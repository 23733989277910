
<template>
    <div v-if="$store.state.app_enable == 'ready'">
        <Alert /> 
        <div class="container-scroller">
            <div class="container-fluid page-body-wrapper">
                    
                    <!---<div id="settings-trigger"><i class="mdi mdi-settings"></i></div>
                        <div id="theme-settings" class="settings-panel">
                        <i class="settings-close mdi mdi-close"></i>
                        <p class="settings-heading">SIDEBAR SKINS</p>
                        <div class="sidebar-bg-options selected" id="sidebar-default-theme">
                            <div class="img-ss rounded-circle bg-light border mr-3"></div>Default
                        </div>
                        <div class="sidebar-bg-options" id="sidebar-dark-theme">
                            <div class="img-ss rounded-circle bg-dark border mr-3"></div>Dark
                        </div>
                        <p class="settings-heading mt-2">HEADER SKINS</p>
                        <div class="color-tiles mx-0 px-4">
                            <div class="tiles default primary"></div>
                            <div class="tiles success"></div>
                            <div class="tiles warning"></div>
                            <div class="tiles danger"></div>
                            <div class="tiles info"></div>
                            <div class="tiles dark"></div>
                            <div class="tiles light"></div>
                        </div>
                    </div>-->
                    <Headbar />
                    <div class="main-panel">
                        <div class="content-wrapper pb-0">
                            <audio id="sys-audio"><source id="source-mp3" src="/sound/default.mp3" type="audio/mpeg"></audio>
                            <router-view />
                        </div>
                    </div>
            </div>
        </div>
        <Footer />
    </div>
    <div v-if="$store.state.app_enable == 'login'">
        <Login />
    </div>
</template>

<style lang="scss">
  @import "./assets/animate.scss";
  @import "./assets/table.scss";
  @import "./assets/bg.scss";
  @import "./assets/button.scss";
  @import "./assets/text.scss";
  @import "./assets/core.scss";
</style>

<script>
import 'animate.css';
import Alert from './components/parts/Alert';
import Headbar from './components/layout/Headbar';
import Footer from './components/layout/Footer';
import Login from './application/Login';
export default {
  name: 'App',
  components: {
    Headbar, Footer, Alert, Login
  }
}
</script>
