<template>
    <div class="row">
        <SystemConnection :connection="service_connection"  v-if="isFirstLoad" />
        <div class="detail-promo" v-else>
            <div class="col-md-12">
                <h3>{{ model.promo_title }}</h3>
                <img :src="$store.state.api_resource + model.photo"/>
                <p class="promo-date">( <span class="text-secondary">Masa berlaku promo</span>  {{ $filters.formatDate(model.start_date, 'DD/MM/YYYY') }} - {{ $filters.formatDate(model.end_date, 'DD/MM/YYYY') }} )</p>
            </div>
            <div class="col-md-12">
                <div class="content" v-html="model.description"></div>
            </div>
        </div>
    </div>
</template>
<script>
    export default 
    {   
        data() {
            return {
                isFirstLoad : true,
                model: []
            };
        },
        created()
        {
            this.DataQuery()
        },
        methods: 
        {
            async DataQuery()
            {
                let res = await this.ServiceApiPost({url: 'banners/detail', data: {id : localStorage.getItem('QUERY_ID')}});
                if(res)
                {
                    this.model = res.query;
                    this.isFirstLoad = false;
                }
            },
        }
    }
</script>
<style lang="scss" scoped>
.detail-promo{
    margin-top:50px;
    background-color:#ffffff3d;
    font-family :'Poppins Light';
    padding:20px;
    h3{
        width:100%;
        float:left;
        text-align:center;
        margin-top:50px;
    }
    p.promo-date{
        text-align:center;
    }
    img{
        width:100%;
        height:auto;
        float:left;
        margin-top:20px;
        margin-bottom:20px;
        border-radius:5px;
    }
    div.content{
        float:left;
        width:100%;
        min-height:400px;
        word-wrap: break-word;
        margin-top:30px;
        text-align:left;
        font-size:14px;
    }
}
</style>