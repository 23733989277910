<template>
    <div class="popup-modal" >
        <div class="modal-dialog modal-sm" >
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Ordering<br/><span class="text-secondary">Urutkan berdasarkan ascending atau descending.</span></h5>
                    <button class="btn btn-sm btn-close" type="button"  @click="closePopup()"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="box-sorting">
                                <div class="form-group">
                                    <select class="form-control" v-model="isSelected" >
                                        <option v-for="(items, idx) in data_model" :value="idx" :key="idx">{{ items.text }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="float-right mt-3">
                                <ButtonProcess :classes="'btn btn-sm btn-warning'" :submit_state="isSubmitProcess" :type="'Button'" :title="'Ordering Data'"  @click="SubmitOrdering"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TableOrdering',
    props:['data_model'],

    data() {
        return {
            isSelected : '',
        }
    },
    created()
    {
        if(this.data_model){
            console.log('mausk', this.data_model);
        }
    },
    methods:{
        closePopup()
        {
            this.$emit('closePopup');
        },
        SubmitOrdering()
        {
            const model = this.data_model[this.isSelected];
            this.$emit('closePopup', model);
        },
    }
}
</script>