<template>
    <main>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        API Response & HTTP Status Code
                    </h1>
                    <div class="page-header-subtitle text-secondary">Response H2H beserta dengan HTTP status code.</div>
                </div>
            </div>
        </header>
        <hr/>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mb-4">
                    <div class="card-body">
                        <SystemConnection :connection="service_connection"  v-if="isFirstLoad" />
                        <div class="s-table table-cover" v-else>
                            <table :class="isRefreshTable ? 'table-load' : '' ">
                                <thead>
                                    <tr>
                                        <th class="no-filter">Response Status</th>
                                        <th class="no-filter">HTTP Code</th>
                                        <th class="no-filter">Msg / Message</th>
                                        <th class="no-filter">Reponse Data</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(items, idx) in mixmodel" :key="idx">
                                        <td>{{ items.text.status }}</td>
                                        <td><span :class="'bg-http-code ' + 'bg-'+items.code">{{ items.code }}</span></td>
                                        <td>{{ items.text.msg ? items.text.msg : '-- --' }}</td>
                                        <td>{{ items.text.data ? items.text.data : '-- --' }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
    export default {
       
        data() {
            return {
                isDownloadProcess : false,
                isFirstLoad : true,
            }
        },
        created()
        {
            this.ServiceInitFunction('DataQuery', 's-table');
        },
        methods:
        {
            
            async DataQuery(pageNum)
            {
                this.isRefreshTable = true;
                this.isEnableFilter = false;
                this.page = pageNum == undefined ? 1 : pageNum;

                let res = await this.ServiceApiDataPaging({
                    url:'h2h/httpcode', 
                    offset : this.page,
                    search: { key : this.mixmeta_data.skey, value : this.mixmeta_data.sval },
                    order: { key : this.mixmeta_data.okey, value : this.mixmeta_data.oval },
                });
                if(res)
                { 
                    this.mixmodel = res.query; 
                    this.isFirstLoad = false;
                    this.isRefreshTable = false;
                }
            },
            async Download()
            {
                this.isDownloadProcess = true;
                const response = await this.ServiceApiPost({url:'h2h/'+this.env+'/log-download', data : {
                    code_otmx_reseller : localStorage.getItem('user-code'),
                    format : 'csv',
                }});
                if(response)
                { 
                    const filename = 'log-request.csv';
                    const url = window.URL.createObjectURL(new Blob([response]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    this.isDownloadProcess = false;
                }
            }, 
        }
    }
</script>


<style lang="scss" scoped>

.bg-http-code{
    color:#fff;
    padding: 5px 10px 5px 10px;
    border-radius:5px;
    font-size:12px;
}
.bg-200{
    background-color: #029f78;
}
.bg-422{
    background-color: #c8c609;
}
.bg-403{
    background-color: #c81809;
}
.bg-401{
    background-color: #c85c09;
}
</style>