<template>
    <div class="modal fade" id="import-color-picker" tabindex="-1" role="">
       <div class="modal-dialog modal-sm" role="document">
            <div class="modal-content p-4">
                <div className="row">
                    <div class="col-md-12">
                        <span class="close-modal material-icons" data-dismiss="modal">close</span>
                        <h6>Color Library <br/><small>Tentukan pilihan warna casing.</small></h6>
                        <div class="card">
                            <fk-color-picker v-model:color="color" />
                        </div>
                    </div>
                    <div class="col-md-12 text-center">
                    <hr/>
                        <a href="javascript:;" class=" btn btn-primary" @click="ImportData()" :data-dismiss="'modal'">Import</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';
export default {
    name: 'ColorPicker',
   data() {
        return {
            color : '',
        }
    },
    created(){

        setTimeout(() => {
            $('.bee-fk-colorPicker__display').find('.action').hide();
        }, 500);
    },
    methods:{
        ImportData(){
            this.$emit('applyData', this.color)
        }
    }
}
</script>