<template>

  <div class="horizontal-menu">
    <SystemEnv  v-on:closePopup="closeEnv"  v-if="isEnablePopup == 'env'" />
    <nav class="navbar top-navbar col-lg-12 col-12 p-0">
      <div class="container">
        <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <a class="navbar-brand brand-logo" href="index.html">
            <img src="assets/images/logo-company.svg" alt="logo" />
            <span class="font-12 d-block text-white"><b>H2H</b>  sandbox</span>
          </a>
          <a class="navbar-brand brand-logo-mini" href="index.html"><img src="assets/images/logo-company-simple.png" alt="logo" /></a>
        </div>
        <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">
          <!--<ul class="navbar-nav mr-lg-2 global-search">
            <li class="nav-item nav-search d-none d-lg-block ">
              <div class="input-group">
                    <input type="search" id="input-search" class="form-control" v-model="search_keyword"  @keyup="QuerySearch()"  placeholder="Search Data or Jump to ....."  />
                    <div class="input-group-append">
                        <div class="spinner-border  spinner-border-sm" role="status"  v-if="isProcessQuery"></div>
                        <i class="mdi mdi-magnify" v-else></i>
                    </div>
                    <div class="query-search" v-if="model_search.length > 0">
                      <ul>
                          <li v-for="(items, idx) in model_search" :key="idx">
                              <a href="javascript:;" @click="EntryKeyword(items.skey, items.sval, items.source_url, items.title)">
                                  <div class="desc">
                                      <span class="title"><i :class="items.icon"></i> {{ items.module }}<br/>
                                      <span class="text">{{items.desc}}</span></span>
                                  </div>
                              </a>
                          </li>
                      </ul>
                  </div>
                </div>
            </li>
          </ul>-->
          <ul class="navbar-nav navbar-nav-right">
            <li class="nav-item nav-profile dropdown">
              <a class="nav-link" id="profileDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
                <div class="nav-profile-img">
                  <img :src="'assets/images/avatar/'+model.avatar+'.jpg'" alt="profile" />
                </div>
                <div class="nav-profile-text">
                  <p class="text-black font-weight-semibold m-0"> {{ $filters.ucwords(model.name) }} </p>
                  <span class="font-13 online-color"><i class="mdi mdi-record menu-icon text-success"></i>  online <i class="mdi mdi-chevron-down"></i></span>
                </div>
              </a>
              <div class="dropdown-menu navbar-dropdown" aria-labelledby="profileDropdown">
                <router-link :to="{ name: 'profile' }" class="dropdown-item" >
                  <i class="mdi mdi mdi-face menu-icon mr-2"></i> Profil Info
                </router-link>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#"  @click="ServiceAuthenticateLogout()">
                  <i class="mdi mdi-logout text-danger mr-2"></i> Logout
                </a>
              </div>
            </li>
          </ul>
          <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="horizontal-menu-toggle">
            <span class="mdi mdi-menu"></span>
          </button>
        </div>
      </div>
    </nav>
    <nav class="bottom-navbar" id="bottom-navbar">
      <div class="container">
        <ul class="nav page-navigation">
          <li class="nav-item">
            <router-link :to="{ name: 'dashboard' }" class="nav-link" >
                <i class="mdi mdi-chart-bar-stacked menu-icon"></i>
                <span class="menu-title">Dashboard</span>
             </router-link>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">
              <i class="mdi mdi-file-document-box menu-icon"></i>
              <span class="menu-title">Docs API</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="submenu">
              <ul class="submenu-item">
                <li class="nav-item">
                  <a href="https://documenter.getpostman.com/view/641760/2s9YeEar7X" target="__blank" class="nav-link">Postman</a>
                </li>
                <li class="nav-item">
                  <router-link :to="{ name: 'doc' }" class="nav-link" >Manual Book </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li class="nav-item">
            <a href="#" class="nav-link">
              <i class="mdi mdi-cloud-sync menu-icon"></i>
              <span class="menu-title">Integrasi</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="submenu">
              <ul class="submenu-item">
                <li class="nav-item">
                  <router-link :to="{ name: 'credential' }" class="nav-link" >Konfigurasi</router-link>
                </li>
                <li class="nav-item">
                  <router-link :to="{ name: 'log' }" class="nav-link" >Log Request </router-link>
                </li>
                <li class="nav-item">
                  <router-link :to="{ name: 'httpcode' }" class="nav-link" >Http Code & Response</router-link>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'balance-data' }" class="nav-link" >
                <i class="mdi mdi-cash-refund menu-icon"></i>
                <span class="menu-title">Topup & Saldo</span>
             </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'product' }" class="nav-link" >
                <i class="mdi mdi-arrange-bring-forward menu-icon"></i>
                <span class="menu-title">Produk</span>
             </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'transaction' }" class="nav-link" >
                <i class="mdi mdi-file menu-icon"></i>
                <span class="menu-title">Transaksi</span>
             </router-link>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">
              <i class="mdi mdi-chart-areaspline menu-icon"></i>
              <span class="menu-title">Laporan</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="submenu">
              <ul class="submenu-item">
                <li class="nav-item">
                  <router-link :to="{ name: 'report-transaction' }" class="nav-link" >Transaksi</router-link>
                </li>
                <!--<li class="nav-item">
                  <router-link :to="{ name: 'report-transaction' }" class="nav-link" >Produk</router-link>
                </li>-->
              </ul>
            </div>
          </li>
          <li class="nav-item">
            <div class="mt-3">
              <a href="javascript:;" @click="isEnablePopup = 'env'"><i class="mdi mdi-mdi-compare-horizontal"></i>  <span class="btn btn-sm btn-dark">{{ $filters.ucwords(isEnableEnv) }}</span></a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>


<script>
 
  import $ from 'jquery';
  import SystemEnv from '../layout/SystemEnv';

  export default 
  {   
      components: {
        SystemEnv
      },
      data() {
          return {
              isEnablePopup : false,
              isProcessQuery : false,
              isEnableEnv : localStorage.getItem('is_enable_env'),

              search_keyword : '',
              model_search : [],
              isEnableFullScreen : false,
              sound_notification : '',
              date : { year:'', month:'', day:''},
              fprivileges : localStorage.getItem('user-privileges'),
              model: {
                    name : '',
                    avatar : '',
                    email : ''
                },
              
          };
      },

      created()
      {
          this.model.name = localStorage.getItem("user-name");
          this.model.avatar = localStorage.getItem("user-avatar");
          this.$nextTick(function()
          {
              this.freseller = localStorage.getItem('user-code');

              this.Initialize();
              this.InitServerDate();
              this.InitClock();
              
          });
      },
      methods: 
      {
          closeEnv()
          {
              this.isEnablePopup = false;
          },
         
          Initialize()
          {
             
            window.onscroll = function() 
            {
                var header = document.getElementById("bottom-navbar");
                var sticky = header.offsetTop;
                console.log('has', sticky);
                
                if (window.pageYOffset > sticky) {
                  header.classList.add("sticky");
                } else {
                  header.classList.remove("sticky");
                }
            };
          
          },
          InitServerDate()
          {
              this.year = [];
              this.date.year = this.ServerDateService('active_year');
              this.date.month = parseInt(this.ServerDateService('active_month'));
              this.date.day = this.ServerDateService('active_day');
          },
          InitClock() 
          {
              var today = new Date();
              var h = today.getHours();
              var m = today.getMinutes();
              var s = today.getSeconds();
              m = this.checkTime(m);
              s = this.checkTime(s);
              $('#init-clock').html(h + ":" + m + ":" + s);
              setTimeout(this.InitClock, 500);
          },
          checkTime(i) 
          {
              if (i < 10) {i = "0" + i}  // add zero in front of numbers < 10
              return i;
          },
         
          async MaximizeWindow()
          {
              this.isEnableFullScreen =! this.isEnableFullScreen;

              var elem = document.documentElement;
              if(this.isEnableFullScreen)
              {
                if (elem.requestFullscreen) {
                  elem.requestFullscreen();
                } else if (elem.webkitRequestFullscreen) { /* Safari */
                  elem.webkitRequestFullscreen();
                } else if (elem.msRequestFullscreen) { /* IE11 */
                  elem.msRequestFullscreen();
                }
              }else{
                if (document.exitFullscreen) {
                  document.exitFullscreen();
                } else if (document.webkitExitFullscreen) { /* Safari */
                  document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { /* IE11 */
                  document.msExitFullscreen();
                }
              }
          },
          EntryKeyword(skey, sval, source_url, title)
          {
              this.model_search = [];
              this.search_keyword = title;

              localStorage.setItem("global-search", JSON.stringify({search_key :skey, search_val : sval}));
              window.location.href = source_url;
          },
          async QuerySearch()
          {
              if( this.search_keyword !== '' )
              {
                  this.isProcessQuery = true;
                  const query = await this.ServiceApiPost({url:'search/global', data : {
                      freseller : this.freseller,
                      keyword: this.search_keyword,
                  }});
                  if(query)
                  { 
                    this.model_search = query;
                    this.isProcessQuery = false;
                  }
              }else{
                this.model_search = [];
              }
          }
      },
  }
</script>
<style lang="scss" scoped>

.dateclock{
  position: relative;
  padding:3px 10px 3px 10px;
  font-size:12px;
  background-color:#f1f1f1;
  color:rgb(110, 110, 110);
  border-radius:3px;
  -moz-border-radius:3px;
  -webkit-border-radius:3px;
  margin-right:10px;
  .fas{color : #f37979;}
}
.global-search{
  width:80%;
  li{
    width:100%;
    .input-group{
      input[type="search"]{
          border-radius:25px;
          background-color:#fff;
      }
      .input-group-append{
        position: relative;
        z-index: 99;
        left: -35px;
        top: 0px;
        width: 33px;
        height: 33px;
        text-align: center;
        background-color: #f9f9f9;
        border-radius: 50%;
        padding: 0px;
          i{
              position:relative;
              color:red;
              font-size:20px;
              left:8px;
          }
          div.spinner-border{
            position:relative;
            left:10px;
            top:10px;
          }
      }
    }
  }
 
  .query-search{
      border:1px solid whitesmoke;
      position: absolute;
      z-index: 999;
      top: 40px;
      left: 5px;
      width: 94%;
      height: 300px;
      overflow-y: scroll;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);

      ul{
          padding:0px;
          margin:0;
          width:100%;
          float:left;
          li{
              list-style:none;
              float:left;
              width:100%;
              padding:0px;
              border-bottom:1px solid whitesmoke;
              span,b{
                  font-size:12px;
                  position:relative;
              }
              div.img{
                  float:left;
                  width:40px;
                  height:40px;
                  overflow:hidden;
                  img{
                      width:auto;
                      height:100%;
                  }
              }
              div.desc{
                  padding:10px;
                  float:left;
                  width:100%;
                  span.text{
                      color:#2f2f2f;
                      font-size:13px;
                  }
                  span.title{
                      color:#989898;
                      font-weight:200;
                  }
                  span.module{
                    color:#2f2f2f;
                    font-weight:300;
                  }
                  i.mdi{
                    font-size: 14px;
                    color: #ff3e00;
                    width: 25px;
                    height: 25px;
                    position: relative;
                    display: block;
                    float: left;
                    border-radius: 50%;
                    text-align: center;
                    background-color: #f1f1f1;
                    margin-right: 7px;
                  }
                  
              }
              &:hover{
                  background-color:whitesmoke;
              }
          }
          li:last-child{
              border-bottom:none;
          }
      }
  }
}


.sticky {
    position: fixed !important;
    top: 0;
    width: 100%;
    z-index: 99;
}
  
</style>