<template>
    <main>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Setting Notifikasi 
                    </h1>
                    <div class="page-header-subtitle text-secondary">Pengaturan nofikasi suara.</div>
                </div>
                <div class="col-md-2 mt-4">
                </div>
            </div>
        </header>
        <div className="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="configuration">
                            <ul>
                                <li>
                                    <span><i class="text-success mdi mdi-bell-ring-outline mr-2"></i>  Jinggle</span>
                                    <input type="radio"  value="sound/default.mp3" :checked="ValidateChecked('/sound/default.mp3')" @change="ChooseAlert('/sound/default.mp3')"/>
                                </li>
                                <li>
                                    <span><i class="text-success mdi mdi-bell-ring-outline mr-2"></i>  The Calling </span>
                                    <input type="radio"  value="sound/the-calling.mp3" :checked="ValidateChecked('/sound/the-calling.mp3')" @change="ChooseAlert('/sound/the-calling.mp3')"/>
                                </li>
                                <li>
                                    <span><i class="text-success mdi mdi-bell-ring-outline mr-2"></i>  Demonstrtive </span>
                                    <input type="radio"  value="sound/demonstrative.mp3" :checked="ValidateChecked('/sound/demonstrative.mp3')" @change="ChooseAlert('/sound/demonstrative.mp3')"/>
                                </li>
                                <li>
                                    <span><i class="text-success mdi mdi-bell-ring-outline mr-2"></i>  Point</span>
                                    <input type="radio"  value="sound/to-the-point.mp3" :checked="ValidateChecked('/sound/to-the-point.mp3')" @change="ChooseAlert('/sound/to-the-point.mp3')"/>
                                </li>
                                <li>
                                    <span><i class="text-success mdi mdi-bell-ring-outline mr-2"></i>  Definite</span>
                                    <input type="radio"  value="sound/definite.mp3" :checked="ValidateChecked('/sound/definite.mp3')" @change="ChooseAlert('/sound/definite.mp3')"/>
                                </li>
                                <li>
                                    <span><i class="text-success mdi mdi-bell-ring-outline mr-2"></i>  Not Bad</span>
                                    <input type="radio"  value="sound/not-bad.mp3" :checked="ValidateChecked('/sound/not-bad.mp3')" @change="ChooseAlert('/sound/not-bad.mp3')"/>
                                </li>
                                <li>
                                    <span><i class="text-success mdi mdi-bell-ring-outline mr-2"></i>  Little Bunny </span>
                                    <input type="radio"  value="sound/little-dwarf.mp3" :checked="ValidateChecked('/sound/little-dwarf.mp3')"  @change="ChooseAlert('/sound/little-dwarf.mp3')"/>
                                </li>
                                <li>
                                    <span><i class="text-success mdi mdi-bell-ring-outline mr-2"></i>  Christmas</span>
                                    <input type="radio"  value="sound/chirstmas.mp3" :checked="ValidateChecked('/sound/chirstmas.mp3')" @change="ChooseAlert('/sound/chirstmas.mp3')"/>
                                </li>
                                <li>
                                    <span><i class="text-success mdi mdi-bell-ring-outline mr-2"></i>  Happy Year</span>
                                    <input type="radio"  value="sound/happy-year.mp3" :checked="ValidateChecked('/sound/happy-year.mp3')" @change="ChooseAlert('/sound/happy-year.mp3')"/>
                                </li>
                                <li>
                                    <span><i class="text-success mdi mdi-bell-ring-outline mr-2"></i>  Olala</span>
                                    <input type="radio"  value="sound/obey.mp3" :checked="ValidateChecked('/sound/obey.mp3')" @change="ChooseAlert('/sound/obey.mp3')"/>
                                </li>
                                <li>
                                    <span><i class="text-success mdi mdi-bell-ring-outline mr-2"></i>  Alarm - Love</span>
                                    <input type="radio"  value="sound/loving-you.mp3" :checked="ValidateChecked('/sound/loving-you.mp3')" @change="ChooseAlert('/sound/loving-you.mp3')"/>
                                </li>
                                <li>
                                    <span><i class="text-success mdi mdi-bell-ring-outline mr-2"></i>  Alarm - Sunrise</span>
                                    <input type="radio"  value="sound/early-sunrise.mp3" :checked="ValidateChecked('/sound/early-sunrise.mp3')" @change="ChooseAlert('/sound/early-sunrise.mp3')"/>
                                </li>
                                <li>
                                    <span><i class="text-success mdi mdi-bell-ring-outline mr-2"></i>  Alarm - Buzzer</span>
                                    <input type="radio"  value="sound/alarm-buzzer.mp3" :checked="ValidateChecked('/sound/alarm-buzzer.mp3')" @change="ChooseAlert('/sound/alarm-buzzer.mp3')"/>
                                </li>
                                <li>
                                    <span><i class="text-success mdi mdi-bell-ring-outline mr-2"></i>  Alarm - Good Morning</span>
                                    <input type="radio"  value="sound/good-morning.mp3" :checked="ValidateChecked('/sound/good-morning.mp3')" @change="ChooseAlert('/sound/good-morning.mp3')"/>
                                </li>
                                <li>
                                    <span><i class="text-success mdi mdi-bell-ring-outline mr-2"></i>  Alarm - Cuckoo Clock</span>
                                    <input type="radio"  value="sound/cuckoo-cuckoo-clock.mp3" :checked="ValidateChecked('/sound/cuckoo-cuckoo-clock.mp3')" @change="ChooseAlert('/sound/cuckoo-cuckoo-clock.mp3')"/>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>


<script>
    export default {
        data() {
            return {
                sound : localStorage.getItem('notification_sound')
            };
        },
        methods:
        {
            ValidateChecked(init_sound)
            {
                return init_sound == this.sound ? true : false;
            },
            ChooseAlert(path)
            {
                var sys_audio  = document.getElementById('sys-audio');
                var source_mp3 = document.getElementById('source-mp3');
                this.sound = path;
                source_mp3.src = this.sound;
                
                sys_audio.load();
                sys_audio.play();
                this.Submit(path);
            },
            async Submit(path_source) 
            {
                let dataUpdate = {id_user : this.$store.state.user_id, set : 'sound', notification_sound : path_source};
                const res = await this.ServiceApiPost({url:'app/setting', data : dataUpdate});
                if(res){ 
                    localStorage.setItem('notification_sound', path_source);
                }
            }
        }
    }
</script>


<style lang="scss" scoped>
.configuration ul {
  padding: 0;
  margin: 0;
}
.configuration ul li {
  width: 100%;
  list-style: none;
  border-top: 1px solid whitesmoke;
  padding: 12px;
  i.fas .fa-bell{
      font-size:14px;
  }
  input[type="radio"]{
      position:relative;
      top:7px;
      float:right;
  }
}
.configuration ul li:nth-child(1) {
  border-top: none;
  padding-top: 30px;
}
</style>
