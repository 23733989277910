<template>
    <main>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Profil Info 
                    </h1>
                    <div class="page-header-subtitle text-secondary">Data informasi umum akun, foto selfie, foto identitas serta informasi login akses aplikasi.</div>
                </div>
               
            </div>
        </header>
        <hr/>
        <div class="row">
            <div class="col-xl-4 pr-0">
                <div class="card p-4">
                    <div class="mt-3">
                        <label><i class="mdi mdi-information-outline text-warning"></i>  Avatar  <br/><small class="text-secondary">Pilih avatar favorit ( Geser ke kanan/kiri ).</small></label><br/>
                        <div class="icon-list">
                            <div class="scrollx">
                                <ul>
                                    <li v-for="(items, idx) in 10" :key="idx">
                                        <i class="icon-check mdi mdi-checkbox-marked-circle" v-if="(idx+1) == model.avatar"></i>
                                        <a href="javascript:;" @click="ChangeAvatar(idx+1)">
                                            <div class="picture"><img :src="'assets/images/avatar/'+(idx+1)+'.jpg'"/></div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3">
                        <label><i class="mdi mdi-information-outline text-warning"></i>  Foto Selfie </label>
                        <div class="pic-box"><img :src="model.photo_selfi_id !== null ? model.photo_selfi_id  : '/assets/images/no-image.png'"/></div>
                    </div>
                    <div class="mt-3">
                        <label><i class="mdi mdi-information-outline text-warning"></i>  Foto Identitas KTP</label>
                        <div class="pic-box"><img :src="model.photo_identity_id  !== null ? model.photo_identity_id  : '/assets/images/no-image.png'" /></div>
                    </div>
                </div>
            </div>
            <div class="col-xl-8">
                <!-- Account details card-->
                <div class="card mb-4">
                    <SystemConnection :connection="service_connection"  v-if="isFirstLoad" />
                    <div class="card-body" v-else>
                        <div class="box-data mb-2">
                            <label><i class="mdi mdi-information-outline text-warning"></i>  Informasi Umum <br/><small class="text-secondary">Informasi umum tentang akun data.</small></label>
                        </div>
                        <div class="box-data">
                            <span class="text-secondary">Tgl.Daftar</span>
                            <span class="float-right">{{ $filters.formatDate(model.register_date, 'DD/MM/YYYY') }} - <span class="text-secondary">{{ $filters.formatDate(model.register_date, 'LT') }}</span></span>
                        </div>
                        <div class="box-data">
                            <span class="text-secondary">Member Status</span>
                            <span class="float-right">{{ model.status }}</span>
                        </div>
                        <div class="box-data">
                            <span class="text-secondary">Data Status</span>
                            <span class="float-right">{{ GenerateDataStatus(model.data_status) }}</span>
                        </div>
                        <div class="box-data">
                            <span class="text-secondary">Kode Reseller</span>
                            <span class="float-right">{{ model.code_otmx_reseller }}</span>
                        </div>
                        <div class="box-data">
                            <span class="text-secondary"> Kode Level</span>
                            <span class="float-right">{{ model.level_code }}</span>
                        </div>
                        
                        <div class="box-data">
                            <span class="text-secondary"> Nama Konter</span>
                            <span class="float-right">{{ model.counter_name }}</span>
                        </div>
                        <div class="box-data">
                            <span class="text-secondary"> Alamat Konter</span>
                            <span class="float-right">{{ model.address !== null  ? model.address : '-- --'}}</span>
                        </div>
                        <div class="box-data">
                            <span class="text-secondary"> Nama Pemilik</span>
                            <span class="float-right">{{ model.owner_name }}</span>
                        </div>
                        <div class="box-data">
                            <span class="text-secondary">Device Mobile</span>
                            <span class="float-right">{{ $filters.ucwords(model.device_model) }}</span>
                        </div>
                        <div class="box-data mt-2 mb-2">
                            <label><i class="mdi mdi-information-outline text-warning"></i>  Informasi login. <br/><small class="text-secondary">Akun yang saat ini digunakan untuk login aplikasi.</small></label>
                        </div>
                        <div class="box-data">
                            <span class="text-secondary"> No. Telepon</span>
                            <span class="float-right">{{ model.phone }}</span>
                        </div>
                        <div class="box-data">
                            <span class="text-secondary"> Kode Reseller</span>
                            <span class="float-right">{{ model.code_otmx_reseller }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </main>
</template>

<script>
    export default 
    {   
        data() {
            return {
                isFirstLoad : true,
                model:{},
                avatar : '',
            };
        },
        created()
        {
            this.DataQuery();
        },
        methods: 
        {
            GenerateDataStatus(initial)
            {
                let idx = initial - 1;
                let status = [
                    'Belum terverifikasi',
                    'Proses pengecekan',
                    'Terverifikasi',
                    'Data belum lengkap',
                    'Foto Selfie tidak jelas',
                    'Foto KTP tidak jelas',
                    'Foto KTP & Selfie tidak jelas',
                ];

                return status[idx];
            },
           
            async ChangeAvatar(idx)
            {
                this.model.avatar = idx;
                let req = await this.ServiceApiPost({url:'user/avatar', action:'post', data:{
                    code_otmx_reseller : this.model.code_otmx_reseller,
                    avatar : idx
                }});
                if(req && req.result)
                {
                    localStorage.setItem('user-avatar', idx);
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                }
            },
            async DataQuery()
            {
                let req = await this.ServiceApiPost({url:'user/profile', data:{code_otmx_reseller : localStorage.getItem('user-code')}});
                if(req && req.result)
                {
                    this.model = req.query;
                    localStorage.setItem('user-name', this.model.owner_name);
                    this.isFirstLoad = false;
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
.box-data{
    float:left;
    width:100%;
    padding:10px;
    border-bottom:1px solid #fcfcfc59;
}
.pic-box{
    width:100%;
    height:174px;
    overflow:hidden;
    text-align:center;
    border:1px solid #ffffff8d;
    border-radius:3px;
    padding:5px;
    img{
        height:100%;
    }
}


</style>