<template>
    <main>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        FAQ( frequently asked question )
                    </h1>
                    <div class="page-header-subtitle text-secondary">Temukan jawaban atas pertanyaan anda dari yang umum dan sering ditanyakan.</div>
                </div>
            </div>
        </header>
        <hr/>
        <div class="row">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div id="faq">
                                    <ul>
                                        <li>
                                            <a href="javascript:;" data-toggle="collapse" data-target="#faq-1" aria-expanded="true"  @click="isOpenFaq('colicon-1')"><i  id="colicon-1" class="mdi mdi-minus-circle-outline"></i> Bagaimana cara mendapatkan token access API</a>
                                            <div id="faq-1" class="desc collapse show">
                                                <b class="text-secondary">Jawab : </b><br/>
                                                <p>Dengan menyertakan parameter "client_id" dan "client_secret" yang anda miliki anda dapat 
                                                terhubung/terkoneksi dengan sistem h2h Dflash, Untuk panduan selengkapnya anda dapat membaca dokumentasi tentang cara mendapatkan token API melalui manual book.</p>
                                            </div>
                                        </li>
                                        <li>
                                            <a href="javascript:;" data-toggle="collapse" data-target="#faq-2" aria-expanded="false"  @click="isOpenFaq('colicon-2')"><i id="colicon-2" class="mdi mdi-plus-circle-outline"></i> Apa itu IP Whitelist</a>
                                            <div id="faq-2" class="desc collapse">
                                                <b class="text-secondary">Jawab : </b><br/>
                                                <p>Ip Whitelist digunakan pada environment fase production yang  merupakan unique IP Address yang di daftarkan pada sistem H2H Dflash. Untuk dapat terkoneksi dengan sistem H2H kami membutuhkan otentikasi request dari server yang valid.</p>
                                            </div>
                                        </li>
                                        <li>
                                            <a href="javascript:;" data-toggle="collapse" data-target="#faq-3" aria-expanded="false"  @click="isOpenFaq('colicon-3')"><i id="colicon-3" class="mdi mdi-plus-circle-outline"></i> Bagaimana cara monitoring request yang telah di hit ke server H2H</a>
                                            <div id="faq-3" class="desc collapse">
                                                <b class="text-secondary">Jawab : </b><br/>
                                                <p>Anda dapat membuka fitur Integrasi > Log Request untuk melihat semua tipe http method, http response code, endpoint url, response api, request body sebagai detail log request.</p>
                                            </div>
                                        </li>
                                        <li>
                                            <a href="javascript:;" data-toggle="collapse" data-target="#faq-4" aria-expanded="false" @click="isOpenFaq('colicon-4')"><i id="colicon-4" class="mdi mdi-plus-circle-outline"></i> Bagaimana cara melakukan topup saldo</a>
                                            <div id="faq-4" class="desc collapse">
                                                <b class="text-secondary">Jawab : </b><br/>
                                                <p>Anda dapat menggunakan fitur "Topup & Saldo" kemudian klik icon plus untuk melakukan deposit saldo. <br/> Syarat & Ketentuan dalam deposit saldo sewaktu-waktu dapat berubah seperti minimal jumlah deposit dan jam operasional deposit.</p>
                                                <div class="group-image">
                                                    <ul>
                                                        <li><img class="landscape" src="/assets/images/faq/topup/btn-depo.png"/></li>
                                                        <li><img class="potrait" src="/assets/images/faq/topup/topup.png"/></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <a href="javascript:;" data-toggle="collapse" data-target="#faq-5" aria-expanded="false"  @click="isOpenFaq('colicon-5')"><i id="colicon-5" class="mdi mdi-plus-circle-outline"></i> Apa itu "Url Callback Transaksi"</a>
                                            <div id="faq-5" class="desc collapse">
                                                <b class="text-secondary">Jawab : </b><br/>
                                                <p>"Url Callback Transaksi" merupakan suatu endpoint url yang akan di HIT oleh sistem H2H Dflash untuk mengirimkan parameter hasil dari request transaksi sebagai response balasan. 
                                                    Untuk mengetahui tentang konfigurasi beserta parameter yang dikirimkan anda dapat mengakses menu <b>Integrasi</b> > <b>Konfigurasi</b></p>
                                            </div>
                                        </li>
                                        <li>
                                            <a href="javascript:;" data-toggle="collapse" data-target="#faq-6" aria-expanded="false"  @click="isOpenFaq('colicon-6')"><i id="colicon-6" class="mdi mdi-plus-circle-outline"></i> Apa itu perbedaan env staging dan production</a>
                                            <div id="faq-6" class="desc collapse">
                                                <b class="text-secondary">Jawab : </b><br/>
                                                <p>Env Staging adalah environment dimana anda dapat melakukan unit testing pada server staging dengan return response dummy data. 
                                                    Sedangkan Env Production adalah environment dimana data yang di submit dan di tampilkan merupakan data realtime.
                                                    Perlu di ketahui bahwa request parameter dan response pada server staging dan production tidak berubah/sama hal tersebut agar memudahkan anda saat melakukan integrasi dan migrasi.
                                                </p>
                                            </div>
                                        </li>
                                        <li>
                                            <a href="javascript:;" data-toggle="collapse" data-target="#faq-7" aria-expanded="false"  @click="isOpenFaq('colicon-7')"><i id="colicon-7" class="mdi mdi-plus-circle-outline"></i> Bagaimana jika ada kendala teknis atau hal yang bersangkutan dengan penggunaan API.</a>
                                            <div id="faq-7" class="desc collapse">
                                                <b class="text-secondary">Jawab : </b><br/>
                                                <p>Anda dapat mengisi form pada "Contact Us" dengan menentukan topic yang ada. sistem akan meneruskan pesan menjadi ticketing yang nantinya akan di handle oleh bagian tehnikal team kami.
                                                </p>
                                            </div>
                                        </li>
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="bg-faq-pusing"></div>
    </main>
</template>

<script>
    export default {
       
        data() {
            return {
                isDownloadProcess : false,
                isFirstLoad : true,
            }
        },
        methods:
        {
            isOpenFaq(id)
            {
                if( document.getElementById(id).className == 'mdi mdi-plus-circle-outline' ){
                    document.getElementById(id).className = 'mdi mdi-minus-circle-outline';
                }else{
                    document.getElementById(id).className = 'mdi mdi-plus-circle-outline';
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
#faq{
    position:relative;
    z-index: 1;
    width:100%;
    float:left;
    margin-bottom:20px;
    ul{
        margin:0;
        padding:0;
        li{
            width:100%;
            float:left;
            list-style:none;
            a{
                display:block;
                font-size:14px;
                color:#000;
                padding:3px;

                &:after{
                    content:" ?";
                    color:red;
                }
                i.mdi{
                    font-size: 21px;
                    color: #ff8031;
                    position: relative;
                    top: 3px;
                    margin-right:10px;
                }
            }
            .desc{
                float:left;
                width:100%;
                padding: 5px 10px 5px 40px;
                p{margin-top:10px; line-height: 20px;}
            }
        }
    }
}

#bg-faq-pusing{
    position: relative;
    display:block;
    z-index: 0;
    left: 0;
    bottom:-14em;
    width: 380px;
    height: 200px;
    background:url('/assets/images/faq-pusing.png')no-repeat;
    background-size:contain;
    background-position: 10%;
    opacity:.20;
}
.group-image{
    float:left;
    width:100%;
    ul{
        padding:0;
        margin:0;
        float:left;
        width:100%;
        li{
            float:left;
            padding:10px;
            margin:0;
            width:30% !important;

            .landscape{
                float:left;
                width:100%;
                margin-right:10px;
            }

            .potrait{
                float:left;
                height:150px;
                margin-right:10px;
            }
        }
    }
}
</style>
