<template>
    <div id="mapid" v-bind:style="{height:css_height+'px'}"></div>
</template>

<script>
    export default {
        name: 'LeafletMap',
        props : ['model', 'style_height']
,       data() {
            return {
                mymap : null,
                css_height : 580
            }
        },
        created()
        {
            if(this.style_height){
                this.css_height = this.style_height;
            }

            this.$nextTick(function(){
                this.InitializeMap();
            });
        },
        methods:
        {
            InitializeMap()
            {
                this.mymap = window.L.map('mapid').setView([-6.2375262, 106.7672906], 10);
                window.L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw', {
                    maxZoom: 18,
                    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, ' +
                        'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                    id: 'mapbox/streets-v11',
                    tileSize: 512,
                    zoomOffset: -1
                }).addTo(this.mymap);
            },
            AddMarker(data)
            {
                let self = this;
                window.L.marker([data.lat, data.lng]).addTo(this.mymap).bindPopup('<b>'+data.name+'</b>' + '<br/>' +data.address);
                window.L.marker([data.lat, data.lng]).addTo(this.mymap).on('click', function() {
                    self.isEnableDetail = true;
                    self.data_store = data;
                });
            }
        }
    }
</script>


<style lang="scss">
 #mapid { 
    width:100%;
    border-radius:3px;
}
</style>