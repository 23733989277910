<template>
    <main>
        <div class="row">
            <div class="col-md-12">
                <div class="mb-4">
                    <div id="report-header">
                        <h4>Laporan Transaksi <br/> <span class=text-secondary>Periode {{ GenerateReportTitle('period') }}</span></h4>
                        <div v-if="isActiveFilterDate == 'weekly' && !isRefreshTable">
                            <span class="mt-2">{{ $filters.formatDate(report_title.start_date, 'DD/MM/YYYY') }}</span> <span class="text-secondary">-</span> <span>{{ $filters.formatDate(report_title.end_date, 'DD/MM/YYYY') }}</span>
                        </div>
                        <div v-if="isActiveFilterDate !== 'weekly'  && !isRefreshTable">
                            <span class="mt-2">{{ report_title.start_date }}</span> 
                        </div>
                    </div>
                    <div id="report-filter">
                        <span class="total">Total Data  ( <b v-if=!isRefreshChartTrxSaldo>{{ $filters.formatNumber(mixmodel.total) }}</b> <b v-else>..</b> ) <span clas="text-secondary">TRX</span></span> 
                        <ul>
                            <li><a href="javascript:;" data-toggle="tooltip" data-placement="right" title="Filter berdasarkan 7 hari terkahir"  :class="isActiveFilterDate == 'weekly' ? 'active' : ''"  @click="FilterDate('weekly')" ><span>7 Hari Terakhir</span></a></li>
                            <li><a href="javascript:;" data-toggle="tooltip" data-placement="right" title="Filter berdasarkan bulanan" :class="isActiveFilterDate == 'monthly' ? 'active' : ''" @click="FilterDate('monthly')"><span>Bulanan</span></a></li>
                            <!--<li><a href="javascript:;" data-toggle="tooltip" data-placement="right" title="Filter berdasarkan tahunan" :class="isActiveFilterDate == 'yearly' ? 'active' : ''" @click="FilterDate('yearly')"><span>Tahunan</span></a></li>-->
                        </ul>
                    </div>
                    <div id="report-chart">
                        <div class="row">
                            <div class="col-md-8">
                                <label><i class="mdi mdi-information-outline text-danger"></i> Detail Total Trx & Saldo <span class="text-secondary">( {{ GenerateReportTitle('period') }} )</span></label>
                                <div class="cover-info" >
                                    <div id="carouselChart" class="carousel slide" data-ride="carousel">
                                        <ol class="carousel-indicators">
                                            <li data-target="#carouselChart" data-slide-to="0" class="active"></li>
                                            <li data-target="#carouselChart" data-slide-to="1"></li>
                                            <!--<li data-target="#carouselChart" data-slide-to="2"></li>-->
                                        </ol> 
                                        <div class="carousel-inner">
                                            <div class="carousel-item active">
                                                <div  class="cover-circle-info">
                                                    <div class="circle-info circle-yellow">
                                                        <span>{{ $filters.kFormatter(total_trx.saldo) }}</span><br/>
                                                        <small>TRX</small>
                                                        <i class="mdi mdi-chart-areaspline"></i>
                                                    </div>
                                                    <div class="circle-desc">
                                                        <div class="float-left">Deposit Saldo <br/><span class="text-secondary">Data akumulasi deposit saldo.</span></div>
                                                        <div class="loading-dots float-right" v-if=isRefreshChartTrxSaldo><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div></div>
                                                        <div class="float-right idr idr-yellow" v-else>Rp {{ $filters.formatNumber(total_trx.saldo_idr) }}</div>
                                                    </div>
                                                </div>
                                                <div :class="isRefreshChartTrxSaldo ? 'block-load cover-mini-chart' : 'cover-mini-chart'">
                                                    <div id="chart-trx-saldo" :class="'chart-'+isActiveFilterDate"></div>
                                                </div>
                                            </div>
                                            <div class="carousel-item">
                                                <div class="cover-circle-info">
                                                    <div class="circle-info circle-orange">
                                                        <span>{{ $filters.kFormatter(total_trx.success) }}</span><br/>
                                                        <small>TRX</small>
                                                        <i class="mdi mdi-chart-bar-stacked"></i>
                                                    </div>
                                                    <div class="circle-desc">
                                                        <div class="float-left">Transaksi Sukses <br/><span class="text-secondary">Data akumulasi transaksi sukses</span></div>
                                                        <div class="loading-dots float-right" v-if=isRefreshChartTrxSuccess><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div></div>
                                                        <div class="float-right idr idr-orange" v-else>Rp {{ $filters.formatNumber(total_trx.success_idr) }}</div>
                                                    </div>
                                                </div>
                                                <div :class="isRefreshChartTrxSuccess ? 'block-load cover-mini-chart' : 'cover-mini-chart'">
                                                    <div id="chart-trx-success"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 pl-0">
                                <label><i class="mdi mdi-information-outline text-danger"></i> Summary Trx Status <span class="text-secondary">( {{ GenerateReportTitle('period') }} )</span></label>
                                <div class="summary-info">
                                     <div id="piechart-trx-all" :class="isRefreshChartTrxPie ? 'block-load' : ''"></div>
                                </div>
                                <div :class="isRefreshChartTrxSuccess ? 'block-load summary-info' : 'summary-info'">
                                    <i class="indicator mdi mdi-arrow-up text-success"></i>
                                    <span>Trx Sukses Tertinggi</span>
                                    <span class="date">{{ ts_max_date }}</span><br/>
                                    <small class="text-secondary">Data transaksi tertinggi <b class="text-success">{{ $filters.formatNumber(ts_max_count) }}</b> TRX</small>
                                    <h6 class="mt-2">Rp {{ $filters.formatNumber(ts_max_idr) }}</h6>
                                </div>
                                <div :class="isRefreshChartTrxSuccess ? 'block-load summary-info' : 'summary-info'">
                                    <i class="indicator mdi mdi-arrow-down text-danger"></i>
                                    <span>Trx Sukses Terendah</span>
                                    <span class="date">{{ ts_min_date }}</span><br/>
                                    <small class="text-secondary">Data transaksi terendah <b class="text-danger">{{ $filters.formatNumber(ts_min_count) }}</b> TRX</small>
                                    <h6 class="mt-2">Rp {{ $filters.formatNumber(ts_min_idr) }}</h6>
                                </div>
                                
                            </div>
                            <div class="col-md-12 mt-4">
                                <label><i class="mdi mdi-information-outline text-danger"></i> Saldo & Transaksi Sukses <span class="text-secondary">( {{ GenerateReportTitle('period') }} )</span></label>
                                <div  :class="isRefreshChartTrxTotal ? 'block-load cover-chart' : 'cover-chart'">
                                    <div id="chart-trx-total"></div>
                                </div>
                            </div>
                            <div class="col-xl-12 mt-4">
                                <label><i class="mdi mdi-information-outline text-danger"></i> Record Transaksi <span class="text-secondary">( {{ GenerateReportTitle('period') }} )</span></label>
                                <div class="card">
                                    <div class="card-body">
                                        <SystemConnection :connection="service_connection"  v-if="isFirstLoad" />
                                        <div class="s-table table-cover" v-else>
                                            <table :class="isRefreshTable ? 'table-load' : '' ">
                                                <thead>
                                                    <tr>
                                                        <th :data-filter="env == 'production' ? 'tgl_entri' : 'created_at'">Tgl.Transaksi</th>
                                                        <th data-filter="kode">ID Transaksi</th>
                                                        <th data-filter="ref_id">Ref ID</th>
                                                        <th data-filter="nama_produk">Nama Produk</th>
                                                        <th data-filter="tujuan">No.Tujuan</th>
                                                        <th data-filter="harga">Harga</th>
                                                        <th data-filter="status">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="mixmodel.total > 0">
                                                    <tr v-for="(items, idx) in mixmodel.data" :key="idx">
                                                        <td width="120">{{ $filters.formatDate(items.tgl_entri, 'DD/MM/YYYY') }}<br/><small class="text-secondary">{{ $filters.formatDate(items.tgl_entri, 'LT') }}</small></td>
                                                        <td width="120">{{ items.kode }}</td>
                                                        <td width="130">
                                                            <span v-if="items.ref_id !== null"><i class="mdi mdi-file-hidden text-success"></i> {{ items.ref_id }}</span>
                                                            <span class="text-secondary" v-else>-- --</span>
                                                        </td>
                                                        <td>{{ items.kode_produk }} - {{ $filters.ucwords(items.nama_produk) }}<br/></td>
                                                        <td width="150">{{ $filters.formatPhone(items.tujuan) }}</td>
                                                        <td width="120">Rp {{ $filters.formatNumber(items.harga) }}</td>
                                                        <td width="150"><span :class="$filters.GenerateTrxClassStatus(items.status)">{{ items.status }}</span></td>
                                                    </tr>
                                                </tbody>
                                                <tbody v-else>
                                                    <tr>
                                                        <td colspan="8">
                                                            Data kosong / tidak ditemukan.
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>                                     
                                        </div>
                                        <v-pagination
                                            v-if="mixmodel.total > 0"
                                            v-model="page"
                                            :class="'pagination'"
                                            :pages="parseInt(mixmodel.last_page)"
                                            :range-size="1"
                                            active-color="#ffffff"
                                            @update:modelValue="QueryTrxTable"
                                        />
                                        <div class="paginate-total">Total Records ( {{ $filters.formatNumber(mixmodel.total) }} )</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    import Highcharts from 'highcharts';

    export default 
    {   
        data() {
            return {
                isFirstLoad : true,
                isRefreshChartTrxSuccess : true,
                isRefreshChartTrxSaldo : true,
                isRefreshChartTrxTotal : true,
                isRefreshChartTrxPie : true,


                isRefreshTable : false,
                isActiveFilterDate : 'weekly',
                model_year : [],
                model_month : [],
                date : { year:'', month:'', day:''},
                startdate : new Date( Date.now() + -0*24*3600*1000 ).toISOString().slice(0,10),
                total_trx : {
                    saldo : 0,
                    saldo_idr : 0,
                    success : 0,
                    success_idr : 0
                },
                ts_max_count : 0,
                ts_max_idr : 0,
                ts_max_date : 0,
                ts_min_count : 0,
                ts_min_idr : 0,
                ts_min_date : 0,
                report_title : { start_date : '', end_date : '' },
                // identified users special
                env : localStorage.getItem('is_enable_env'),
                freseller : localStorage.getItem('user-code'),
            };
        },
        created()
        {
            this.InitServerDate();

            this.QueryChartTrxSuccess();
            this.QueryChartTrxSaldo();
            this.QueryChartTrxTotal();
            this.QueryChartPercentage();

            this.mixmeta_data.okey = 'ref_id';
            this.mixmeta_data.oval = 'desc';
            this.ServiceInitFunction('QueryTrxTable', 's-table');

        },
        methods: 
        {
            InitServerDate()
            {
                this.year = [];
                this.date.year = this.ServerDateService('active_year');
                this.date.month = parseInt(this.ServerDateService('active_month'));
                this.date.day = this.ServerDateService('active_day');
                this.InitDateYear();
                this.InitDateMonth();
            },
            InitDateMonth()
            {
                for(var i=1; i<=12; i++){
                    this.model_month.push(i);
                }
            },
            InitDateYear()
            {
                for(var i=2020; i<=this.date.year; i++){
                    this.model_year.push(i);
                }
            },
            FilterDate(initial)
            {
                this.isActiveFilterDate = initial;
                this.QueryChartTrxSaldo();
                this.QueryChartTrxSuccess();
                this.QueryChartPercentage();
                this.QueryChartTrxTotal();
                this.QueryTrxTable();
               
            },
            GenerateReportTitle(initial)
            {
                if( initial == 'period' )
                {
                    if(this.isActiveFilterDate == 'weekly'){
                        return '7 Hari Terakhir';
                    }
                    if(this.isActiveFilterDate == 'monthly'){
                        return 'Bulanan';
                    }
                    if(this.isActiveFilterDate == 'yearly'){
                        return 'Tahunan';
                    }
                }
            },
            async QueryChartTrxSaldo()
            {
                this.isRefreshChartTrxSaldo = true;
                let res = await this.ServiceApiPost({
                    url:'h2h/'+this.env+'/transaction-summary-chart-saldo', 
                    data : {
                        fmode : this.isActiveFilterDate,
                        code_otmx_reseller : this.freseller
                    }
                });
                if(res && res.result)
                { 
                    let model = res.query;
                    this.total_trx.saldo = model.total_data;
                    this.total_trx.saldo_idr = model.total_idr;

                    this.report_title.start_date = model.start_date;
                    this.report_title.end_date = model.end_date;
                    this.BuildChartTrxSaldo(model.categories, model.saldo);

                    this.isRefreshChartTrxSaldo = false;
                }
            },
            async QueryTrxTable()
            {
                this.isRefreshTable = true;

                var filter_reseller = '';
                if( this.env == 'production' ){
                    filter_reseller = 'reseller.kode';
                }else{
                    filter_reseller = 'code_otmx_reseller';
                }

                let res = await this.ServiceApiDataPaging({
                    url: 'h2h/'+this.env+'/transaction-summary-table/'+this.isActiveFilterDate, 
                    offset : this.page,
                    search: { key : this.mixmeta_data.skey, value : this.mixmeta_data.sval },
                    order: { key : this.mixmeta_data.okey, value : this.mixmeta_data.oval },
                    filter : [
                        {key : filter_reseller, val:this.freseller},
                    ]
                });
                if(res){
                    this.mixmodel = res.query;
                    if(this.isActiveFilterDate !== 'weekly')
                    {
                        this.report_title.start_date = res.init_period;
                    }

                    this.isRefreshTable = false;
                    this.isFirstLoad = false;
                }
            },
            BuildChartTrxSaldo(cat, trx_success)
            {
                Highcharts.chart('chart-trx-saldo', {

                    credits: {
                        enabled: false
                    },
                    chart: {
                        backgroundColor: 'transparent',
                        style: {
                            fontFamily: 'Poppins Light',
                            fontSize:'14px'
                        }
                    },
                    colors: ['#ffc16f'],
                    title: {
                        text:''
                    },
                    subtitle: {
                        text: null
                    },

                    yAxis: {
                        labels: {
                            min: 0,
                            minRange: 0.1,
                            formatter: function () {
                                return Highcharts.numberFormat(this.value, 0);
                            }
                        },
                        title: {
                            text: null
                        }
                    },

                    xAxis: {
                        categories: cat,
                        crosshair: true
                    },
                    series: [{
                        showInLegend: false,
                        name: '',
                        data: trx_success
                    }],
                    responsive: {
                        rules: [{
                            condition: {
                                maxWidth: 500
                            },
                            chartOptions: {
                                legend: {
                                    layout: 'horizontal',
                                    align: 'center',
                                    verticalAlign: 'bottom'
                                }
                            }
                        }]
                    }
                });
            },
            async QueryChartTrxSuccess()
            {
                this.isRefreshChartTrxSuccess = true;
                let res = await this.ServiceApiPost({
                    url:'h2h/'+this.env+'/transaction-summary-chart-success', 
                    data : {
                        fmode : this.isActiveFilterDate,
                        code_otmx_reseller : this.freseller
                    }
                });
                if(res && res.result)
                { 
                    let model = res.query;
                    
                    this.total_trx.success = model.total_data;
                    this.total_trx.success_idr = model.total_idr;
                    this.ts_max_idr = model.max_idr;
                    this.ts_max_count = model.max_count;
                    this.ts_max_date = model.max_date;
                    this.ts_min_idr = model.min_idr;
                    this.ts_min_count = model.min_count;
                    this.ts_min_date = model.min_date;

                    this.BuildChartTrxSuccess(model.categories, model.success);
                    this.isRefreshChartTrxSuccess = false;
                }
            },
            BuildChartTrxSuccess(cat, trx_success)
            {
                Highcharts.chart('chart-trx-success', {

                    credits: {
                        enabled: false
                    },
                    chart: {
                        type :'area',
                        backgroundColor: 'transparent',
                        style: {
                            fontFamily: 'Poppins Light',
                            fontSize:'14px'
                        }
                    },
                    colors: ['#f7645f'],
                    title: {
                        text:''
                    },
                    subtitle: {
                        text: null
                    },

                    yAxis: {
                        labels: {
                            min: 0,
                            minRange: 0.1,
                            formatter: function () {
                                return Highcharts.numberFormat(this.value, 0);
                            }
                        },
                        title: {
                            text: null
                        }
                    },
                    xAxis: {
                        categories: cat,
                        crosshair: true
                    },
                    series: [{
                        showInLegend: false,
                        name: '',
                        data: trx_success
                    }],
                    responsive: {
                        rules: [{
                            condition: {
                                maxWidth: 500
                            },
                            chartOptions: {
                                legend: {
                                    layout: 'horizontal',
                                    align: 'center',
                                    verticalAlign: 'bottom'
                                }
                            }
                        }]
                    }
                    });
            },

            async QueryChartTrxTotal()
            {
                this.isRefreshChartTrxTotal = true;
                let res = await this.ServiceApiPost({
                    url:'h2h/'+this.env+'/transaction-summary-chart', 
                    data : {
                        fmode : this.isActiveFilterDate,
                        code_otmx_reseller : this.freseller
                    }
                });
                if(res && res.result)
                { 
                    let model = res.query;
                    this.BuildChartTrxTotal(model.categories, model.saldo, model.success);
                    this.isRefreshChartTrxTotal = false;
                }
            },
            
            BuildChartTrxTotal(cat, saldo, trx_success)
            {
                Highcharts.chart('chart-trx-total', {
                    credits: {
                        enabled: false
                    },
                    chart: {
                        type: 'column',
                        backgroundColor: 'transparent',
                        style: {
                            fontFamily: 'Poppins Light'
                        }
                    },
                    colors: ['#f4b366', '#f7645f', '#fe7e96'],
                    options3d: {
                        enabled: true,
                        alpha: 10,
                        beta: 25,
                        depth: 70
                    },
                    title: {
                        text: ''
                    },
                    subtitle: {
                        text: 'Data perbandingan transaksi dan saldo'
                    },
                    xAxis: {
                        categories: cat,
                        crosshair: true
                    },
                    yAxis: {
                        labels: {
                            min: 0,
                            formatter: function () {
                                return Highcharts.numberFormat(this.value, 0);
                            }
                        },
                        title: {
                            text: null
                        }
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                            '<td style="padding:0"><b>Rp {point.y:,.0f}</b></td></tr>',
                        footerFormat: '</table>',
                        shared: true,
                        useHTML: true
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0,
                            borderWidth: 0
                        }
                    },
                    series: [{
                        name: 'Saldo',
                        data: saldo
                    }, {
                        name: 'Trx Sukses',
                        data: trx_success
                    }]
                });
            },
            
            async QueryChartPercentage()
            {
                this.isRefreshChartTrxPie = true;

                let res = await this.ServiceApiPost({
                    url:'h2h/'+this.env+'/transaction-summary-percentage', 
                    data : {
                        fmode : this.isActiveFilterDate,
                        code_otmx_reseller : this.freseller
                    }
                });
                if(res && res.result)
                { 
                    let model = res.query;
                    this.BuildPieChartPercentage(model);
                    this.isRefreshChartTrxPie = false;
                }
            },
            BuildPieChartPercentage(data_percentage)
            {
                Highcharts.chart('piechart-trx-all', {
                    credits: {
                        enabled: false
                    },
                    chart: {
                        backgroundColor: 'transparent',
                        plotBackgroundColor: null,
                        plotBorderWidth: null,
                        plotShadow: false,
                        type: 'pie'
                    },

                    title: {
                        text: '',
                    },
                    tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                    },
                    accessibility: {
                        point: {
                            valueSuffix: '%'
                        }
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            colors: ['#f4b366', '#f7645f'],

                            borderRadius: 5,
                            dataLabels: {
                                enabled: true,
                                format: '<b>{point.name}</b><br>{point.percentage:.1f} %',
                                distance: -50,
                                filter: {
                                    property: 'percentage',
                                    operator: '>',
                                    value: 4
                                }
                            }
                        }
                    },
                    series: [{
                        name: 'Share',
                        data: data_percentage
                    }]
                });
            },
            
        }
    }
</script>


<style lang="scss" scoped>

.loading-dots--dot{width:4px; height:4px;}
#report-header{
    width:100%;
    padding:15px;
    float:left;
    text-align:center;
    font-family :'Poppins Light';
    margin-bottom:20px;
    h4, span{
        font-family: inherit;
    }
    span{font-size:13px; font-weight: 200;}

}
#report-filter
{
    float:left;
    width:100%;
    border-top:1px solid #cecece;
    border-bottom:1px solid #cecece;
    padding:10px 0px;
    span.total{
        position:relative;
        top:8px;
        font-size:13px;
    }
    ul{
        float:right;
        padding:0;
        margin:0;
        li{
            float:left;
            width:auto;
            margin:0;
            padding:0;
            list-style:none;
            margin-left:5px;
            a{
                padding:3px 28px;
                display: block;
                background-color:#ffffff52;
                text-decoration: none;
                color:#000;
                border-radius:2px;
                span{
                  
                    font-size:12px;
                }
                i{
                    color:#f15735;
                    border-right:1px dashed #d7d1ca;
                    padding-right:5px;
                    margin-right:5px;
                }
            }
            a:hover{
                background-color:#ffffff9c;
            }
            a.active{
                background-color:#f15735;
                color:#fff;

            }
        }
    }
    .form-group{
        float:right;
        width:150px;
        margin-bottom:0;
        margin-top:0;
    }

}

#report-chart{
    float:left;
    width:100%;
    margin-top:20px;
    
    .cover-chart{
        float:left;
        background:#ffffff36;
        padding:10px;
        width:100%;
        height:auto;
        
        #chart-trx-total{
            width:100%;
            height:380px;
        }
    }
    
    .cover-info{
        float:left;
        background:#ffffff36;
        padding:15px;
        width:100%;
        height:auto;
       
        .cover-circle-info{
            width:100%;
            float:left;
            .circle-info{
                width:80px;
                height:80px;
                border-radius:50%;
                background-color:#fff;
                text-align:center;
                border:7px solid #fff1f1;
                float:left;
                position:relative;
                overflow:hidden;
                .mdi{
                    position: absolute;
                    font-size: 80px;
                    left: -20px;
                    top: -19px;
                    color: #fcffee36;
                }

                span{
                    position:relative;
                    top:15px;
                    font-size:17px;
                    color:#fff;
                    margin-bottom:10px;
                    font-weight: 300;
                }
                small{
                    position:relative;
                    top:7px;
                    margin-top:20px;
                }
            }

            .circle-orange{
                background:#fe8f8b;
            }
            .circle-yellow{
                background:#fec77e;
            }

            .circle-desc{
                width:85%;
                float:left;
                padding:20px 0px 0px 7px;
                font-size:14px;
                span{font-size:12px;}
                .idr{
                    position:relative;
                    top:10px;
                    padding:4px 10px 4px;
                    background-color:#fbfbfb42;
                    font-size:14px;
                }
                .idr-orange{background-color:#fe8f8b36;}
                .idr-yellow{background-color:#fec77e38;}
            }
        }
       
        

        .cover-mini-chart{
            margin-top:30px;
            position:relative;
            width:100%;
            height:390;
            float:left;
            overflow-x:scroll;

        }
        #carouselChart{
            height:402px;
            .carousel-indicators{
                li{
                    background-color:#f15b35;
                }
            }
            #chart-trx-saldo, #chart-trx-success{
                float:left;
                width:100%;
                height:235px;
            }
    
            .chart-weekly{
                width:100%;
            } 
            .chart-monthly{
                width:1000px;
            }
        }
        
       
    }
   

    #piechart-trx-all{
        float:left;
        width:100%;
        height:190px;
    }

    .summary-info{
        position:relative;
        float:left;
        width:100%;
        height:auto;
        background:#ffffff36;
        padding:15px;
        margin-top:3px;
        i.indicator{
            position:relative;
            font-size: 23px;
            color: #f4825e;
            width:35px;
            height:35px;
            border-radius:50%;
            background-color:#ffffff96;
            text-align:center;
            float:right;
            top:0px;
        }
        .date{
            position:absolute;
            right:10px;
            bottom:20px;
        }
        span{
            font-size:13px;
        }
        h6{
            font-size:14px;
            position:relative;
            font-weight: 200;
        }
    }
}
</style>