<template>
    <main>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Deposit Saldo
                    </h1>
                    <div class="page-header-subtitle text-secondary">Pilih bank favorit anda dan tentukan jumlah nominal saldo deposit.</div>
                </div>
                <div class="col-md-2 mt-4">
                    <a href="javascript:;" class="nav-module" @click="$router.go(-1)"><i class="mdi mdi-arrow-left mdi-icon"></i></a>
                </div>
            </div>
        </header>
        <hr/>
        <div class="row">
            <div class="col-xl-4 pr-0">
                <div class="card p-4">
                    <div class="">
                        <label><i class="mdi mdi-information-outline text-warning"></i> Metode Pembayaran </label><br/>
                        <select class="form-control" v-model="isDepositFrom" >
                            <option value="transfer_bank">Transfer Bank</option>
                            <option value="mart">Via Mart</option>
                        </select>
                        <hr/>
                    </div>
                    <!-- via bank -->
                    <div v-if="isDepositFrom == 'transfer_bank'">
                        <label>Pilih bank favorit  <small class="text-secondary">( Geser ke kanan/kiri )</small></label>
                        <div class="icon-list mt-2">
                            <div class="scrollx">
                                <ul>
                                    <li v-for="(items, idx) in model_bank" :key="idx">
                                        <i class="icon-check mdi mdi-checkbox-marked-circle" v-if="items.id == model.vendor "></i>
                                        <a href="javascript:;" @click="SelectVendor((idx))">
                                            <div class="picture"><img :src="items.logo" :class="items.logo"/></div>
                                            <small>{{ items.bank }}</small>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <small class="text-danger" v-if="service_form_error.vendor">{{ service_form_error.vendor[0] }}</small>
                    </div>
                    <!-- via mart -->
                    <div v-if="isDepositFrom == 'mart'">
                        <label>Pilih minimarket favorit  <small class="text-secondary">( Geser ke kanan/kiri )</small></label>
                        <div class="icon-list mt-2">
                            <div class="scrollx">
                                <ul>
                                    <li v-for="(items, idx) in model_mart" :key="idx">
                                        <i class="icon-check mdi mdi-checkbox-marked-circle" v-if="items.id == model.vendor"></i>
                                        <a href="javascript:;" @click="SelectVendor((idx))">
                                            <div class="picture"><img :src="items.logo" :class="items.logo"/></div>
                                            <small>{{ items.name }}</small>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <small class="text-danger" v-if="service_form_error.vendor">{{ service_form_error.vendor[0] }}</small>
                    </div>
                    <div class="guides">
                        <label><i class="mdi mdi-information-outline text-warning"></i>  Keterangan</label><br/>
                        <ul>
                            <li>Minimal jumlah deposit Rp <b>20.000</b></li>
                            <li>Sistem akan otomatis menambahkan deposit dengan angka unik yang harus ditransfer.</li>
                            <li>Jam operasional deposit <b>10:00</b> - <b>21:00</b></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-xl-8">
                <!-- Account details card-->
                <div class="card mb-4">
                    <div class="card-body">
                        <div >
                            <div class="box-data mt-3">
                                <label><i class="mdi mdi-information-outline text-warning"></i>  Pilih Jumlah Deposit <br/><small class="text-secondary">Silahkan pilih salah satu jumlah saldo yang diinginkan.</small></label>
                                <ul>
                                    <li>
                                        <a href="javascript:;" :class="model.balance == 50000 ? 'active' : ''" @click="ChooseBalance(50000)">
                                            <lottie-player src="/assets/images/lotties/money/uang_1.json" background="Transparent"   autoplay ></lottie-player>
                                            <span><b>Rp</b> 50.000</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:;"  :class="model.balance == 100000 ? 'active' : ''" @click="ChooseBalance(100000)">
                                            <lottie-player src="/assets/images/lotties/money/uang_2.json" background="Transparent"  autoplay></lottie-player>
                                            <span><b>Rp</b> 100.000</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:;"  :class="model.balance == 300000 ? 'active' : ''" @click="ChooseBalance(300000)">
                                            <lottie-player src="/assets/images/lotties/money/uang_3.json" background="Transparent"  autoplay></lottie-player>
                                            <span><b>Rp</b> 300.000</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:;"  :class="model.balance == 500000 ? 'active' : ''" @click="ChooseBalance(500000)">
                                            <lottie-player src="/assets/images/lotties/money/uang_4.json" background="Transparent"  autoplay></lottie-player>
                                            <span><b>Rp</b> 500.000</span>
                                        </a>
                                    </li>
                                </ul>
                                <div class="or">
                                    <span class="text-secondary">ATAU</span>
                                </div>
                            </div>
                            <div class="box-data">
                                <div class="input">
                                    <label><i class="mdi mdi-information-outline text-warning"></i>  Custom Jumlah IDR</label>
                                    <input type="number" class="form-control"  placeholder="0" v-model="model.balance"/>
                                    <small class="text-danger" v-if="service_form_error.balance"><br>{{ service_form_error.balance[0] }}</small>
                                </div>
                                <div class="mt-4">
                                    <span class="text-secondary">
                                        Ketik jumlah saldo yang akan di topup.<br/>
                                        <b class="text-danger">!</b> Inputan harus dalam format numeric (IDR)
                                    </span>
                                </div>
                            </div>
                            <div class="box-data mt-3 mb-4">
                                <hr/>
                                <div class="float-left mt-4">
                                    <b class="text-danger">!</b> <span class="text-secondary"> Periksa kembali nominal deposit sebelum submit.</span>
                                </div>
                                <div class="float-right mt-3">
                                    <ButtonProcess :classes="'btn btn-sm btn-warning'" :submit_state="isSubmitProcess" :type="'Button'" :title="'Lanjutkan Deposit'"  @click="SubmitDeposit"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>


<script>
    export default {
       
        data() {
            return {
                isFirstLoad : true,
                isDepositFrom : 'transfer_bank',
                isSubmitProcess : false,

                model : { balance : 0 },
                model_bank : [],
                model_mart : [],
                env : localStorage.getItem('is_enable_env')

            }
        },
       
        created()
        {
            this.QueryBank();
            this.QueryMart();
        },
        methods:
        {
            ChooseBalance(balance)
            {
                this.model.balance = balance;
            },
            SelectVendor(idx)
            {
                if( this.isDepositFrom == 'transfer_bank' ){
                    this.model.vendor = this.model_bank[idx].id;
                }
                if( this.isDepositFrom == 'mart' ){
                    this.model.vendor = this.model_mart[idx].id;
                }
            },
            async QueryBank()
            {
                this.isFirstLoad = true;
                let res = await this.ServiceApiPost({
                    url:'payment_method/bank'
                });
                if(res && res.result)
                {
                    this.model_bank = res.query;
                    this.isFirstLoad = false;
                }
            },
            async QueryMart()
            {
                this.isFirstLoad = true;
                let res = await this.ServiceApiPost({
                    url:'payment_method/mart'
                });
                if(res && res.result)
                {
                    this.model_mart = res.query;
                    this.isFirstLoad = false;
                }
            },
            async SubmitDeposit()
            {
                this.isSubmitProcess = true;

                let res = await this.ServiceApiPost({
                    url:'h2h/'+this.env+'/balance/store',
                    data : {
                        env : localStorage.getItem('is_enable_env'),
                        deposit_by : this.isDepositFrom,
                        vendor : this.model.vendor,
                        balance : this.model.balance,
                        code_otmx_reseller : localStorage.getItem('user-code')
                    }
                });
                if(res)
                {
                    if(res.result)
                    {

                        localStorage.setItem('QUERY_DEPOSIT_BY', this.isDepositFrom);
                        localStorage.setItem('QUERY_ID', this.model.vendor);
                        localStorage.setItem('QUERY_AMOUNT', res.query.jumlah);
                        localStorage.setItem('QUERY_UNIQUE_CODE', res.query.unique_code);
                        if( this.isDepositFrom == 'mart' )
                        {
                            localStorage.setItem('QUERY_KETERANGAN', res.query.keterangan);
                        }

                        if( res.query.invoice_send_to ){
                            localStorage.setItem('QUERY_INV_SEND_TO', res.query.invoice_send_to);
                        }
                        this.$router.push({name : 'balance-confirm'});

                    }

                    this.isSubmitProcess = false;
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
#topup{
    padding: 10px 20px 5px;
    border: 2px solid rgba(253, 63, 0, 0.424);
    border-radius: 15px;
    background-color: #fbfbfb5c;
}
.guides{
    float:left;
    border-top: 1px solid #d2d2c442;
    margin: 12px 0px 0px 0px;
    padding: 19px 7px 0px;

    ul{
        margin:0;
        padding:0px 15px;
        li{
            width:100%;
            float:left;
            padding:3px;
            font-size:13px;
            color:#262626;
        }
    }
}

.box-data{
    width:100%;
    float:left;
   
    ul{
        float:left;
        width:100%;
        margin:0;
        padding:0;
        margin-top:5px;
        li{
            float:left;
            width:22%;
            margin:10px;
            list-style:none;
            text-align:center;
            a{
                display: block;
                border-radius:20px;
                border:1px solid #ee4a2f8a;
                padding:5px;
                span{color:#5d5d5d;}
                lottie-player{
                    margin:0 auto;
                    width:60px;
                    height:60px;
                }
            }
            a:hover, a.active{
                background-color:#ffffff6c;
            }
        }
    }
    div.input{
        float:left;
        width:48%;
        margin-right:20px;
        input[type="number"]{
            float:left;
            width:100%;
            border-radius:25px;
            padding:20px;
            font-size:15px;
            color:#ec3d2f;
            font-family :'Poppins Light';
        }
        small.text-danger{
            position:relative;
            top:5px;
            left:5px;
        }
    }
    
    .or{
        position: relative;
        float:left;
        width:100%;
        margin-top:30px;
        margin-bottom:15px;
        text-align:center;
        span{
            position: absolute;
            margin:0 auto;
            left:0;
            right:0;
            top:-10px;
        }
    }
    .or:before{
        float:left;
        content : '.';
        color:transparent;
        width:47%;
        border-top:1px solid #f2d5c4;
    }
    .or:after{
        float:right;
        content : '.';
        color:transparent;
        width:47%;
        border-top:1px solid #f2d5c4;
    }
   
}

.icon-list{
    border-radius:10px;
    height:90px;
    .scrollx{
        width:360px;
        ul{
            li{
                text-align:center;
                i{
                    top: -10px;
                    left: 7px;
                }
                a{
                    .picture{
                        width:80px;
                        height:50px;
                        border-radius:10px;
                        background-color:#ffffff7a;
                        border:1px solid #ff5c0024;
                        
                        img{
                            position: relative;
                            left: 0;
                            top: 12px;
                            width: 58%;
                        }
                    }
                    .picture:hover{
                        background-color:rgb(243, 243, 243);
                    }

                    small{
                        color:#424242;
                    }
                }
            }
        }
       
    }
}
</style>