<template>
    <div>
        <div class="box-images mb-2" v-bind:style="{ width: styleObject.width, height: styleObject.height, background:styleObject.background }">
            <i class="mdi mdi-file-image"></i>

            <img :src="data_image" v-if="data_image !== ''" id="sys-upload-single-image" v-bind:style="{ padding: styleObject.img_padding, width:styleObject.img_width, height:styleObject.img_height }">
            <span class="group-input-file">
                <input type="file" class="form-control input-file" :accept="file_type"  @change="processFile($event)">
            </span>
        </div>
    </div>   
</template>

<script>
    export default
    {
        name: 'UploadSingleImage',
        props: [
            'layout_width', 
            'layout_height', 
            'min_width', 
            'min_height', 
            'image_width', 
            'image_height', 
            'default_image',  
            'index', 
            'type',
            'layout'
        ],
        data () {
            return { 
                max_image_size : 10097152, // 10 MB;
                data_image: '',
                file_type : this.type ? this.type : 'image/*',
                styleObject : {
                    width : 100+'%',
                    height : 200+'px',
                    background : 'whitesmoke',

                    img_width:  this.layout == 'potrait' ? 'auto' : 100+'%',
                    img_height: this.layout == 'potrait' ? '100%' : 'auto',
                    img_padding: 0+'px',
                }
            }
        },
        mounted() 
        {
            if( this.default_image !== '' )
            {
                this.data_image = this.default_image;
            }
            if( this.type ){
                this.file_type = this.type;
            }
            if( this.layout_width){
                this.styleObject.width  = this.layout_width;
            }
            if(this.layout_height ){
                this.styleObject.height = this.layout_height;
            }
            if( this.image_width){
                this.styleObject.img_width  = this.image_width;
            }
            if( this.image_height){
                this.styleObject.img_height  = this.image_height;
            }
        },
        methods: 
        {
            processFile(event)
            {
                this.file = event.target.files[0];
                if (event.target.files && event.target.files[0])
                {
                    let fileSize = event.target.files[0].size;
                    if( parseInt(fileSize) > this.max_image_size ){
                        alert('File image cannot more than 10 MB');
                    }else
                    {
                        let reader = new FileReader();
                        reader.onload = (event) =>
                        {
                            let img = new Image;
                            img.onload = () =>{
                                this.data_image = event.target.result;
                                this.$emit('processFile', this.data_image, this.index, this.file);
                            }
                            img.src = event.target.result;
                        }
                        reader.readAsDataURL(event.target.files[0]);
                    }
                }
            },
        }
    }
</script>

<style lang='scss' scoped>
    .box-images {
        color: dimgray;
        overflow:hidden;
        position: relative;
        text-align:center;
        background-color:#f5f5f5ba;
        border:5px solid #fff;
        
        i.mdi{
            position:absolute;
            margin:0 auto;
            left:0;
            right:0;
            top:35%;
            font-size:45px;
        }
        .input-file, .group-input-file:after{
            position:absolute;
            bottom:50px;
            left:0;
            right:0;
            bottom:20px;
            margin:0 auto;
            width:40px;
            height:40px;
            cursor: pointer;
        }
        .input-file{
            z-index: 9;  
            width:100%;
            height:100%;
            opacity: 0;
            top:0;

        }
        .group-input-file{
            &:after{
                content: "Browse File";
                font-size: 12px;
                background-color: #f9f9f9;
                width: 100px;
                z-index: 2;
                color:#000;
                text-align: center;
                width: 100px;
                height: 30px;
                padding: 5px;
                border-radius: 3px;
            }
        }
        img{
            position: relative;
            z-index: 2;
            cursor:auto;
            background-color:#b7b7b7;
        }
        p {
            font-size: 1em;
            text-align: center;
            padding: 50px 0;
        }
        svg{
            font-size:50px;
            top:30%;
            position: absolute;
            z-index: 9;
            left:0;
            right:0;
            margin:0 auto;
            opacity:.5;
            color:#bbbbbb;
        }
    }
    .box-images:hover { background: whitesmoke; }
    .box-images-info{
        width:100%;
        overflow:hidden;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        font-size:12px;
        margin-top:15px;
    }
</style>