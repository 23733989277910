<template>
    <img src="assets/img/loader.gif"  id="loader-animate"/>
</template>

<script>
export default {
  name: 'Loader'
}
</script>

<style lang="scss" scoped>
#loader-animate{
    width:50px;
    position:absolute;
    left:0;
    right:0;
    top:40%;
    margin:0 auto;
    opacity:.5;
}
</style>