<template>
    <main>
        <header>
            <div class="row">
                <div class="col-md-8 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        DASHBOARD
                    </h1>
                    <div class="page-header-subtitle text-secondary">Hi, Selamat Datang di Dashboard Dflash.</div>
                </div>
                <div class="col-md-4 mt-4">
                    <div class="box-date">
                        <span class="dt-day">{{ date.day }}</span>
                        <span class="dt-year">{{ date.year }}</span>
                        <span class="dt-month">{{ $filters.convertStringMonth(date.month) }}</span>
                        <div class="dt-time"><span  id="init-clock">00:00</span></div>
                    </div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-md-4 pr-2" v-for="(items, idx) in model_banner" :key="idx">
                    <div class="banner">
                        <a href="javascript:;" @click="DetailBannerPromo(items.id)"><img :src="$store.state.api_resource + items.photo"/></a>
                    </div>
                </div>
            </div>
        </header>
        <hr/>
        <div class="row" v-if="!isRefreshChartTrxSuccess">
            <div class="col-md-12" >
               <small class="text-secondary float-right">( 7 Hari Terakhir )</small><br/>

                <div class="box-marquee">
                    <ul>
                        <li class="item-1"><i class="mdi mdi-information-outline text-danger"></i> Informasi Statistik TRX</li>
                    </ul>  
                </div>
                <div class="marquee">
                    <div class="track">
                        <div class="content">
                             <div class="text-analytics">
                                <i class="indicator mdi mdi-arrow-up text-success"></i>
                                Data transaksi tertinggi <span class="text-success">{{ $filters.formatNumber(ts_max_count) }}</span> <span class="text-secondary">TRX</span>
                                 - 
                                Rp {{ $filters.formatNumber(ts_max_idr) }}  <span class="date">{{ ts_max_date }}</span>
                             </div>
                             <div class="text-analytics">
                                <i class="indicator mdi mdi-arrow-down text-danger"></i>
                                Data transaksi terendah <span class="text-danger">{{ $filters.formatNumber(ts_min_count) }}</span> <span class="text-secondary">TRX</span>
                                 - 
                                Rp {{ $filters.formatNumber(ts_min_idr) }}  <span class="date">{{ ts_min_date }}</span>
                             </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <hr v-if="!isRefreshChartTrxSuccess"/>
        <div class="row">
            <div class="col-md-12 mt-2">
                <label><i class="mdi mdi-information-outline text-danger"></i> Summary transaksi & akumulasi</label>
            </div>
            <div class="col-md-8">
                <div class="row">
                    <div class="col mb-4 mt-4">
                        <div class="box-header">
                            <div class="bg"><i class="mdi mdi-chart-bubble"></i></div>
                            <span class="up-down-text"  v-if="!isLoadSummaryTrxProcess">Transaksi hari ini</span>

                            <div class="spinner-border  spinner-border-sm" role="status" v-if="isLoadSummaryTrxProcess"></div>
                            <i class="indicator mdi mdi-sync-alert text-warning" v-else></i>
                            <div class="left">
                                <h6>Transaksi Proses</h6>
                                <span><b>Rp</b> {{ $filters.formatNumber(trx.idr_process) }}</span>
                            </div>
                            <div class="right">
                                <h3>{{ $filters.formatNumber(trx.process) }}</h3>
                                <small>TRX</small>
                            </div>
                        </div>
                    </div>
                    <div class="col mb-4 mt-4 pl-0">
                        <div class="box-header">
                            <div class="bg"><i class="mdi mdi-chart-bubble"></i></div>
                            <span class="up-down-text"  v-if="!isLoadSummaryTrxFailed">Transaksi hari ini</span>

                            <div class="spinner-border  spinner-border-sm" role="status" v-if="isLoadSummaryTrxFailed"></div>
                            <i class="indicator mdi mdi-alert-circle-outline text-danger" v-else></i>

                            <div class="left">
                                <h6>Transaksi Gagal</h6>
                                <span><b>Rp</b> {{ $filters.formatNumber(trx.idr_failed) }}</span>
                            </div>
                            <div class="right">
                                <h3>{{ $filters.formatNumber(trx.failed) }}</h3>
                                <small>TRX</small>
                            </div>
                        </div>
                    </div>
                    <div class="col mb-4 mt-4 pl-0">
                        <div class="box-header">
                            <div class="bg"><i class="mdi mdi-chart-bubble"></i></div>
                            <span class="up-down-text"  v-if="!isLoadSummaryTrxSuccess">Transaksi hari ini</span>

                            <div class="spinner-border  spinner-border-sm" role="status" v-if="isLoadSummaryTrxSuccess"></div>
                            <i class="indicator mdi mdi-check-all text-success" v-else></i>

                            <div class="left">
                                <h6>Transaksi Sukses</h6>
                                <span><b>Rp</b> {{ $filters.formatNumber(trx.idr_success) }}</span>
                            </div>
                            <div class="right">
                                <h3>{{ $filters.formatNumber(trx.success) }}</h3>
                                <small>TRX</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="box-header box-saldo mt-4">
                    <div class="bg"><i class="mdi mdi-cards-playing-outline"></i></div>
                    <span class="up-down-text"  v-if="!isLoadSummaryTrxTotal">Saldo, Komisi dan Poin</span>
                    
                    <div class="spinner-border  spinner-border-sm" role="status" v-if="isLoadSummaryTrxSuccess"></div>
                    <i class="indicator mdi mdi-coin" v-else></i>
                    <div class="left pr-2">
                        <div id="total">
                            <span class="title">Saldo</span>
                            <div class="loading-dots" v-if="isLoadSummaryTrxTotal"><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div></div>
                            <span class="" v-else><b>Rp</b> {{ $filters.formatNumber(trx.idr_total) }}</span>
                            <div class="delimiter"></div>
                            <span class="title">Komisi</span>
                            <div class="loading-dots" v-if="isLoadSummaryTrxTotal"><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div></div>
                            <span class="" v-else><b>Rp</b> {{ $filters.formatNumber(trx.idr_komisi) }}</span>
                        </div>
                    </div>
                    <div class="right">
                        <h3>{{ $filters.formatNumber(trx.poin) }}</h3>
                        <small>POIN</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-1">
            <div class="col-md-6">
                <div class="cover-chart">
                    <div class="table-info">
                        <div class="float-left">
                            <label><i class="mdi mdi-information-outline text-danger"></i> Deposit & Transaksi <small class="text-secondary">( 7 Hari Terakhir )</small></label>
                        </div>
                        <div class="float-right">
                            <ul>
                                <li>
                                    <router-link :to="{ name: 'report-transaction' }" ><i class="mdi mdi-link-variant mdi-icon"></i> </router-link>
                                </li>
                                <li class="border-left">
                                    <i class="mdi mdi-refresh" v-if="! isRefreshChartTrx" @click="QueryChartTrx()"></i>
                                    <div class="spinner-border  spinner-border-sm" role="status" v-else></div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div id="chart-trx" :class="isRefreshChartTrx ? 'block-load' : '' "></div>
                </div>
            </div>
            <div class="col-md-6 pl-0">
                <div class="cover-info" >
                    <div class="table-info">
                        <div class="float-left">
                            <label><i class="mdi mdi-information-outline text-danger"></i> Detail Total Trx & Saldo <small class="text-secondary">( 7 Hari Terakhir )</small></label>
                        </div>
                        <div class="float-right">
                            <ul>
                                <li>
                                    <router-link :to="{ name: 'report-transaction' }" ><i class="mdi mdi-link-variant mdi-icon"></i> </router-link>
                                </li>
                                <li class="border-left">
                                    <i class="mdi mdi-refresh"  @click="QueryChartSaldoAndSuccess()"></i>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="chart-info">
                        <ul class="data-list">
                            <li>
                                <div class="float-left">
                                    <div class="circle-info circle-yellow">
                                        <span>{{ $filters.kFormatter(total_trx.saldo) }}</span><br/>
                                        <small>TRX</small>
                                        <i class="mdi mdi-chart-areaspline"></i>
                                    </div>
                                    <div class="circle-desc">
                                        <h6>Deposit Saldo</h6>
                                        <small class="text-secondary">Total Deposit Member</small>
                                    </div>
                                </div>
                                <div class="float-right">
                                    <div class="loading-dots float-right" v-if=isRefreshChartTrxSaldo><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div></div>
                                    <h6 v-else>Rp {{ $filters.formatNumber(total_trx.saldo_idr) }}</h6>
                                </div>
                            </li>
                            <li>
                                <div class="float-left">
                                    <div class="circle-info circle-orange">
                                        <span>{{ $filters.kFormatter(total_trx.success) }}</span><br/>
                                        <small>TRX</small>
                                        <i class="mdi mdi-chart-bar-stacked"></i>
                                    </div>
                                    <div class="circle-desc">
                                        <h6>Transaksi Sukses</h6>
                                        <small class="text-secondary">Total Transaksi Member</small>
                                    </div>
                                </div>
                                <div class="float-right">
                                    <div class="loading-dots float-right" v-if=isRefreshChartTrxSuccess><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div></div>
                                    <h6 v-else>Rp {{ $filters.formatNumber(total_trx.success_idr) }}</h6>
                                </div>
                            </li>
                        </ul>
                        <div id="chart-trx-total" :class="isRefreshChartTrxSuccess ? 'block-load' : ''"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body p-3">
                        <div class="table-info">
                            <div class="float-left">
                                <label><i class="mdi mdi-information-outline text-danger"></i> <b>10</b> Data Transaksi Hari ini</label>
                               
                            </div>
                            <div class="float-right">
                                <ul>
                                    <li>
                                        <router-link :to="{ name: 'transaction' }" ><i class="mdi mdi-link-variant mdi-icon"></i> </router-link>
                                    </li>
                                    <li class="border-left">
                                        <i class="mdi mdi-refresh" v-if="! isRefreshTableTransaction" @click="QueryTransaction()"></i>
                                        <div class="spinner-border  spinner-border-sm" role="status" v-else></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="table-cover table-dashboard">
                            <table :class="isRefreshTableTransaction ? 'table-load' : '' ">
                                <thead>
                                    <tr>
                                        <th>Tanggal</th>
                                        <th>ID Transaksi</th>
                                        <th>Ref ID</th>
                                        <th>Nama Produk</th>
                                        <th>No.Tujuan</th>
                                        <th>Status</th>
                                        <th class="text-center bg-danger-soft">Harga</th>
                                    </tr>
                                </thead>
                                <tbody v-if="model_trans.length > 0">
                                    <tr v-for="(items, idx) in model_trans" :key="idx">
                                        <td width="100">{{ $filters.formatDate(items.tgl_entri, 'DD/MM/YYYY') }}<br/><small class="text-secondary">{{ $filters.formatDate(items.tgl_entri, 'LT') }}</small></td>
                                        <td width="120">{{ items.kode }}</td>
                                        <td width="170">
                                            <span v-if="items.ref_id !== null"><i class="mdi mdi-file-hidden text-success"></i> {{ items.ref_id }}</span>
                                            <span class="text-secondary" v-else>-- --</span>
                                        </td>
                                        <td >{{ items.kode_produk }} - {{ $filters.ucwords(items.nama_produk) }}<br/></td>
                                        <td width="150">{{ $filters.formatPhone(items.tujuan) }}</td>
                                        <td width="150"><span :class="$filters.GenerateTrxClassStatus(items.status)">{{ items.status }}</span></td>
                                        <td width="120" class="text-center">Rp {{ $filters.formatNumber(items.harga) }}</td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="7">
                                            Data kosong / tidak ditemukan.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
    import Highcharts from 'highcharts';
    //import { state } from "@/socket";

    export default 
    {   
        /*
        computed: {
            connected() {
                return state.connected;
            }
        },*/
        data() {
            return {
                isFirstLoad : true,
                isFinishLoad : false,
                isRefreshTableTransaction : false,
                isRefreshChartTrx : false,
                isRefreshChartTrxSaldo : false,
                isRefreshChartTrxSuccess : false,
                
                model_banner : [],
                model_year :[],
                model_month :[],

                env : localStorage.getItem('is_enable_env'),
                date : { year:'', month:'', day:''},
                startdate : new Date( Date.now() + -7*24*3600*1000 ).toISOString().slice(0,10),
                enddate : new Date( Date.now() + -0*24*3600*1000 ).toISOString().slice(0,10),
                model_trans : [],
                trx : {
                    poin : 0,
                    idr_total : 0,
                    idr_komisi : 0,
                    idr_success : 0,
                    idr_failed : 0,
                    idr_process : 0,
                  
                    success : 0,
                    failed : 0,
                    process : 0,
                },
                total_trx : {
                    saldo : 0,
                    saldo_idr : 0,
                    success : 0,
                    success_idr : 0
                },
                ts_max_count : 0,
                ts_max_idr : 0,
                ts_max_date : 0,
                ts_min_count : 0,
                ts_min_idr : 0,
                ts_min_date : 0,

                // identified users special
                freseller : localStorage.getItem('user-code')
            };
        },
        created(){
            
            this.InitServerDate();

            // banner promo
            this.QueryBannerPromo();

            // chart
            this.QueryChartTrxSaldo();
            this.QueryChartTrxSuccess();
            this.QueryChartTrx();

            // data
            this.QuerySummaryTrxFailed();
            this.QuerySummaryTrxSuccess();
            this.QuerySummaryTrxProcess();
            this.QuerySummaryTrxTotal();

            // table
            this.QueryTransaction();
        },
        methods:
        {
            
            InitServerDate()
            {
                this.year = [];
                this.date.year = this.ServerDateService('active_year');
                this.date.month = parseInt(this.ServerDateService('active_month'));
                this.date.day = this.ServerDateService('active_day');
                this.InitDateYear();
                this.InitDateMonth();
            },
            InitDateMonth()
            {
                for(var i=1; i<=12; i++){
                    this.model_month.push(i);
                }
            },
            InitDateYear()
            {
                for(var i=2020; i<=this.date.year; i++){
                    this.model_year.push(i);
                }
            },
            GetIndicatorStatus(status, initial)
            {
                if(initial == 'up-down')
                {
                    if( status == 'stable' ){
                        return 'indicator mdi mdi-chart-line text-warning';
                    }else{
                        return status == 'up' ? 'indicator mdi mdi-arrow-up text-success' : 'indicator mdi mdi-arrow-down text-danger';
                    }
                }

                if(initial == 'text')
                {
                    if( status == 'stable' ){
                        return 'Transaksi Stabil';
                    }else{
                        return status == 'up' ? 'Kenaikan Sebesar ' : 'Turun Sebesar';
                    }
                }
            },
            DetailBannerPromo(id)
            {
                localStorage.setItem('QUERY_ID', id);
                this.$router.push({name : 'detail-promo'});
            },
            async QueryBannerPromo()
            {
                this.isRefreshChartTrx = true;
                let res = await this.ServiceApiPost({
                    url:'banners/data/all',
                    data: {
                        order: { key : 'position', value : 'asc' },
                        filter : [
                            {key : 'type', val : 'promo-h2h'},
                        ],
                    }
                });
                if(res && res.result)
                { 
                    this.model_banner = res.query;
                }
            },
            async QuerySummaryTrxSuccess()
            {
                this.isLoadSummaryTrxSuccess = true;
                let res = await this.ServiceApiPost({
                    url:'h2h/'+this.env+'/transaction-summary-success', 
                    data : {
                        start_date : this.startdate,
                        end_date : this.enddate,
                        code_otmx_reseller : this.freseller,
                        privileges : this.fprivileges
                    }
                });
                if(res){ 

                    let model = res.query;
                    this.trx.success = model.status_success; 
                    this.trx.idr_success = model.idr_success; 

                    this.isLoadSummaryTrxSuccess = false;
                }
            },
            async QuerySummaryTrxFailed()
            {
                this.isLoadSummaryTrxFailed = true;
                let res = await this.ServiceApiPost({
                    url:'h2h/'+this.env+'/transaction-summary-failed', 
                    data : {
                        start_date : this.startdate,
                        end_date : this.enddate,
                        code_otmx_reseller : this.freseller,
                    }
                });
                if(res){ 
                    
                    let model = res.query;
                    this.trx.failed = model.status_failed; 
                    this.trx.idr_failed = model.total_idr_failed; 
                    this.isLoadSummaryTrxFailed = false;
                }
            },
            async QuerySummaryTrxProcess()
            {
                this.isLoadSummaryTrxProcess = true;
                let res = await this.ServiceApiPost({
                    url:'h2h/'+this.env+'/transaction-summary-process', 
                    data : {
                        start_date : this.startdate,
                        end_date : this.enddate,
                        code_otmx_reseller : this.freseller,
                    }
                });
                if(res){ 
                    
                    let model = res.query;
                    this.trx.process = model.status_process; 
                    this.trx.idr_process = model.total_idr_process; 
                    this.isLoadSummaryTrxProcess = false;
                }
            },
            async QuerySummaryTrxTotal()
            {
                this.isLoadSummaryTrxTotal = true;
                let res = await this.ServiceApiPost({
                    url:'h2h/'+this.env+'/transaction-summary-total', 
                    data : {
                        start_date : this.startdate,
                        end_date : this.enddate,
                        code_otmx_reseller : this.freseller
                    }
                });
                if(res){ 
                    
                    let model = res.query;
                    this.trx.idr_total = model.total_idr; 
                    this.trx.poin = model.poin; 
                    this.trx.idr_komisi = model.komisi; 

                    this.isLoadSummaryTrxTotal = false;
                }
            },
            async QueryTransaction()
            {
                this.isRefreshTableTransaction = true;

                var filter_reseller = '';
                let okey = '';
                let oval = 'desc';

                if( this.env == 'production' ){
                    okey = 'tgl_entri';
                    filter_reseller = 'reseller.kode';
                }
                if( this.env == 'staging' ){
                    okey = 'h2h_transaction.created_at';
                    filter_reseller = 'code_otmx_reseller';
                }

                let res = await this.ServiceApiPost({
                    url:'h2h/'+this.env+'/transaction-data', 
                    data: {
                        order: { key : okey, value : oval },
                        filter : [
                            {key : 'start_date', val : this.startdate},
                            {key : 'end_date', val : this.enddate},
                            //{key : 'status', val : 'all'},
                            {key : filter_reseller, val : this.freseller}
                        ],
                    }
                });
                if(res){ 
                    this.model_trans = res.query; 
                    this.isFirstLoad = false;
                    this.isRefreshTableTransaction = false;
                }
            },
           
            async QueryChartTrx()
            {
                this.isRefreshChartTrx = true;
                let res = await this.ServiceApiPost({
                    url:'h2h/'+this.env+'/transaction-summary-chart', 
                    data : {
                        fmode : 'weekly',
                        code_otmx_reseller : this.freseller
                    }
                });
                if(res && res.result)
                { 
                    let model = res.query;
                    this.BuildChartTrx(model.categories, model.saldo, model.success);
                    this.isRefreshChartTrx = false;
                }
            },
            BuildChartTrx(cat, saldo, trx_success)
            {
                /*
                Highcharts.chart('chart-trx', {
                    credits: {
                        enabled: false
                    },
                    chart: {
                        type: 'column',
                        backgroundColor: 'transparent',
                        style: {
                            fontFamily: 'Poppins Light'
                        }
                    },
                    colors: ['#f4b366', '#f7645f'],
                    options3d: {
                        enabled: true,
                        alpha: 10,
                        beta: 25,
                        depth: 70
                    },
                    title: {
                        text: ''
                    },
                    subtitle: {
                        text: ''
                    },
                    xAxis: {
                        categories: cat,
                        crosshair: true
                    },
                    yAxis: {
                        labels: {
                            min: 0,
                            minRange: 0.1,
                            formatter: function () {
                                return Highcharts.numberFormat(this.value, 0);
                            }
                        },
                        title: {
                            text: null
                        }
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                            '<td style="padding:0"><b>Rp {point.y:,.0f}</b></td></tr>',
                        footerFormat: '</table>',
                        shared: true,
                        useHTML: true
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0,
                            borderWidth: 0
                        }
                    },
                    series: [{
                        name: 'Saldo',
                        data: saldo
                    }, {
                        name: 'Trx Sukses',
                        data: trx_success
                    }]
                });*/

                Highcharts.chart('chart-trx', {
                    credits: {
                        enabled: false
                    },
                    chart: {
                        type: 'column',
                        backgroundColor: 'transparent',
                        style: {
                            fontFamily: 'Poppins Light'
                        }
                    },
                    colors: ['#f4b366', '#f7645f'],
                    options3d: {
                        enabled: true,
                        alpha: 10,
                        beta: 25,
                        depth: 70
                    },
                    title: {
                        text: '',
                        align: 'left'
                    },
                    subtitle: {
                        text: ''
                    },
                    xAxis: {
                        categories: cat,
                        crosshair: true,
                        accessibility: {
                            description: 'Countries'
                        }
                    },
                    yAxis: {
                        labels: {
                            min: 0,
                            minRange: 0.1,
                            formatter: function () {
                                return Highcharts.numberFormat(this.value, 0);
                            }
                        },
                        title: {
                            text: null
                        }
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                            '<td style="padding:0"><b>Rp {point.y:,.0f}</b></td></tr>',
                        footerFormat: '</table>',
                        shared: true,
                        useHTML: true
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0
                        }
                    },
                    series: [{
                        name: 'Deposit',
                        data: saldo
                    }, {
                        name: 'Trx Sukses',
                        data: trx_success
                    }]
                });
            },
            QueryChartSaldoAndSuccess()
            {
                this.QueryChartTrxSaldo();
                this.QueryChartTrxSuccess();
            },
            async QueryChartTrxSaldo()
            {
                this.isRefreshChartTrxSaldo = true;
                let res = await this.ServiceApiPost({
                    url:'h2h/'+this.env+'/transaction-summary-chart-saldo', 
                    data : {
                        fmode : 'weekly',
                        code_otmx_reseller : this.freseller
                    }
                });
                if(res && res.result)
                { 
                    let model = res.query;
                    this.total_trx.saldo= model.total_data;
                    this.total_trx.saldo_idr = model.total_idr;
                    this.isRefreshChartTrxSaldo = false;
                }
            },
            async QueryChartTrxSuccess()
            {
                this.isRefreshChartTrxSuccess = true;
                let res = await this.ServiceApiPost({
                    url:'h2h/'+this.env+'/transaction-summary-chart-success', 
                    data : {
                        fmode : 'weekly',
                        code_otmx_reseller : this.freseller
                    }
                });
                if(res && res.result)
                { 
                    let model = res.query;
                    this.total_trx.success = model.total_data;
                    this.total_trx.success_idr = model.total_idr;
                    this.ts_max_idr = model.max_idr;
                    this.ts_max_count = model.max_count;
                    this.ts_max_date = model.max_date;
                    this.ts_min_idr = model.min_idr;
                    this.ts_min_count = model.min_count;
                    this.ts_min_date = model.min_date;
                    this.BuildChartTrxSuccess(model.categories, model.success);
                    this.isRefreshChartTrxSuccess = false;
                }
            },
            BuildChartTrxSuccess(cat, trx_success)
            {
                Highcharts.chart('chart-trx-total', {

                    credits: {
                        enabled: false
                    },
                    chart: {
                        backgroundColor: 'transparent',
                        style: {
                            fontFamily: 'Poppins Light'
                        }
                    },
                    colors: ['#f7645f'],
                    title: {
                        text:''
                    },
                    subtitle: {
                        text: null
                    },

                    yAxis: {
                        title: {
                            text: null
                        }
                    },
                   
                    xAxis: {
                        categories: cat,
                        crosshair: true
                    },
                    legend: {
                        layout: 'vertical',
                        align: 'right',
                        verticalAlign: 'middle'
                    },
                    series: [{
                        name: 'Trx Sukses',
                        data: trx_success
                    }],
                    responsive: {
                        rules: [{
                            condition: {
                                maxWidth: 500
                            },
                            chartOptions: {
                                legend: {
                                    layout: 'horizontal',
                                    align: 'center',
                                    verticalAlign: 'bottom'
                                }
                            }
                        }]
                    }
                });
            }
        }
    }
</script>

<style lang="scss" scoped>

/** banner */
.banner{
    width:100%;
    height: 132px;
    overflow:hidden;
    border-radius:5px;
    img{
        width:100%;
    }
}
.cover-info{
    float:left;
    background:#ffffff36;
    padding:15px;
    width:100%;
    height:480px;
    ul.data-list{
        li{
            position:relative;
            padding:7px 5px;
            border-bottom:none;
            .float-right{
                .loading-dots{
                    position:absolute;
                    right:5px;
                    top:35px;
                }
                h6{
                    position:relative;
                    font-weight: 200;
                    top:30px;
                }
            }
            
        }
    }
    .circle-info{
        width:80px;
        height:80px;
        border-radius:50%;
        background-color:#fff;
        text-align:center;
        border:7px solid #fff1f1;
        float:left;
        position:relative;
        overflow:hidden;
        .mdi{
            position: absolute;
            font-size: 80px;
            left: -20px;
            top: -34px;
            color: #fcffee36;
        }

        span{
            position:relative;
            top:11px;
            font-size:17px;
            color:#fff;
            margin-bottom:10px;
            font-weight: 300;
        }
        small{
            position:relative;
            top:0px;
            margin-top:20px;
        }
    }
    .circle-orange{
        background:#fe8f8b;
    }
    .circle-yellow{
        background:#fec77e;
    }

    .circle-desc{
        float:left;
        padding:30px 0px 0px 7px;
        h6{
            font-size:14px;
            font-weight: 200;
            line-height: 0px;
        }
    }

    #chart-trx-total{
        margin-top:10px;
        float:left;
        padding:0px 15px;
        width:100%;
        height:195px;
    }
}
.cover-chart{
    float:left;
    background:#ffffff36;
    padding:15px;
    width:100%;
    height:480px;

    #chart-trx{
        width:100%;
        height:400px;
    }
}

.table-dashboard{
    overflow:hidden;
    margin-bottom:10px;
    padding-bottom:10px;
    max-height:500px;
    overflow-y:scroll;
    .bg-danger-soft{
        background-color:#ff6565fa !important ;
        color:#fff;
    }
    .bg-success-soft{
        background-color:#71af77 !important;
        color:#fff;
    }
    table{
        thead{
            th{
                position: sticky; 
                top: 0;
                z-index: 2;
                background-color:#f1f1f1f2;
            }
        }
    }
}
.box-header{
    position: relative;
    float:left;
    width:100%;
    padding:28px 0px 24px 10px;
    font-family: "Poppins Light";
    background-color: #ffffff4f;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
    border-left:3px solid #ee363163;
    
    h6{
       font-family: inherit; 
       font-size:13px;
       font-weight: 200;
    }

    i.indicator{
        font-size: 23px;
        position: absolute;
        right: 10px;
        top: -20px;
        color: #f4825e;
        width:35px;
        height:35px;
        border-radius:50%;
        background-color:#ffffff96;
        text-align:center;
        box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
    }
    .spinner-border{
        position: absolute;
        border-width:1px;
        width:25px;
        height:25px;
        right: 10px;
        top: -13px;
        color: #f4825e;
        border-radius:50%;
        text-align:center;

    }

    div.bg{
        position: absolute;
        top: -33px;
        left: -13px;
        i{
            font-size: 104px;
            color: #f2683514;
        }
    }
    .up-down-text{
        position:absolute;
        top:-20px;
        font-size:12px;
        left:0px;
        color:rgb(77, 77, 77);
    }
    
    div.left, div.right{
        height:50px;
    }
    div.left{
        float:left;
        width:65%;
        #total{
            position:relative;
            top:-19px;
            div.delimiter{
                border-top:1px dashed #e9c5a1;
                width:100%;
                height:1px;
                float:left;
                margin-top:3px;
                margin-bottom:3px;
            }
            span{
                width:100%;
                float:left;
                font-size:14px;
            }
            .title{
                font-size:12px;
            }
        }
       
    }
    div.right{
        float:left;
        width:35%;
        text-align:center;
        border-left:1px dashed #e0d6c9;
        h3{
            font-size:15px;
            font-family: inherit;
            line-height:20px;
            padding-top:2px;
            color:#ee3c32;
        }
    }
}
.box-saldo{
    background-color: #ecff0017;
    div.left{
        width:60%;
    }
    div.right{
        width:40%;
        border-left:1px dashed #e7b789;
    }
   
}
.box-date{
    position:absolute;
    right:5px;
    padding:12px;
    bottom:-35px;
    font-family:'Poppins Light';
    .dt-day{
        position:relative;
        top:-25px;
        font-size:45px;
        font-weight:bold;
        color:#f54242;
    }
    .dt-month{
        position: relative;
        top: -46px;
        right: -4px;
        color: #d62005;
        font-weight: 300;
        font-size:17px;
    }
    .dt-year{
        position:absolute;
        top:18px;
        margin-left:3px;
        font-size:15px;
    }
    .dt-time{

        position: absolute;
        bottom: 31px;
        left: 18px;
        float: left;
        i{
            font-size:16px;
            color:#d6371eeb;
            
        }
        span{
            font-size: 13px;
            color: #515151;
        }
    }
}

.box-marquee{
    width:21%;
    float:left;
    position:relative;
    padding:3px;
    height: 40px;
    border-left: 3px solid #ef6523;
    background-color: #cfd6d4;
    ul{
        margin:0;
        padding:0;
        height: 30px;
        overflow:hidden;
        li{
            list-style:none;
            float:left;
            width:100%;
            padding:5px;
            color:#2e2e2e;

        }
    }
}
.marquee {
    position: relative;
    float:left;
    padding:12px 5px;
    width: 79%;
    height: 40px;
    overflow-x: hidden;
    background-color:#ffffff24;
    margin-bottom:5px;
    overflow:hidden;
    .track {
        position: absolute;
        white-space: nowrap;
        width:130vw;
        left:-30px;

        will-change: transform;
        animation: marquee 25s linear infinite;
            
        .content{
            width:70%;
            .text-analytics{
                float:left;
                width:auto;
                font-size:13px;
                text-align:right;
                border-left:1px dashed #cfc5b9;
                padding-right:15px;
                padding-left:15px;
                i.indicator{
                    position:relative;
                    font-size: 17px;
                    color: #f4825e;
                    width:23px;
                    height:23px;
                    border-radius:50%;
                    background-color:#ffffff96;
                    text-align:center;
                    float:left;
                    top:-3px;
                    margin-right:5px;
                }
                .date{
                    padding: 5px 10px;
                    font-size: 13px;
                    background-color: #ff340508;
                    border-radius: 3px;
                    color: #262626;
                }
            }
            .text-analytics:first-child{
                border:none;
            }

        }
    }
}

@keyframes marquee {
    from { transform: translateX(-60%); }
    to { transform: translateX(50%); }
}
</style>