<template>
    <div v-if="model.length > 0">
        <a class="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-bell fa-fw"></i>
            <span class="badge badge-danger badge-counter" id="notif-count">{{ model.length }}+</span>
        </a>
        <div class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="alertsDropdown">
            <h6 class="dropdown-header">! Notification</h6>
            <div id="notif-scrollbox" v-if="!isLoading">
                <div v-for="(items, idx) in model" :key="idx" :class="items.type+'-'+items.id">
                    <router-link class="dropdown-item d-flex align-items-center"  :to="{ name: GenerateLink(items.type) }">
                        <div class="mr-3">
                            <div class="icon-circle bg-warning">
                                <i class="fas fa-file-alt text-white"></i>
                            </div>
                        </div>
                        <div>
                            <div class="small text-gray-500">{{ items.title }}</div>
                            {{ items.body }}
                        </div>
                    </router-link>
                </div>
            </div>
            <a class="dropdown-item text-center small text-gray-500" href="#" v-if="model.length > 4">Scroll down to see others</a>
        </div>
    </div>
</template>
<script>
    export default 
    {
        name : 'Notification',
        data() {
            return {
                isLoading : true,
                model : []
            }
        },
        created() 
        {
            this.Notification();
            this.FcmListener();
            
        },
        methods: {

            async Notification()
            {
                this.isLoading = true;
                const query = await this.ServiceApiPost({url:'notification/data'});
                if(query)
                { 
                    this.model = query; 
                    this.isLoading = false;
                }
            },
            FcmListener()
            {
                const self = this;
                this.$messaging.onMessage(function () 
                {
                    self.Notification();
                    var sys_audio = document.getElementById('sys-audio');
                    if(sys_audio){
                        sys_audio.load();
                        sys_audio.play();
                    }
                });
            },
            GenerateLink(type){

                if(type == 'order'){
                    return 'orders-data'
                }  
            },
        },
    }
</script>

<style lang="scss" scoped>
    #notif-scrollbox{
        height:300px;
        overflow-y:scroll;
    }
</style>