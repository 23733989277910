<template>
  <button :type="type" :class="class_style +' btn-loader'">
    <i class="spinner-button" v-if="isLoading"></i> {{ title }}
  </button>
</template>

<script>
export default {
name: 'ButtonProcess',
props: ['type', 'title', 'classes', 'submit_state'],

data() {
    return {
        isLoading : false,
        class_style : 'btn btn-primary',
    }
},
watch: 
{
    submit_state: function(val) 
    { 
        this.isLoading = val;
    }
},
created()
{
    if(this.classes){
      this.class_style = this.classes;
    }
}
}
</script>

<style lang="scss" scoped>
.btn{
padding-left:30px;
padding-right:30px;
}
</style>
