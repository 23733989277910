<template>
    <main>
        <!-- download -->
        <div class="popup-modal" v-if="isEnablePopup == 'download'">
            <div class="modal-dialog modal-sm" >
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Download</h5>
                        <button class="btn btn-sm btn-close" type="button" @click="isEnablePopup = false"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <p>Silahkan pilih format file yang di bawah ini dan mohon tidak keluar dari aplikasi pada saat proses download berlangsung. </p>
                            </div>
                            <div class="col-md-12 pb-3 ">
                                <hr/>
                                <div class="download-option">
                                    <a href="javascript:;" class="csv" @click="SetDownload('csv')"><i class="mdi mdi-file-excel"></i> .CSV</a>
                                    &nbsp;&nbsp;
                                    <a href="javascript:;" class="json" @click="SetDownload('json')"><i class="mdi mdi-file-document"></i> .JSON</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- download -->

        <!-- open links -->
        <div class="popup-modal" v-if="isEnablePopup == 'links'">
            <div class="modal-dialog modal-sm" >
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Links</h5>
                        <button class="btn btn-sm btn-close" type="button" @click="isEnablePopup = false"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-8 ">
                                <p>Anda dapat megakses data produk dalam format json melalui link berikut.</p>
                                <p>Untuk mendapatkan group spesifik data anda dapat menggunakan parameter filterisasi di bawah ini. </p>
                            </div>
                            <div class="col-md-4 text-center border-left">
                                <a href="javascript:;" class="donwload-links" @click="SetDownload('links')">
                                    <i class="icon mdi mdi-json"></i> 
                                </a>
                                Json Link
                                <a href="javascript:;" class="copy" @click="CopyClipboard('Json Link', SetDownload('links',true))"><i class="mdi mdi-content-copy"></i></a>
                            </div>
                            <div class="col-md-12">
                                <hr/>
                                <label><i class="mdi mdi-information-outline text-warning"></i> Parameter Filterisasi</label>
                                <div class="scrollbox">
                                    <table class="table-popup">
                                        <thead>
                                            <tr>
                                                <th>Nama</th>
                                                <th>Deskripsi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>cat</td>
                                                <td>filter berdasarkan kategori, value "all" adalah untuk semua kategori. Jika anda menginginkan data dalam group spesifik kategori anda dapat memberikan value dari "id_kategori".</td>
                                            </tr>
                                            <tr>
                                                <td>subcat</td>
                                                <td>filter berdasarkan sub-kategori, value "all" adalah untuk semua sub-kategori. Jika anda menginginkan data dalam group spesifik sub-kategori anda dapat memberikan value dari "id_subkategori".</td>
                                            </tr>
                                            <tr>
                                                <td>provider</td>
                                                <td>filter berdasarkan provider, value "all" adalah untuk semua provider. Jika anda menginginkan data dalam group spesifik provider anda dapat memberikan value dari"kode_provider".</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- openlinks -->

        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Produk 
                    </h1>
                    <div class="page-header-subtitle text-secondary">Anda dapat melihat seluruh data produk berdasarkan group spesifik kategori, sub-kategori hingga provider. Serta anda juga dapat mengunduh data produk atau melihat produk melalui "JSON Link" hingga melakukan filterisasi data.</div>
                </div>
            </div>
        </header>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mb-4">
                    <div class="card-header p-0">
                        <div class="row table-filter">
                            <div class="col-md-3 col">
                                <div class="form-group">
                                    <SelectProductCategory :type="['digital', 'tagihan']" v-on:ChooseData="FilterCategory"/>
                                </div>
                            </div>
                            <div class="col-md-3 col">
                                <div class="form-group">
                                    <SelectProductSubCategory v-on:ChooseData="FilterSubCategory" :id_category="meta_filter.id_category" v-if="meta_filter.id_category !== 'all'"/>
                                    <div v-else>
                                        <label>Sub-Kategori Produk </label>
                                        <select  class="form-control" disabled>
                                            <option value="all">--  --</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col">
                                <div class="form-btn">
                                    <ButtonProcess :classes="'btn btn-full btn-sm btn-warning'"  :type="'Submit'" :title="'Filter'" @click="DataQuery();"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                       
                        <!-- end component filter table -->
                        <div class="table-info table-master">
                            <div class="float-left">
                                <ul>
                                    <li class="border-right pr-2">
                                        <a href="javascript:;" class="download" @click="isEnablePopup = 'download'"> Download </a>
                                    </li>
                                    <li class="pl-2">
                                        <a href="javascript:;" class="links" @click="isEnablePopup = 'links'"> Open Link </a>
                                    </li>
                                    <li v-if="isDownloadProcess">
                                        <span>Prosess Download ...</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="float-right">
                                <ul>
                                    <li class="">
                                        <i class="mdi mdi-refresh" v-if="! $store.state.loading.status" @click="DataQuery();"></i>
                                        <div class="spinner-border  spinner-border-sm" role="status" v-else></div>
                                    </li>
                                </ul>
                            </div>
                            <div class="group-search">
                                <div class="black-layer" v-if="mixsearch"></div>
                                <div id="box" v-if="mixsearch">
                                    <span class="close" @click="mixsearch = false"><i class="mdi mdi-close"></i></span>
                                    <div class="form-group mt-3">
                                        <label>Pilih Target Pencarian</label>
                                        <select class="form-control" v-model="mixmeta_data.skey">
                                            <option value="product.product_code">Kode Produk</option>
                                            <option value="product.name">Nama Produk</option>
                                        </select>
                                    </div>
                                    <div class="form-group mt-2">
                                        <label>Masukan Keyword</label>
                                        <input type="text" class="form-control" v-model="mixmeta_data.sval"/>
                                        <a href="javascript:;" @click="ServiceRefreshData()" class="clear" v-if="mixmeta_data.sval !== ''"><i class="mdi mdi-delete-circle"></i></a>
                                    </div>
                                    <div class="form-group">
                                        <hr/>
                                        <ButtonProcess :classes="'btn btn-full btn-sm btn-warning'" :submit_state="isSubmitProcess" :type="'Submit'" :title="'Cari Data'" @click="ServiceSearchData()"/>
                                    </div>
                                </div>
                                <a href="javascript:;"  class="search" @click="mixsearch = true"><i class="mdi mdi-magnify"></i></a>
                            </div>
                        </div>
                       
                        <SystemConnection :connection="service_connection"  v-if="isFirstLoad" />
                        <div class="s-table table-cover" v-else>
                            <table :class="isRefreshTable ? 'table-load' : '' ">
                                <thead>
                                    <tr>
                                        <th data-filter="category_name" colspan="2">Produk Kategori</th>
                                        <th data-filter="subcategory_name" colspan="2">Sub-Kategori</th>
                                        <th data-filter="product_code">Kode Produk</th>
                                        <th data-filter="product.name">Produk</th>
                                        <th data-filter="price">Harga ( IDR )</th>
                                    </tr>
                                </thead>
                                <tbody  v-if="mixmodel.total > 0">
                                    <tr v-for="(items, idx) in mixmodel.data" :key="idx">
                                        <td width="70" >
                                            <ImageSmCircle :photo="$store.state.api_resource + items.photo_category" v-if="items.photo_category !== null"/>
                                            <span class="text-secondary" v-else>-- --</span>
                                        </td>
                                        <td width="150">{{ items.category_name }}</td>
                                        <td width="70" >
                                            <ImageSmCircle :photo="$store.state.api_resource + items.photo_subcategory" v-if="items.photo_subcategory !== null"/>
                                            <span class="text-secondary" v-else>-- --</span>
                                        </td>
                                        <td width="150">{{ items.subcategory_name }}</td>
                                        <td width="150">{{ items.product_code }}</td>
                                        <td >{{ items.name }}</td>
                                        <td width="130">
                                            Rp {{ $filters.formatNumber(items.price) }} 
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <v-pagination
                            v-if="mixmodel.total > 0"
                            v-model="page"
                            :class="'pagination'"
                            :pages="parseInt(mixmodel.last_page)"
                            :range-size="1"
                            active-color="#ffffff"
                            @update:modelValue="DataQuery"
                        />
                        <div class="paginate-total">Total Records ( {{ $filters.formatNumber(mixmodel.total) }} )</div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>

    export default {
        data() {
            return {
                isFirstLoad : true,
                isRefreshTable : false,
                isDownloadProcess : false,

                isEnablePopup  : false,
                download_format : 'csv',
                freseller : localStorage.getItem('user-code'),
                meta_filter :{ id_category : 'all', id_subcategory : 'all' }
            }
        },
        created()
        {
            this.mixmeta_data.okey = 'category_name';
            this.mixmeta_data.oval = 'desc';
            this.ServiceInitFunction('DataQuery', 's-table');
        },
        methods:
        {
            closePopup(refresh_query)
            {
                this.isEnablePopup = false;
                if(refresh_query)
                {
                    this.DataQuery();
                }
            },
            async CopyClipboard(title, string)
            {    
                await navigator.clipboard.writeText(string);
                alert(title + ' telah di copy');
            },
            FilterCategory(data)
            {
                this.meta_filter.id_category = 'all';
                this.meta_filter.id_subcategory = 'all';

                setTimeout(() => {
                    if( data.id ){
                        this.meta_filter.id_category = data.id;
                    }
                }, 200);
            },
            FilterSubCategory(data)
            {
                this.meta_filter.id_subcategory = 'all';
                if( data.id ){
                    this.meta_filter.id_subcategory = data.id;
                }
            },
            async DataQuery(pageNum)
            {
                this.isRefreshTable = true;
                this.page = pageNum == undefined ? 1 : pageNum;
                let res = await this.ServiceApiDataPaging({
                    url:'h2h/product-digital', 
                    identified_user : this.freseller,
                    offset : this.page,
                    search: { key : this.mixmeta_data.skey, value : this.mixmeta_data.sval },
                    order: { key : this.mixmeta_data.okey, value : this.mixmeta_data.oval },
                    filter : [
                        {key : 'product_category.product_type', val : ['digital', 'tagihan']},
                        {key : 'product_category.id', val: this.meta_filter.id_category },
                        {key : 'product_subcategory.id', val: this.meta_filter.id_subcategory }
                    ]
                });
                if(res){ 
                    this.mixmodel = res.query; 
                    this.isFirstLoad = false;
                    this.isRefreshTable = false;
                }
            }, 
            SetDownload(format, ret = null)
            {
                this.download_format = format;
                if(format == 'links')
                {
                    // client crypt id
                    let ccid = localStorage.getItem('user-crypt');
                    let links = process.env.VUE_APP_API_URL + 'product/links?cat=all&subcat=all&provider=all&ccid='+ccid;
                    if(ret !== null){
                        return links;
                    }else{
                        window.open(links, '_blank');
                    }

                }else{
                    this.Download();
                }
            },
            async Download()
            {
                this.isEnablePopup = false;
                this.isDownloadProcess = true;

                const response = await this.ServiceApiPost({url:'h2h/production/product-download', data : {
                    format : this.download_format,
                    code_otmx_reseller : this.freseller,
                    filter : [
                        {key : 'product_category.id', val: this.meta_filter.id_category },
                        {key : 'product_subcategory.id', val: this.meta_filter.id_subcategory }
                    ]
                }});
                if(response)
                { 
                    var url = '';
                    const filename = this.download_format == 'csv' ? 'product.csv' : 'product.json';
                    if(this.download_format == 'csv')
                    {
                        url = window.URL.createObjectURL(new Blob([response]));
                    }else{
                        url = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(response));
                    }

                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    this.isDownloadProcess = false;
                }
            }, 
        }
    }
</script>


<style lang="scss" scoped>
.copy{
    font-size:16px;
    color:#ec432e;
    margin-left:5px;
}
.copy:hover{
    color:#f06d21;
}
.download-option{
    width:100%;
    float:left;
    text-align:center;
    margin-top:10px;
    a{
        padding:5px 10px 5px 10px;
        border-radius:5px;
        text-align:center;
    }
    a.csv{
        background-color: #f8f8f8;
        color: #00771c;
        border: 1px solid #1bdd1b;
    }
    a.json{
        background-color: #f8f8f8;
        color: #ff5730;
        border: 1px solid #ff5730;
    }
   
}

.links{
    padding: 4px 12px;
    background-color: #fff;
    border-radius: 25px;
    color: #da3d04;
    position: relative;
    top: -5px;
}
.donwload-links{
    background-color: #e7e7e7d4;
    color: #e9683c;
    width: 85px;
    height: 85px;
    padding: 0px;
    border-radius: 5px;
    display: block;
    text-align: center;
    position: relative;
    left: 0;
    right: 0;
    margin: 0 auto;
    margin-top:20px;
    margin-bottom:5px;

    &:hover{
        background-color:#e9683c;
        color:#fff;
    }
    i{
        font-size:60px;
    }
}
.scrollbox{max-height:350px;}
.table-popup{
    thead{
        tr{
            th{
                background-color:#ff500212 !important;
                padding:10px;
            }
        }
    }
    tbody{
        tr{
            td{
                padding:10px;
                border-bottom:1px solid rgb(237, 237, 237) !important;
            }
        }
    }
}

.border-right{border-right:1px dashed #ebeaea !important;}
</style>
