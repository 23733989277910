export default {
   
    methods: {
        
        async ServiceAuthenticateLogin(payload)
        { 
            this.$store.state.loading.status = true;
            return await this.$axios.post( 'h2h/auth/login', {
                handphone : payload.handphone,
                id_reseller  : payload.id_reseller,
                pincode  : payload.pincode,
                prefix  : payload.prefix,
                auth_type : 'h2h'
            })
            .then((result) => 
            {
                let response = result.data;
                if(response.result){

                    localStorage.setItem('user-id-privileges', response.id_privileges);
                    localStorage.setItem('user-privileges', response.id_privileges == 1 ? 'all' : response.id_privileges);

                    localStorage.setItem('is_enable_env', response.is_enable_env);
                    localStorage.setItem('user-id', response.userid);
                    localStorage.setItem('user-code', payload.id_reseller);
                    localStorage.setItem('user-crypt', response.crypt);
                    localStorage.setItem('user-avatar', response.avatar);
                    localStorage.setItem('jwt-token', response.jwt_token );

                    if( localStorage.getItem('user-id-privileges') && localStorage.getItem('user-code') ){
                        return response;
                    }
                }

                return response;
                
            }).catch( (error) =>  
            {
                let res = JSON.parse(error.request.responseText);
                return res;
            });
        },

        async ServiceAuthenticateLogout()
        { 
            localStorage.clear();
            window.location.href = '/'; 
        }
    }
}