<template>
    <div v-if="$store.state.app_msg.status !== false">
        <div id="black-screen"></div>
        <div  class="alert alert-with-icon animated fadeInDown col-md-4" >
            <div id="group-icon">
                <i class="mdi mdi-information-outline" v-if="$store.state.app_msg.type == 'alert'"></i>
                <i class="mdi mdi-delete-circle"  v-if="$store.state.app_msg.type == 'trash'"></i>
                <i class="mdi mdi-check-circle-outline"  v-if="$store.state.app_msg.type == 'default'"></i>
            </div>
            <div id="msg">
                <h6>{{ $store.state.app_msg.title }}</h6 >
                <p class="text-msg">{{ $store.state.app_msg.text }}</p>
            </div>
        </div>
    </div>
</template>


<style lang="scss" scoped>
#black-screen{
    position: fixed; 
    width:100%;
    height:100%;
    z-index: 9999999; 
    top: 0px; 
    left: 0px;
    right: 0px;
    padding:10px;
    background-color:#000;
    opacity:.5;
}
.alert{
    margin: 0 auto; 
    position: fixed; 
    z-index: 999999999; 
    top: 80px; 
    left: 0px;
    padding:0;
    right: 0px;
    width:100%;
    color:#fff;
    border-radius:5px !important;
    overflow:hidden;


    #group-icon, #msg{
        float:left;
        padding:10px;
        height:150px;
    }
    #group-icon{
        background-color:#232c3a;
        text-align:center;
        width:25%;
        background: linear-gradient(110.1deg, rgb(241, 115, 30) 18.9%, rgb(231, 29, 54) 90.7%);
        i.mdi{
            font-size:70px;
            position: relative;
            left:0;
            right:0;
            top:20%;
            margin:0 auto;
            color:#fff;
        }
    }
    #msg{
        width:75%;
        padding:20px;
        background:#fff9f9f2;
        color:#000;
        h6{
            font-weight: 500;
            font-size:17px;
            padding-bottom:10px;
            border-bottom:1px solid #ff5550;
            margin-bottom:15px;
        }
        p{
            font-weight: 300;
            font-size:14px;
        }
    }
}

</style>