<template>
    <div >
        <label>Sub-Kategori Produk <small class="text-danger" v-if="mode == 'form'">*</small></label>
        <select  class="form-control" v-model="selected"  @change="ChooseData()" >
            <option value="all" v-if="mode == 'form'">--  --</option>
            <option value="all" v-else>-- Semua Sub Kategori --</option>
            <option v-for="(items, idx) in model" :key="idx" :value="items.id"> {{ items.name }}</option>
        </select>
    </div>
</template>
<script>
export default {
    name: 'SelectProductSubCategory',
    props : ['mode', 'id_category'],
    
    data() {
        return {
            selected : 'all',
            model : []
        }
    },
    created()
    {
        this.DataQuery();
    },
    methods:
    {

        async DataQuery()
        {
            this.selected = 'all';
            const res = await this.ServiceApiPost({
                url:'product_subcategory/data/all',
                data : { 
                    filter : [
                        {key : 'product_category.id', val:this.id_category}
                    ]
                }
            });
            if(res && res.result){ 
                this.model = res.query; 
            }
        }, 
        ChooseData()
        {
            if(this.selected !== 'all'){

                let data_model = {};
                for(let key in this.model)
                {
                    if( this.model[key].id == this.selected ){
                        data_model = this.model[key];
                    }
                }    
                this.$emit('ChooseData', data_model);
            }else{
                this.$emit('ChooseData', 'all');
            }
        }
    }
}
</script>