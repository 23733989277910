<template>
    <main>
        <DetailLog :data_model="model_detail" v-on:closePopup="closeDetail"  v-if="isEnablePopup"/>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        API Request & Response 
                    </h1>
                    <div class="page-header-subtitle text-secondary">Log request dan response api.</div>
                </div>
            </div>
        </header>
        <hr/>
        <div class="row">
            <div class="col-xl-12">
                <!-- end component filter table -->
                <div class="card mb-4">
                    <div class="card-header  p-0 pr-2">
                        <div class="row table-filter">
                            
                            <div class="col-md-4 col">
                                <div class="form-group">
                                    <label>Periode Transaksi</label>
                                    <select class="form-control" v-model="fdate" @change="FilterFDate()">
                                        <option value="0">Pilih Manual</option>
                                        <option value="1">Hari Sekarang</option>
                                        <option value="7">7 Hari Terakhir</option>
                                        <option value="30">30 Hari Terakhir</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3 col">
                                <div class="form-group">
                                    <label>Tanggal Mulai</label>
                                    <input type="date" class="form-control"  v-model="startdate"   id="startDate" :disabled="fdate !== '0' ? true  : false"/>
                                </div>
                            </div>
                            <div class="col-md-3 col">
                                <div class="form-group">
                                    <label>Tanggal Akhir</label>
                                    <input type="date" class="form-control"  v-model="enddate" id="endDate" :disabled="fdate !== '0' ? true  : false"/>
                                </div>
                            </div>
                            <div class="col-md-2 col">
                                <div class="form-btn">
                                    <ButtonProcess :classes="'btn btn-full btn-sm btn-warning'"  :type="'Submit'" :title="'Filter'" @click="DataQuery(); QuerySummaryTrx();"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="table-info  table-master">
                            <div class="float-left">
                                <ul>
                                    <li>
                                        <a href="javascript:;" class="download" @click="Download()"> Download </a>
                                    </li>
                                    <li v-if="isDownloadProcess">
                                        <span>Prosess Download ...</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="float-right">
                                <ul>
                                    <li class="">
                                        <i class="mdi mdi-refresh" v-if="! $store.state.loading.status" @click="DataQuery();"></i>
                                        <div class="spinner-border  spinner-border-sm" role="status" v-else></div>
                                    </li>
                                </ul>
                            </div>
                            <div class="group-search">
                                <div class="black-layer" v-if="mixsearch"></div>
                                <div id="box" v-if="mixsearch">
                                    <span class="close" @click="mixsearch = false"><i class="mdi mdi-close"></i></span>
                                    <div class="form-group mt-3">
                                        <label>Pilih Target Pencarian</label>
                                        <select class="form-control" v-model="mixmeta_data.skey">
                                            <option value="status_code">Status Code</option>
                                            <option value="ip_address">IP Address</option>
                                        </select>
                                    </div>
                                    <div class="form-group mt-2">
                                        <label>Masukan Keyword</label>
                                        <input type="text" class="form-control" v-model="mixmeta_data.sval"/>
                                        <a href="javascript:;" @click="ServiceRefreshData()" class="clear" v-if="mixmeta_data.sval !== ''"><i class="mdi mdi-delete-circle"></i></a>
                                    </div>
                                    <div class="form-group">
                                        <hr/>
                                        <ButtonProcess :classes="'btn btn-full btn-sm btn-warning'" :submit_state="isSubmitProcess" :type="'Submit'" :title="'Cari Data'" @click="ServiceSearchData()"/>
                                    </div>
                                </div>
                                <a href="javascript:;"  class="search" @click="mixsearch = true"><i class="mdi mdi-magnify"></i></a>

                            </div>
                        </div>
                        <SystemConnection :connection="service_connection"  v-if="isFirstLoad" />
                        <div class="s-table table-cover" v-else>
                            <table :class="isRefreshTable ? 'table-load' : '' ">
                                <thead>
                                    <tr>
                                        <th data-filter = 'created_at'>Tanggal</th>
                                        <th data-filter="ip_address">IP Address</th>
                                        <th data-filter = 'method'>Method</th>
                                        <th data-filter = 'request_url'>Request URL</th>
                                        <th data-filter = 'type'>Server Task</th>
                                        <th data-filter = 'status_code'>HTTP Code</th>
                                        <th class="no-filter">Action</th>
                                    </tr>
                                </thead>
                                <tbody v-if="mixmodel.total > 0">
                                    <tr v-for="(items, idx) in mixmodel.data" :key="idx">
                                        <td width="120">{{ $filters.formatDate(items.created_at, 'DD/MM/YYYY') }}<br/><small class="text-secondary">{{ $filters.formatDate(items.created_at, 'LT') }}</small></td>
                                        <td width="120">{{ items.ip_address !== null ? items.ip_address : 'Private Hidden' }}</td>
                                        <td><span :class="items.method == 'POST' ? 'bg-success-soft status-method' : 'bg-danger-soft status-method'">{{ items.method }}</span></td>
                                        <td>{{ items.request_url }}</td>
                                        <td width="140"> 
                                            <span><i :class="items.type == 'in' ? 'mdi mdi-server-security  text-success' : 'mdi mdi-server-network text-warning'"></i> {{ items.type == 'in' ? 'Server Receive' : 'Server Send' }}</span>
                                        </td>
                                        <td width="100">{{ items.status_code }}</td>
                                        <td width="100" class="text-center">
                                            <a href="javascript:;" class="btn btn-sm btn-orange btn-with-icon" @click="DataDetail(idx)"> <i class="mdi mdi-gesture-tap"></i> Detail</a> 
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="7">
                                            Data kosong / tidak ditemukan.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <v-pagination
                            v-if="mixmodel.total > 0"
                            v-model="page"
                            :pages="parseInt(mixmodel.last_page)"
                            :range-size="1"
                            active-color="#ffffff"
                            @update:modelValue="DataQuery"
                        />
                        <div class="paginate-total">Total Records ( {{ $filters.formatNumber(mixmodel.total) }} )</div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>


<script>
    import $ from 'jquery';
    import DetailLog from './DetailLog';
    import moment from "moment";

    export default {
        components: {
            DetailLog
        },
        data() {
            return {
                isDownloadProcess : false,
                isFirstLoad : true,
                isLoadSummaryTrx : false,
                isEnableFilter : false,
                isEnablePopup : false,
                model_detail : {},
                env : localStorage.getItem('is_enable_env'),

                fdate : 7,
                startdate : new Date( Date.now() + -6*24*3600*1000 ).toISOString().slice(0,10),
                enddate : new Date().toISOString().slice(0,10),
            }
        },
        created()
        {
            this.mixmeta_data.okey = 'created_at';
            this.mixmeta_data.oval = 'desc';
            this.ServiceInitFunction('DataQuery', 's-table');

            setTimeout(() => {
                this.InitialMaxInputDate();
            }, 200);
        },
        methods:
        {
            InitialMaxInputDate()
            {
                var today = new Date();
                var dd = today.getDate();
                var mm = today.getMonth()+1; 
                var yyyy = today.getFullYear();
                if(dd<10){
                    dd='0'+dd
                } 
                if(mm<10){
                    mm='0'+mm
                } 

                today = yyyy+'-'+mm+'-'+dd;
                $('#startDate, #endDate').prop('max', function(){
                    return today;
                });
            },
            FilterFDate()
            {
                switch(this.fdate)
                {
                    case '1': 
                        this.startdate = new Date( Date.now()).toISOString().slice(0,10);
                        this.enddate = new Date(Date.now()).toISOString().slice(0,10);
                    break;
                    case '7': 
                        this.startdate = new Date( Date.now() + -6*24*3600*1000 ).toISOString().slice(0,10);
                        this.enddate = new Date(Date.now()).toISOString().slice(0,10);
                    break;
                    case '30': 
                        this.startdate = new Date( Date.now() + -29*24*3600*1000 ).toISOString().slice(0,10);
                        this.enddate = new Date(Date.now()).toISOString().slice(0,10);
                    break;
                    default:
                    break;
                }

                if( this.fdate !== '0' )
                {
                    document.getElementById('startDate').value = this.startdate;
                    document.getElementById('endDate').value = this.enddate;
                }
            },
            
            closeDetail()
            {
                this.isEnablePopup = false;
            },
            
            DataDetail(idx)
            {
                this.model_detail = this.mixmodel.data[idx];
                this.isEnablePopup = true;
            },
            async DataQuery(pageNum)
            {
                this.isRefreshTable = true;
                this.isEnableFilter = false;
                this.page = pageNum == undefined ? 1 : pageNum;

                var query_startdate = this.startdate;
                var query_enddate   = this.enddate;
                if( document.getElementById('startDate') && document.getElementById('endDate') )
                {
                    var sdate = document.getElementById('startDate').value;
                    var edate = document.getElementById('endDate').value;
                    query_startdate = moment(sdate).format("DD-MM-YYYY");
                    query_enddate   = moment(edate).format("DD-MM-YYYY");
                }

               

                let res = await this.ServiceApiDataPaging({
                    url:'h2h/'+this.env+'/log', 
                    offset : this.page,
                    search: { key : this.mixmeta_data.skey, value : this.mixmeta_data.sval },
                    order: { key : this.mixmeta_data.okey, value : this.mixmeta_data.oval },
                    filter : [
                        {key : 'code_otmx_reseller', val : localStorage.getItem('user-code')},
                        {key : 'start_date', val : query_startdate},
                        {key : 'end_date', val : query_enddate},
                    ],
                });
                if(res)
                { 
                    this.mixmodel = res.query; 
                    this.isFirstLoad = false;
                    this.isRefreshTable = false;
                }
            },
            async Download()
            {
                this.isDownloadProcess = true;
                const response = await this.ServiceApiPost({url:'h2h/'+this.env+'/log-download', data : {
                    code_otmx_reseller : localStorage.getItem('user-code'),
                    format : 'csv',
                }});
                if(response)
                { 
                    const filename = 'log-request.csv';
                    const url = window.URL.createObjectURL(new Blob([response]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    this.isDownloadProcess = false;
                }
            }, 
           
        }
    }
</script>


<style lang="scss" scoped>
.bg-system-type .mdi{
   font-size:16px;
}
.status-method{
    padding: 3px;
    border-radius: 2px;
    width:50px;
    display:block;
    text-align:center;
}
.period{
    width: auto;
    float: left;

    .icon{
        padding: 5px 7px;
        background-color:#ec422ed4;
        color:#fff;
    }
    .date{
        position:relative;
        left:-1px;
        padding: 5px 10px;
    }
   
}
.data-list{
    width:50%;
    li{
        border-bottom: 1px solid #d3d3d3;
    }
}
.box-summary-circle{
    background-color:rgba(240, 240, 240, 0.538);
    float:left;
    width:100%;
    margin-bottom:15px;
    border-radius:5px;
    border: 1px solid #ff9d955e;
    overflow:hidden;
    border-radius:40px;
    color:#2d2d2d;
    ul{
        padding:0;
        margin:0;
        li:first-child{
            position:relative;
            width:75%;
            padding:10px;
            text-align:left;
            padding-left:60px;
            small{
                position:relative;
                top:-3px;
                font-size:13px;
            }
            .bg-icon{
                font-size: 30px;
                position: absolute;
                left: 20px;
                top: 7px;
            }
            .bg-icon-warning{color:#e99e6a}
            .bg-icon-danger{color:#eb3c2fad}
            .bg-icon-primary{color:#1d8da491}
         
        }
        li{
            padding:10px;
            list-style: none;
            float:left;
            height:70px;
            width:25%;
            text-align:center;
            background-color:#ffbd8f17;
        }
        li.number{
            background-color:#ed522900;
            text-align:center;
            span, i{
                display:block;
                position:relative;
                top:0px;
                font-size:16px;
                color:#e72e00;
                padding-top:5px;
            }
            small{
                position:relative;
                top:-8px;
            }
            .loading-dots {
                position: relative;
                float:none !important;
                top:0px;
            }

        }
     
    }
}
</style>