<template>
    <div class="circle-images">
        <img :src="photo" v-if="photo !== null">
        <div class="img" v-else><i class="mdi mdi-image-filter"></i></div> 
    </div>
</template>

<script>
export default {
  name: 'ImageMdCircle',
  props: ['photo'],
}
</script>

<style lang="scss" scoped>
.circle-images{
    width:100px;
    height:100px;
    border-radius:120px;
    -webkit-border-radius:120px;
    -moz-border-radius:120px;
    margin:0 auto;
    overflow:hidden;
    background: #e8e8e8;
    text-align:center;
    border:1px solid #dddddd;
    
    img{
        width:auto;
        height:100%;
    }
}
</style>