<template>
    <div class="popup-modal">
        <div class="modal-dialog modal-lg" >
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Detail Request & Response</h5>
                    <small></small>
                    <button class="btn btn-sm btn-close" type="button" @click="ClosePopup()"></button>
                </div>
                <div class="modal-body ">
                    <div class="row"  v-if="data_model.type == 'in'">
                        <div class="col-md-6">
                            <div class="labeling pb-2"><span class=""><i class="mdi mdi-information-outline text-danger"></i>  Headers</span></div>
                            <ul class="data-list">
                                <li><span class="text-secondary">Host</span><br/>  <div class="content" > {{ model_header.host[0] }}</div></li>
                                <li><span class="text-secondary">Accept, Accept Encoding</span><br/>  <div class="content" > {{ model_header['accept'][0] }}, [ {{ model_header['accept-encoding'][0] }} ]</div></li>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <div class="labeling pb-2">&nbsp;</div>
                            <ul class="data-list">
                                <li><span class="text-secondary">IP-Address</span><br/>  <div class="content" > {{ data_model.ip_address }}</div></li>
                                <li><span class="text-secondary">User-Agent</span><br/>  <div class="content" > {{ model_header['user-agent'][0] }}</div></li>
                            </ul>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-md-12">
                            <div class="labeling">
                                <span class="title-response"><i class="mdi mdi-information-outline text-danger"></i> Request & Response</span>
                            </div>
                            <div class="method-url">
                                <span>{{ data_model.method }}  </span>
                                <span>{{ data_model.request_url }}</span>
                            </div>
                        </div>
                        <div class="col-md-12 mt-2">
                            <div class="labeling bg-header">
                                <span :class="isEnableTab == 'body' ? 'tab tab-active' : 'tab'" @click="isEnableTab = 'body'" v-if="isEnableBody">Body</span>
                                <span :class="isEnableTab == 'response' ? 'tab tab-active' : 'tab'" @click="isEnableTab = 'response'">Response</span>

                                <a href="javascript:;" class="copy" title="Copy" @click="CopyClipboard('Server Response telah di copy', JsonStringy(data_model.response))"  v-if="isEnableTab == 'response'"><i class="mdi mdi-content-copy"></i></a>
                                <a href="javascript:;" class="copy" title="Copy" @click="CopyClipboard('Request Body telah di copy', JsonStringy(data_model.request_body))"  v-if="isEnableTab == 'body'"><i class="mdi mdi-content-copy"></i></a>
                                <div class="header-info">
                                    <span class="text-secondary">HTTP Status Code : </span>
                                    {{ data_model.status_code }}
                                </div>
                                <div class="header-info">
                                    <i class="mdi mdi-clock-fast"></i>
                                    <span class="text-secondary">Request Time : </span>
                                    <span class="text-success">{{ $filters.formatDate(data_model.created_at, 'DD/MM/YYYY HH:mm:ss') }}</span>
                                </div>
                            </div>
                            <pre class="response" v-if="isEnableTab == 'response'">{{ JsonStringy(data_model.response) }}</pre>
                            <pre class="response" v-if="isEnableTab == 'body'">{{ JsonStringy(data_model.request_body) }}</pre>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default 
    {   
        props :['data_model'],
        data() {
            return {
                isFirstLoad : true,
                isEnableTab : 'response',
                isEnableBody : false,
                model_header: {
                    authorization : ''
                }
            };
        },
        created(){

            if(this.data_model)
            {
                if(this.data_model.type == 'in')
                {
                    this.model_header = JSON.parse(this.data_model.request_header);
                }
                let response_body = this.JsonStringy(this.data_model.request_body);
                if( response_body !== ''  ){
                    this.isEnableBody = true;
                }
            }
        },
        methods: 
        {
            ClosePopup()
            {
                this.$emit('closePopup');
            },
            JsonStringy(data)
            {
                if(data.length > 2)
                    return JSON.stringify(JSON.parse(data), null, 4);
                else 
                    return '';
            },
            async CopyClipboard(title, string)
            {    
                if(string !== ''){
                    await navigator.clipboard.writeText(string);
                    alert(title);
                }else{
                    alert('Data kosong, Copy data tidak dapat dilakukan!');
                }
            },
        }
    }
</script>
<style lang="scss" scoped>

 .bg-header{
    background-color:#f2f2f2ad;
 }
.labeling{
    margin-top:5px;
    padding:0px 10px 15px 0px;
    float:left;
    width:100%;
    .tab{
        position: relative;
        padding: 10px 10px;
        font-size: 12px;
        cursor: pointer;
        margin-right: 1px;
        background-color: #efefef;
        top: 10px;
    }
    span{font-size:13px;}
    .tab-active{
        color:#ff3300;
    }
    .copy{
        float: right;
        font-size: 16px;
        color: #ec432e;
        margin-left: 5px;
        position: relative;
        top: 8px;
    }
    .copy:hover{
        color:#f06d21;
    }
    .header-info{
        float:right;
        position:relative;
        top:10px;
        margin-right:10px;
        padding-right:10px;
        border-right:1px dashed #d6d6d6;
        small{
            color:inherit;
        }
        i{
            font-size:17px;
            position:absolute;
            left:-25px;
            top:-1.5px;
            color:rgb(82, 82, 82);
        }
    }
}

hr{
    margin:5px;
}

.response{
    
    padding-top:5px;
    float:left;
    width:100%;
    height:35vh;
    overflow:scroll;
    background-color:#191919;
    color:#2ed400ec;
    padding:10px;
    font-size:13px;
    margin-top:1px;
}

.method-url{
    float:left;
    width:100%;
    padding:7px 0px;
    background-color:#fff;
    border:1px solid #efefef;
    margin-top:5px;

    border-radius:20px;
    overflow:hidden;
    span{
        padding:5px;
        font-size:13px;
        color:#000;
    }
    span:nth-child(1){
        background-color: #ea4d26;
        color:#fff ;
        padding: 7px 18px;
        margin-right:10px;
    }
}
</style>