<template>
    <div class="meter" v-if="$store.state.loading.status">
        <span v-bind:style="{ 'width': $store.state.loading.progress + '%' }"><span class="progress"></span></span>
    </div>
</template>

<script>
export default {
  name: 'LoaderBar',
}
</script>
<style scoped lang="scss">
.meter{
    
    height: 5px;
    position: fixed;
    z-index: 999999;
    left:0;
    top:0;
    background: #c4c4c4; 
    overflow: hidden;
    width:100%;
}

.meter span {
    display: block;
    height: 100%;
}

.progress {
    background-color: #f34c0d;
    animation: progressBar 3s ease-in-out;
    animation-fill-mode:both; 
}

@keyframes progressBar {
  0% { width: 0; }
  100% { width: 100%; }
}
</style>