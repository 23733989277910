import moment from "moment";

export default 
{
    capitalize(value) {
        if (!value) return '';
    
        return value.toUpperCase();

    },
    ucwords(value) {
        if (!value) return '';
    
        return value.toLowerCase().replace(/\b[a-z]/g, function(letra) {
            return letra.toUpperCase();
        });
    },
    formatPhone(value){
        return value.replace(/(\d{3})(\d{4})(\d{4})/, '$1 $2 $3');
    },
    firstChar(value){
        if (!value) return '';
    
        value = value.toString()
        return value.charAt(0).toUpperCase();
    },
    formatDate(value, format){
        if (!value) return '';
        return moment(value).format(format);
    },
    formatNumber(value)
    {
        if (!value) return 0;
        let val = (value / 1).toFixed(0).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    kFormatter(number) {
        if (!number) return 0;

        var SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];
        // what tier? (determines SI symbol)
        var tier = Math.log10(Math.abs(number)) / 3 | 0;

        // if zero, we don't need a suffix
        if(tier == 0) return number;

        // get suffix and determine scale
        var suffix = SI_SYMBOL[tier];
        var scale = Math.pow(10, tier * 3);

        // scale the number
        var scaled = number / scale;

        // format number and add suffix
        return scaled.toFixed(1) + suffix;

    },
    striphtml(value){
        var div = document.createElement("div");
        div.innerHTML = value;
        var text = div.textContent || div.innerText || "";
        return text;
    },
    jsonDecode(value)
    {
        if (!value) return '';
        return JSON.parse(value);
    },
    limit(value, char_length, suffix){
        if (!value) return '';
        if( value.length > char_length ){
            return value.substring(0, char_length) + suffix;
        }else{
            return value;
        }
    },
    GetImageThumnail(value){
        if (!value) return '';

        var dotIndex = value.lastIndexOf('.');
        var ext     = value.substring(dotIndex);
        var data = value.split(ext); 
        return data[0]+'_thumb'+ext;
    },

    convertStringMonth(value)
    {
        if (!value) return '--';
        let cl = '';
        switch (value) {
        
            case 1:
                cl = 'Jan';
            break;
            case 2:
                cl = 'Feb';
            break;
            case 3:
                cl = 'Mar';
            break;
            case 4:
                cl = 'Apr';
            break;
            case 5:
                cl = 'May';
            break;
            case 6:
                cl = 'Jun';
            break;
            case 7:
                cl = 'Jul';
            break;
            case 8:
                cl = 'Aug';
            break;
            case 9:
                cl = 'Sep';
            break;
            case 10:
                cl = 'Oct';
            break;
            case 11:
                cl = 'Nov';
            break;
            case 12:
                cl = 'Dec';
            break;
        }
        return  cl;
    },


    /**  Transaction */
    GenerateTrxClassStatus(value)
    {
        if (!value) return '';

        let class_style = '';
        switch(value){
            case 'Sukses':
                class_style = 'status status-success';
            break;
            case 'Dalam Proses':
                class_style = 'status status-process mdi mdi-spin';
            break;
            case 'Gagal':
                class_style = 'status status-danger';
            break;
            default:
                class_style = 'status status-undefined';
            break;
        }
        
        return class_style;
    }
   
};

