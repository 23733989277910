import axios from 'axios';


/*  
axios config dynamic Base URL 
env local, development, staging and production
*/
const host = location.hostname;
if(host == 'client.dev.dflash.co.id'){
    axios.defaults.baseURL = process.env.VUE_APP_API_URL_DEV;  
}else if(host == 'client-staging.dflash.co.id'){
    axios.defaults.baseURL = process.env.VUE_APP_API_URL_STAGING;   
}else if(host == 'client.dflash.co.id'){
    axios.defaults.baseURL = process.env.VUE_APP_API_URL_PROD;   
}else{
    axios.defaults.baseURL = process.env.VUE_APP_API_URL_STAGING;   
}



// axios manage request
axios.defaults.timeout = process.env.VUE_CONFIG_REQUEST_TIMEOUT;
axios.interceptors.request.use(
    
    (config) => 
    {
        if( config.url !== 'auth/login' )
        {
            config.headers['Accept'] = 'application/json'; 
            config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('jwt-token'); 
        }
        return config;
    }, 
    (error) => {
        return Promise.reject(error);
    },
); 


// axios manage response
axios.interceptors.response.use(response => {
    if( response.data.status == 'token_expired' )
    {
        localStorage.clear();
        window.location.href = '/';
    }
    return response;
});

