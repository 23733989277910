<template>
    <main>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-12 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Topup & Saldo
                    </h1>
                    <div class="page-header-subtitle text-secondary">Kelola saldo balance dan topup.</div>
                </div>
               
            </div>
        </header>
        <hr/>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mb-4">
                    <div class="card-header p-0 ">
                        <div class="row">
                            <div class="col-md-8">
                                <div>
                                    <label class="ft-14"><i class="mdi mdi-information-outline text-danger"></i> Informasi</label>
                                    <div class="mt-1">
                                        <span class="text-secondary">
                                            Agar tidak menganggu transaksi lakukan pengisian saldo sebelum batas minimum saldo anda habis.
                                            Untuk  penyesuaian minimum saldo hingga notifikasi dapat dilakukan konfigurasi di menu integrasi.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 pr-4">
                                <div class="box-summary-circle mt-3 mb-3">
                                    <ul>
                                        <li>
                                            <i class="mdi mdi-wallet bg-icon  bg-icon-danger"></i> 
                                            <div class="pl-3">
                                                <span class="text-secondary">Saldo Balance</span><br/>
                                                <div class="loading-dots" v-if="isLoadSummary"><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div></div>
                                                <small class="" v-else>Rp <b>{{ $filters.formatNumber(balance) }}</b></small>
                                            </div>
                                        </li>
                                    </ul>
                                    <router-link :to="{ name: 'balance-store' }" id="btn-topup"><i class="mdi mdi-plus bg-ic  bg-icon-success"></i> </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="row table-filter">
                            <div class="col-md-2 col">
                                <div class="form-group">
                                    <label>Periode</label>
                                    <select class="form-control" v-model="fdate" @change="FilterFDate()">
                                        <option value="1">Semua Tanggal</option>
                                        <option value="0">Pilih Manual</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-2 col">
                                <div class="form-group">
                                    <label>Tanggal Deposit</label>
                                    <input type="date" class="form-control"  v-model="startdate"   id="startDate"  :disabled="fdate !== '0' ? true  : false" v-if="fdate == '0'"/>
                                    <div id="dummy-date" v-else>-- / -- / ---</div>
                                </div>
                            </div>
                            <div class="col-md-3 col">
                                <div class="form-group">
                                    <label>Deposit Status</label>
                                    <select class="form-control" v-model="meta_filter.deposit_status">
                                        <option value="all">-- Semua Status --</option>
                                        <option value="process">Dalam Proses</option>
                                        <option value="success">Success</option>
                                        <option value="expired text-danger">Expired</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-2 col">
                                <div class="form-group">
                                    <label>Vendor Bank</label>
                                    <select class="form-control" v-model="meta_filter.deposit_vendor">
                                        <option value="all">-- Semua Bank --</option>
                                        <option value="BCA">BCA</option>
                                        <option value="BNI">BNI</option>
                                        <option value="MANDIRI">MANDIRI</option>
                                        <option value="BRI">BRI</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3 col">
                                <div class="form-btn">
                                    <ButtonProcess :classes="'btn btn-full btn-sm btn-warning'"  :type="'Submit'" :title="'Filter'" @click="DataQuery();"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="table-info  table-master">
                            <div class="float-left">
                                <ul>
                                    <li>
                                        <a href="javascript:;" class="download" @click="Download()"> Download </a>
                                    </li>
                                    <li v-if="isDownloadProcess">
                                        <span>Prosess Download ...</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="float-right">
                                <ul>
                                    <li class="">
                                        <i class="mdi mdi-refresh" v-if="! $store.state.loading.status" @click="DataQuery();"></i>
                                        <div class="spinner-border  spinner-border-sm" role="status" v-else></div>
                                    </li>
                                </ul>
                            </div>
                            <div class="group-search">
                                <div class="black-layer" v-if="mixsearch"></div>
                                <div id="box" v-if="mixsearch">
                                    <span class="close" @click="mixsearch = false"><i class="mdi mdi-close"></i></span>
                                    <div class="form-group mt-3">
                                        <label>Pilih Target Pencarian</label>
                                        <select class="form-control" v-model="mixmeta_data.skey">
                                            <option value="inv_number">No.Invoice</option>
                                            <option value="amount">Jumlah / Unique Code</option>
                                        </select>
                                    </div>
                                    <div class="form-group mt-2">
                                        <label>Masukan Keyword</label>
                                        <input type="text" class="form-control" v-model="mixmeta_data.sval"/>
                                        <a href="javascript:;" @click="ServiceRefreshData()" class="clear" v-if="mixmeta_data.sval !== ''"><i class="mdi mdi-delete-circle"></i></a>
                                    </div>
                                    <div class="form-group">
                                        <hr/>
                                        <ButtonProcess :classes="'btn btn-full btn-sm btn-warning'" :submit_state="isSubmitProcess" :type="'Submit'" :title="'Cari Data'" @click="ServiceSearchData()"/>
                                    </div>
                                </div>
                                <a href="javascript:;"  class="search" @click="mixsearch = true"><i class="mdi mdi-magnify"></i></a>
                            </div>
                        </div>
                        <SystemConnection :connection="service_connection"  v-if="isFirstLoad" />
                        <div class="s-table table-cover" v-else>
                            <table :class="isRefreshTable ? 'table-load' : '' ">
                                <thead>
                                    <tr>
                                        <th data-filter="created_at">Tanggal</th>
                                        <th data-filter="inv_number">No.Invoice</th>
                                        <th data-filter="vendor">Keterangan Deposit</th>
                                        <th data-filter="status">TRX Status</th>
                                        <th data-filter="amount" class="text-right">Jumlah (Rp)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(items, idx) in mixmodel.data" :key="idx">
                                        <td width="150">{{ $filters.formatDate(items.created_at, 'DD/MM/YYYY H:m') }}</td>
                                        <td width="120">
                                            <span class="bg-border bg-border-secondary" v-if="items.inv_number !== null"><i class="mdi mdi-file-document"></i> {{ items.inv_number }}</span>
                                            <span v-else>-- --</span>
                                        </td>
                                        <td>
                                            <span v-if="items.inv_number !== null"> {{ items.description !== null ? items.description : 'Deposit saldo melalui '  }} <b>{{ items.vendor }}</b></span>
                                            <span v-else>-- --</span>
                                        </td>
                                        <td width="200">
                                            <span v-if="items.inv_number !== null" :class="GenerateDepositClassStatus(items.status)">{{ items.status == 'process' ? 'Menuggu Pembayaran' : $filters.ucwords(items.status) }}</span>
                                            <span v-else>Error TRX</span>
                                        </td>
                                        <td width="150" class="text-right"><b>{{ $filters.formatNumber(items.amount) }}</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <v-pagination
                            v-if="mixmodel.total > 0"
                            v-model="page"
                            :class="'pagination'"
                            :pages="parseInt(mixmodel.last_page)"
                            :range-size="1"
                            active-color="#ffffff"
                            @update:modelValue="DataQuery"
                        />
                        <div class="paginate-total">Total Records ( {{ $filters.formatNumber(mixmodel.total) }} )</div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>

    import $ from 'jquery';
    import moment from "moment";
    export default {
       
        data() {
            return {
                isDownloadProcess : false,
                isFirstLoad : true,
                isLoadSummary :  false,
                balance : 0,
                model : [],

                env : localStorage.getItem('is_enable_env'),
                freseller : localStorage.getItem('user-code'),
                startdate : new Date( Date.now() + -0*24*3600*1000 ).toISOString().slice(0,10),
                fdate : "1",
                meta_filter : {
                    deposit_by : 'transfer_bank',
                    deposit_status : 'all',
                    deposit_vendor : 'all'
                }
            }
        },
        created()
        {
            this.QuerySummarySaldo();

            this.mixmeta_data.okey = 'created_at';
            this.mixmeta_data.oval = 'desc';
            this.ServiceInitFunction('DataQuery', 's-table');

            setTimeout(() => {
                this.InitialMaxInputDate();
            }, 200);
        },
        methods:
        {
            InitialMaxInputDate()
            {
                var today = new Date();
                var dd = today.getDate();
                var mm = today.getMonth()+1; 
                var yyyy = today.getFullYear();
                if(dd<10){
                    dd='0'+dd
                } 
                if(mm<10){
                    mm='0'+mm
                } 

                today = yyyy+'-'+mm+'-'+dd;
                $('#startDate').prop('max', function(){
                    return today;
                });
            },
            FilterFDate()
            {
                switch(this.fdate)
                {
                    case '1': 
                        this.startdate = new Date( Date.now()).toISOString().slice(0,10);
                    break;
                    default:
                    break;
                }

                if( this.fdate !== '0' )
                {
                    document.getElementById('startDate').value = this.startdate;
                }
            },
            DetailDeposit(slug)
            {
                localStorage.setItem('QUERY_ID', slug);
                this.$router.push({name : 'balance-data'});
            },

            async QuerySummarySaldo()
            {
                this.isLoadSummary = true;
                let res = await this.ServiceApiPost({
                    url:'h2h/'+this.env+'/balance/summary', 
                    data : {
                        code_otmx_reseller : localStorage.getItem('user-code')
                    }
                });
                if(res){ 
                    this.balance = res.balance;
                    this.isLoadSummary = false;
                }
            },
    
            async DataQuery(pageNum)
            {
                this.isEnableFilter = false;
                this.isRefreshTable = true;
                this.page = pageNum == undefined ? 1 : pageNum;

                if(this.fdate == '0')
                {
                    var query_startdate = this.startdate;
                    if( document.getElementById('startDate') )
                    {
                        var sdate = document.getElementById('startDate').value;
                        query_startdate = moment(sdate).format("DD-MM-YYYY");
                    }
                }else{
                    query_startdate = 'all';
                }
              

                let res = await this.ServiceApiDataPaging({
                    url:'h2h/'+this.env+'/balance/data', 
                    offset : this.page,
                    search: { key : this.mixmeta_data.skey, value : this.mixmeta_data.sval },
                    order: { key : this.mixmeta_data.okey, value : this.mixmeta_data.oval },
                    filter : [
                        {key : 'code_otmx_reseller', val : this.freseller},
                        {key : 'start_date', val : query_startdate},
                        {key : 'deposit_by', val:this.meta_filter.deposit_by},
                        {key : 'vendor', val:this.meta_filter.deposit_vendor},
                        {key : 'status', val:this.meta_filter.deposit_status},
                    ]
                });
                if(res){
                    this.mixmodel = res.query;
                    this.isFirstLoad = false;
                    this.isRefreshTable = false;
                }
            },

            GenerateDepositClassStatus(value)
            {
                if (!value) return '';
        
                let class_style = '';
                switch(value){
                    case 'success':
                        class_style = 'status status-success';
                    break;
                    case 'process':
                        class_style = 'status status-process mdi mdi-spin';
                    break;
                    case 'expired':
                        class_style = 'status status-danger';
                    break;
                    default:
                        class_style = 'status status-undefined';
                    break;
                }
                
                return class_style;
            },
            async Download()
            {
                this.isDownloadProcess = true;

                if(this.fdate == '0')
                {
                    var query_startdate = this.startdate;
                    if( document.getElementById('startDate') )
                    {
                        var sdate = document.getElementById('startDate').value;
                        query_startdate = moment(sdate).format("DD-MM-YYYY");
                    }
                }else{
                    query_startdate = 'all';
                }

                const response = await this.ServiceApiPost({url:'h2h/'+this.env+'/balance-download', data : {
                    format : 'csv',
                    filter : [
                        {key : 'code_otmx_reseller', val : this.freseller},
                        {key : 'start_date', val : query_startdate},
                        {key : 'deposit_by', val:this.meta_filter.deposit_by},
                        {key : 'vendor', val:this.meta_filter.deposit_vendor},
                        {key : 'status', val:this.meta_filter.deposit_status},
                    ]
                }});
                if(response)
                { 
                    const filename = 'deposit-balance.csv';
                    const url = window.URL.createObjectURL(new Blob([response]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    this.isDownloadProcess = false;
                }
            }, 
        }
    }
</script>

<style lang="scss" scoped>


#dummy-date{
    background-color:#fff;
    float:left;
    height:33px;
    width:100%;
    padding:9px;
    color:rgb(184, 184, 184);
    border-radius:3px;
    font-size:12px;
}
.box-summary-circle{
    #btn-topup{
        position: absolute;
        right: 12px;
        top: 15px;
        width: 40px;
        height: 40px;
        text-align: center;
        border-radius: 50%;
        background-color: #ffffffeb;
        display: block;
        padding: 2px;
        i{
            color:#ed492c;
            font-size:25px;
        }
    }
    #btn-topup:hover{
        opacity:.85;
        background-color: #ec442c;
        i{
            color:#ebebeb;
        }
    }
}

</style>