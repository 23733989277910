<template>
    <main>
        <BrowseSales v-on:closePopup="CloseBrowseSales"  v-if="isEnablePopup == 'browse-sales'"/>
        <DetailTrans :data_model="model_detail" v-on:closePopup="CloseDetailTrans"  v-if="isEnablePopup == 'detail-trans'"/>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-12 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Transaksi 
                    </h1>
                    <div class="page-header-subtitle text-secondary">Master data transaksi produk.</div>
                </div>
            </div>
        </header>
        <hr/>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mb-4">
                    <div class="card-header p-0">
                        <div class="row">
                            <div class="col-md-8">
                                <label class="ft-14"><i class="mdi mdi-information-outline text-danger"></i> Informasi</label>
                                <div class="mt-1">
                                    <span class="text-secondary">
                                        Anda dapat monitoring semua transaksi dan mengunduh data transaksi mulai dari status transaksi hingga pencarian data berdasarkan periode waktu tertentu.                                    
                                    </span>
                                </div>
                                <!-- <ul class="data-list">
                                    <li>
                                        <span class="float-left">Transaksi Sukses</span> 
                                        <div class="loading-dots float-right" v-if="isLoadSummaryTrx"><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div></div>
                                        <span class="float-right" v-else>{{ $filters.formatNumber(trx.success) }} <small class="text-secondary">TRX</small></span>
                                    </li>
                                    <li>
                                        <span class="float-left">Transaksi Gagal</span> 
                                        <div class="loading-dots float-right" v-if="isLoadSummaryTrx"><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div></div>
                                        <span class="float-right" v-else>{{ $filters.formatNumber(trx.failed) }} <small class="text-secondary">TRX</small></span>
                                    </li>
                                    <li>
                                        <span class="float-left">Transaksi Proses</span> 
                                        <div class="loading-dots float-right" v-if="isLoadSummaryTrx"><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div></div>
                                        <span class="float-right" v-else>{{ $filters.formatNumber(trx.process) }} <small class="text-secondary">TRX</small></span>
                                    </li>
                                </ul> -->
                            </div>
                            <div class="col-md-4 pr-4">
                                <div class="box-summary-circle mt-3 mb-3 ">
                                    <ul>
                                        <li>
                                            <i class="mdi mdi-cash-multiple  bg-icon  bg-icon-warning"></i> 
                                            <div class="pl-3">
                                                <span class="text-secondary">Total Transaksi</span><br/>
                                                <small class="" v-if="isLoadSummaryTrx">Rp <b>0</b></small>
                                                <small class="" v-else>Rp <b>{{ $filters.formatNumber(trx.total_idr) }}</b></small>
                                            </div>
                                        </li>
                                        <li class="number">
                                            <div class="loading-dots float-right" v-if="isLoadSummaryTrx"><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div></div>
                                            <span v-else>{{ $filters.kFormatter(trx.total) }}</span>
                                            <small class="text-secondary">TRX</small>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="row table-filter">
                            <div class="col-md-2 col">
                                <div class="form-group">
                                    <label>Transaksi Status</label>
                                    <select class="form-control" v-model="trx_status">
                                        <option value="all">-- Semua Status --</option>
                                        <option value="0">Belum Diroses</option>
                                        <option value="1">Dalam Proses</option>
                                        <option value="2">Dalam Proses</option>
                                        <option value="22">Masuk Antrian</option>
                                        <option value="20">Sukses</option>
                                        <option value="40">Gagal</option>
                                        <option value="42">Format Salah</option>
                                        <option value="43">Saldo Kurang</option>
                                        <option value="44">Kode Salah</option>
                                        <option value="45">Stok Kosong</option>
                                        <option value="47">Produk Gangguan</option>
                                        <option value="50">Dibatalkan</option>
                                        <option value="51">Reseller Nonaktif</option>
                                        <option value="52">Nomor Salah</option>
                                        <option value="53">Diluar Wilayah</option>
                                        <option value="55">Timeout</option>
                                        <option value="56">Nomor Blacklist</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-2 col">
                                <div class="form-group">
                                    <label>Periode Transaksi</label>
                                    <select class="form-control" v-model="fdate" @change="FilterFDate()">
                                        <option value="0">Pilih Manual</option>
                                        <option value="1">Hari Sekarang</option>
                                        <option value="7">7 Hari Terakhir</option>
                                        <option value="30">30 Hari Terakhir</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3 col">
                                <div class="form-group">
                                    <label>Tanggal Mulai</label>
                                    <input type="date" class="form-control"  v-model="startdate"   id="startDate" :disabled="fdate !== '0' ? true  : false"/>
                                </div>
                            </div>
                            <div class="col-md-3 col">
                                <div class="form-group">
                                    <label>Tanggal Akhir</label>
                                    <input type="date" class="form-control"  v-model="enddate" id="endDate" :disabled="fdate !== '0' ? true  : false"/>
                                </div>
                            </div>
                            <div class="col-md-2 col">
                                <div class="form-btn">
                                    <ButtonProcess :classes="'btn btn-full btn-sm btn-warning'"  :type="'Submit'" :title="'Filter'" @click="DataQuery(); QuerySummaryTrx();"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="table-info table-master">
                            <div class="float-left">
                                <ul>
                                    <li>
                                        <a href="javascript:;" class="download" @click="Download()"> Download </a>
                                    </li>
                                    <li v-if="isDownloadProcess">
                                        <span>Prosess Download ...</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="float-right">
                                <ul>
                                    <li class="">
                                        <i class="mdi mdi-refresh" v-if="! $store.state.loading.status" @click="DataQuery(); QuerySummaryTrx();"></i>
                                        <div class="spinner-border  spinner-border-sm" role="status" v-else></div>
                                    </li>
                                </ul>
                            </div>
                            <div class="group-search">
                                <div class="black-layer" v-if="mixsearch"></div>
                                <div id="box" v-if="mixsearch">
                                    <span class="close" @click="mixsearch = false"><i class="mdi mdi-close"></i></span>
                                    <div class="form-group mt-3">
                                        <label>Pilih Target Pencarian</label>
                                        <select class="form-control" v-model="mixmeta_data.skey">
                                            <option :value="env == 'staging' ? 'h2h_transaction.trx_code' : 'transaksi.kode'">ID Transaksi</option>
                                            <option :value="env == 'staging' ? 'h2h_transaction.ref_id' : 'transaksi.ref_id'">Ref-ID</option>
                                            <option :value="env == 'staging' ? 'h2h_transaction.number' : 'transaksi.tujuan'">Nomor Tujuan</option>
                                        </select>
                                    </div>
                                    <div class="form-group mt-2">
                                        <label>Masukan Keyword</label>
                                        <input type="text" class="form-control" v-model="mixmeta_data.sval"/>
                                        <a href="javascript:;" @click="ServiceRefreshData()" class="clear" v-if="mixmeta_data.sval !== ''"><i class="mdi mdi-delete-circle"></i></a>
                                    </div>
                                    <div class="form-group">
                                        <hr/>
                                        <ButtonProcess :classes="'btn btn-full btn-sm btn-warning'" :submit_state="isSubmitProcess" :type="'Submit'" :title="'Cari Data'" @click="ServiceSearchData()"/>
                                    </div>
                                </div>
                                <a href="javascript:;"  class="search" @click="mixsearch = true"><i class="mdi mdi-magnify"></i></a>
                                 
                            </div>
                        </div>
                        <SystemConnection :connection="service_connection"  v-if="isFirstLoad" />
                        <div class="s-table table-cover" v-else>
                            <table :class="isRefreshTable ? 'table-load' : '' ">
                                <thead>
                                    <tr>
                                        <th data-filter="kode">ID Transaksi</th>
                                        <th data-filter="ref_id">Ref-ID</th>
                                        <th data-filter="nama_produk">Nama Produk</th>
                                        <th data-filter="tujuan">No.Tujuan</th>
                                        <th data-filter="harga">Harga</th>
                                        <th data-filter="status">Status</th>
                                        <th :data-filter="env == 'production' ? 'tgl_entri' : 'created_at'">Tgl.Transaksi</th>
                                        <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody v-if="mixmodel.total > 0">
                                    <tr v-for="(items, idx) in mixmodel.data" :key="idx">
                                        <td width="120">{{ items.kode }}</td>
                                        <td width="130">
                                            <span v-if="items.ref_id !== null"><i class="mdi mdi-file-hidden text-success"></i> {{ items.ref_id }}</span>
                                            <span class="text-secondary" v-else>-- --</span>
                                        </td>
                                        <td width="300">{{ items.kode_produk }} - {{ $filters.ucwords(items.nama_produk) }}<br/></td>
                                        <td width="150">{{ items.tujuan }}</td>
                                        <td width="120">Rp {{ $filters.formatNumber(items.harga) }}</td>
                                        <td width="120"><span :class="$filters.GenerateTrxClassStatus(items.status)">{{ items.status }}</span></td>
                                        <td width="130">{{ $filters.formatDate(items.tgl_entri, 'DD/MM/YYYY') }}<br/><small class="text-secondary">{{ $filters.formatDate(items.tgl_entri, 'LT') }}</small></td>
                                        <td width="100" class="text-center">
                                            <a href="javascript:;" class="btn btn-sm btn-orange btn-with-icon"  @click="DataDetail(idx)"> <i class="mdi mdi-gesture-tap"></i> Detail</a> 
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="8">
                                            Data kosong / tidak ditemukan.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <v-pagination
                            v-if="mixmodel.total > 0"
                            v-model="page"
                            :pages="parseInt(mixmodel.last_page)"
                            :range-size="1"
                            active-color="#ffffff"
                            @update:modelValue="DataQuery"
                        />
                        <div class="paginate-total">Total Records ( {{ $filters.formatNumber(mixmodel.total) }} )</div>
                    </div>
                </div>
            </div>
        </div>

    </main>
</template>


<script>
    import $ from 'jquery';
    import moment from "moment";
    import BrowseSales from '../Resellers/BrowseSales';
    import DetailTrans from './DetailTrans';

    export default {
        components: {
            BrowseSales,
            DetailTrans
        },
        data() {
            return {
                isDownloadProcess : false,
                isFirstLoad : true,
                isEnableSorting : false,
                isLoadSummaryTrx : false,
                isEnablePopup : false,
                isActiveFilterTab : 'search',
                model_detail : {},
                model_upline : {},
                env : localStorage.getItem('is_enable_env'),
                freseller : localStorage.getItem('user-code'),
                fprivileges : localStorage.getItem('user-privileges'),
                input_code_reseller : '',

                fdate : 7,
                trx_status : 'all',
                startdate : new Date( Date.now() + -6*24*3600*1000 ).toISOString().slice(0,10),
                enddate : new Date().toISOString().slice(0,10),
                trx : {
                    total : 0,
                    total_idr : 0,
                    success : 0,
                    failed : 0,
                    process : 0
                },
            }
        },
        created()
        {
            this.mixmeta_data.okey = 'ref_id';
            this.mixmeta_data.oval = 'desc';
            this.ServiceInitFunction('DataQuery', 's-table');
            this.QuerySummaryTrx();

            setTimeout(() => {
                this.InitialMaxInputDate();
            }, 200);
        },
        methods:
        {
            InitialMaxInputDate()
            {
                var today = new Date();
                var dd = today.getDate();
                var mm = today.getMonth()+1; 
                var yyyy = today.getFullYear();
                if(dd<10){
                    dd='0'+dd
                } 
                if(mm<10){
                    mm='0'+mm
                } 

                today = yyyy+'-'+mm+'-'+dd;
                $('#startDate, #endDate').prop('max', function(){
                    return today;
                });
            },
            FilterFDate()
            {
                switch(this.fdate)
                {
                    case '1': 
                        this.startdate = new Date( Date.now()).toISOString().slice(0,10);
                        this.enddate = new Date(Date.now()).toISOString().slice(0,10);
                    break;
                    case '7': 
                        this.startdate = new Date( Date.now() + -6*24*3600*1000 ).toISOString().slice(0,10);
                        this.enddate = new Date(Date.now()).toISOString().slice(0,10);
                    break;
                    case '30': 
                        this.startdate = new Date( Date.now() + -29*24*3600*1000 ).toISOString().slice(0,10);
                        this.enddate = new Date(Date.now()).toISOString().slice(0,10);
                    break;
                    default:
                    break;
                }

                if( this.fdate !== '0' )
                {
                    document.getElementById('startDate').value = this.startdate;
                    document.getElementById('endDate').value = this.enddate;
                }
            },
            
            CloseDetailTrans()
            {
                this.isEnablePopup = false;
            },
            
            DataDetail(idx)
            {
                this.model_detail = this.mixmodel.data[idx];
                this.isEnablePopup = 'detail-trans';
            },
            async DataQuery(pageNum)
            {
                this.isRefreshTable = true;
                this.page = pageNum == undefined ? 1 : pageNum;

                var query_startdate = this.startdate;
                var query_enddate   = this.enddate;
                if( document.getElementById('startDate') && document.getElementById('endDate') )
                {
                    var sdate = document.getElementById('startDate').value;
                    var edate = document.getElementById('endDate').value;
                    query_startdate = moment(sdate).format("DD-MM-YYYY");
                    query_enddate   = moment(edate).format("DD-MM-YYYY");
                }


                // filter
                var filter_reseller = [];
                if( this.env == 'production' ){
                    filter_reseller = 'reseller.kode';
                }
                if( this.env == 'staging' ){
                    filter_reseller = 'code_otmx_reseller';
                }

                let res = await this.ServiceApiDataPaging({
                    url:'h2h/'+this.env+'/transaction-data', 
                    offset : this.page,
                    search: { key : this.mixmeta_data.skey, value : this.mixmeta_data.sval },
                    order: { key : this.mixmeta_data.okey, value : this.mixmeta_data.oval },
                    filter : [
                        {key : 'start_date', val : query_startdate},
                        {key : 'end_date', val : query_enddate},
                        {key : 'status', val : this.trx_status},
                        {key : filter_reseller, val : this.freseller}
                    ],
                });
                if(res){ 
                    this.mixmodel = res.query; 
                    this.isFirstLoad = false;
                    this.isRefreshTable = false;
                }
            },
            async QuerySummaryTrx()
            {
                var query_startdate = this.startdate;
                var query_enddate   = this.enddate;
                if( document.getElementById('startDate') ){
                    var sdate = document.getElementById('startDate').value;
                    var edate = document.getElementById('endDate').value;
                    query_startdate = moment(sdate).format("DD-MM-YYYY");
                    query_enddate   = moment(edate).format("DD-MM-YYYY");
                }

                this.isLoadSummaryTrx = true;
                let res = await this.ServiceApiPost({
                    url:'h2h/'+this.env+'/transaction-summary', 
                    data : {
                        start_date : query_startdate,
                        end_date : query_enddate,
                        code_otmx_reseller : this.freseller,
                        trx_status : this.trx_status
                    }
                });
                if(res){ 
                    let model = res.query;
                    this.trx.total = model.total; 
                    this.trx.total_idr = model.total_idr; 
                    this.trx.success = model.status_success; 
                    this.trx.failed = model.status_failed; 
                    this.trx.process = model.status_process; 
                    this.isLoadSummaryTrx = false;
                }
            },
            async Download()
            {
                this.isDownloadProcess = true;

                var query_startdate = this.startdate;
                var query_enddate   = this.enddate;
                if( document.getElementById('startDate') && document.getElementById('endDate') )
                {
                    var sdate = document.getElementById('startDate').value;
                    var edate = document.getElementById('endDate').value;
                    query_startdate = moment(sdate).format("DD-MM-YYYY");
                    query_enddate   = moment(edate).format("DD-MM-YYYY");
                }


                // filter
                var filter_reseller = [];
                if( this.env == 'production' ){
                    filter_reseller = 'reseller.kode';
                }
                if( this.env == 'staging' ){
                    filter_reseller = 'code_otmx_reseller';
                }

                const response = await this.ServiceApiPost({url:'h2h/'+this.env+'/transaction-download', data : {
                    format : 'csv',
                    filter : [
                        {key : 'start_date', val : query_startdate},
                        {key : 'end_date', val : query_enddate},
                        {key : 'status', val : this.trx_status},
                        {key : filter_reseller, val : this.freseller}
                    ],
                }});
                if(response)
                { 
                    const filename = 'transaction.csv';
                    const url = window.URL.createObjectURL(new Blob([response]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    this.isDownloadProcess = false;
                }
            }, 
        }
    }
</script>



<style lang="scss" scoped>
.period{
    width: auto;
    float: left;

    .icon{
        padding: 5px 7px;
        background-color:#ec422ed4;
        color:#fff;
    }
    .date{
        position:relative;
        left:-1px;
        padding: 5px 10px;
    }
}

.data-list{
    width:50%;
    li{
        border-bottom: 1px solid #d3d3d3;
    }
}

</style>