import { createStore } from "vuex";

const host = location.hostname;
let api_resource = '';
if(host == 'client.dev.dflash.co.id'){
   api_resource = process.env.VUE_APP_API_URL_DEV;  
}else if(host == 'client-staging.dflash.co.id'){
   api_resource = process.env.VUE_APP_API_URL_STAGING;   
}else if(host == 'client.dflash.co.id'){
   api_resource = process.env.VUE_APP_API_URL_PROD;   
}else{
   api_resource = process.env.VUE_APP_API_URL_STAGING;   
}
api_resource = api_resource.replace('index.php/api/', 'public/');


const state = {
    // api
    api_resource : api_resource,

    // application
    app_enable : localStorage.getItem('enable-app') ? localStorage.getItem('enable-app') : 'login',
    app_msg : {status : false, title:'', text: '', type:''},
    loading : {status : false, progress :20, msg : 'Please Wait ...'},
}

export default createStore({
    state
})


