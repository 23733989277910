
<template>
    <div class="cov-google">
        <input type="text" id="input_address" class="form-control" placeholder="...." ref="search" />
        <input type="hidden" id="input_lat"/>
        <input type="hidden" id="input_lng"/><br/>
        <div class="google-map" id="gmap">
            <div class="p-2">
                <span class="text-secondary">Please enter valid address.</span>
            </div>
        </div>
        <small><i class="ti ti-info-alt text-info"></i> Drag the marker to help you find the detail address</small>
    </div>
</template>

  
<script>
export default {
    name: 'GoogleMap',
    props: ['mode'],

    data: () => ({
      location: null
    }),
    mounted() {
        if (window.google) 
        {  
            this.initLocationSearch();
            if(this.mode && this.mode == 'update')
            {
                setTimeout(() => {
                    this.initialUpdate();
                }, 1500);
            }
        }
   },
   methods: {

        initialUpdate()
        {
            let input_lat = document.getElementById('input_lat').value;
            let input_lng = document.getElementById('input_lng').value;
            var myLatlng = new window.google.maps.LatLng(input_lat, input_lng);
            var mapOptions = {
                zoom: 12,
                center: myLatlng
            }
            var map = new window.google.maps.Map(document.getElementById("gmap"), mapOptions);
            var marker = new window.google.maps.Marker({
                position: myLatlng,
                draggable:true,
            });

            // To add the marker to the map, call setMap();
            marker.setMap(map);

            new window.google.maps.event.addListener(marker, 'dragend', function(marker)
            {
                const latLng = marker.latLng; 
                let new_lat = latLng.lat();
                let new_lng = latLng.lng();

                const geocoder = new window.google.maps.Geocoder();
                geocoder.geocode({'latLng': latLng}, function(results, status) 
                {
                    if (status == 'OK' && results[0]) 
                    {
                        // update 
                        if( document.getElementById('input_address').value !== '' )
                        {
                            document.getElementById('input_address').value = results[0].formatted_address;
                            document.getElementById('input_lat').value = new_lat;
                            document.getElementById('input_lng').value = new_lng;
                        }
                    }
                });
            });
        },

        initLocationSearch() 
        {
            let autocomplete = new window.google.maps.places.Autocomplete(this.$refs.search);
            autocomplete.addListener("place_changed", function() {
                let place = autocomplete.getPlace();
                if (place && place.address_components) 
                {
                    const mapCentre = {
                        latitude: place.geometry.location.lat(),
                        longitude: place.geometry.location.lng()
                    };
                    
                    document.getElementById('input_lat').value = place.geometry.location.lat();
                    document.getElementById('input_lng').value = place.geometry.location.lng();

                    var map = new window.google.maps.Map(document.getElementById('gmap'), {
                        center: {lat: mapCentre.latitude, lng: mapCentre.longitude},
                        zoom: 15,
                        geocodeAfterResult: false,
                    });
                    var marker = new window.google.maps.Marker({
                        position: {lat: mapCentre.latitude, lng: mapCentre.longitude}, 
                        map: map,
                        draggable:true,
                    });

                    new window.google.maps.event.addListener(marker, 'dragend', function(marker)
                    {
                        const latLng = marker.latLng; 
                        let new_lat = latLng.lat();
                        let new_lng = latLng.lng();

                        const geocoder = new window.google.maps.Geocoder();
                        geocoder.geocode({'latLng': latLng}, function(results, status) 
                        {
                            if (status == 'OK' && results[0]) 
                            {
                                // update 
                                if( document.getElementById('input_address').value !== '' )
                                {
                                    document.getElementById('input_address').value = results[0].formatted_address;
                                    document.getElementById('input_lat').value = new_lat;
                                    document.getElementById('input_lng').value = new_lng;
                                }
                            }
                        });
                    });
                }
            });
        },
   }
};
</script>
<style lang='scss' scoped>
.cov-google{
    width:100%;
    input[type="text"]{
        width:100%;
        margin-bottom:10px;
    }
    .google-map {
        width: 100%;
        height: 300px;
        margin: 0 auto;
        margin-top:20px;
        margin-bottom:10px;
        background: #ececec;
    }
}

#input_address{
    float:left;
    width:100%;
    margin-right:10px;
    margin-bottom:10px;
}
.btn{width:100%;margin-bottom:10px;}

</style>