export default {
    data() {
        return {
            service_form_error : {},
            service_connection : true,
        }
    },
    methods: {
        ServiceApiMessage(title, text, type)
        { 
            this.$store.state.app_msg.title  = title;
            this.$store.state.app_msg.text   = text;
            this.$store.state.app_msg.type   = type;
            this.$store.state.app_msg.status = true;
          
            setTimeout(() => {
                this.$store.state.app_msg.status = false;
            }, 3000);
        },

        async ServiceApiGet(payload) 
        {
            this.$store.state.loading.status = true;
            return await this.$axios.get(payload.url)
            .then((response) => {
                return response.data;
            }).finally(() => {
                setTimeout(() => {this.$store.state.loading.status = false;}, 1800);
            });
        },

        async ServiceApiPing(payload) 
        {
            return await this.$axios.get(payload.url)
            .then((response) => {
                return response.data;
            }).finally(() => {
                setTimeout(() => {this.$store.state.loading.status = false;}, 1800);
            });
        },
        
        async ServiceApiPost(payload)
        {
            this.$store.state.loading.status = true;
            this.$store.state.loading.msg  = 'Please Wait...';
            this.service_form_error = {};
            
            var config_headers = {};
            if( payload.headers ){
                config_headers = {
                    headers: {
                        'Accept': 'application/json',
                        'Content-type' : 'multipart/form-data;boundary=' + Math.random().toString().substr(2)
                    }
                };
            }
            
            return await this.$axios.post(payload.url, payload.data, config_headers)
            .then((response) => 
            {
                let res = response.data;
                this.$store.state.loading.status = false;

                if( payload.action && payload.action == 'post')
                {
                    if( res.result )
                    {
                        this.ServiceApiMessage(
                            res.result ? 'Congratulations' : 'Atention', 
                            res.msg, 
                            payload.msg_type !== undefined ? payload.msg_type : 'default'
                        );
                        
                        if(payload.callback_url){
                            this.$router.push('/'+payload.callback_url);
                        }

                    }else{
                        this.ServiceApiMessage('Atention', res.msg, 'alert' );
                    }
                }
                
                return response.data;
            })
            .catch( (error) =>  
            {
                let data = Object.assign({}, error);
                if( data.response !== undefined && data.response.status == 422 )
                {
                    let res = JSON.parse(error.request.responseText);
                    this.service_form_error = res.errors;
                    return res;
                }

            }).finally(() => {
                setTimeout(() => {this.$store.state.loading.status = false;}, 1800);
            });
        },
        
        async ServiceApiDataPaging(payload) 
        {
            this.$store.state.loading.status = false;
            this.$store.state.loading.progress = 0;

            var meta_search = '',  meta_order = '', meta_filter = '', meta_where = '';
            var page = payload && payload.offset !== undefined ? payload.offset : 1;
            

            // search query    
            if(payload && payload.search !== undefined)
            {
                meta_search = {};
                meta_search.field   = payload.search.key;
                meta_search.keyword = payload.search.value;
            }

            // order by
            if(payload && payload.order !== undefined)
            {
                if( payload.order.key !== '' && payload.order.val !== '' )
                {
                    meta_order = {};
                    meta_order.key   = payload.order.key;
                    meta_order.value = payload.order.value;
                }
            }

            // filter query
            if(payload && payload.filter !== undefined)
            {
                meta_filter = [];
                meta_filter = payload.filter;
            }

            //  where
            if(payload && payload.where !== undefined)
            {
                meta_where = [];
                meta_where = payload.where;
            }
            
            this.$store.state.loading.status = true;
            this.$store.state.loading.msg  = 'Please Wait...';
            this.$store.state.loading.progress = 100;
            return await this.$axios.post(payload.url+"?page="+page,{
                search : meta_search,
                identified_user : payload.identified_user ? payload.identified_user : '',
                order : meta_order,
                filter: meta_filter,
                group : payload.group ? payload.group : '',
                where :  meta_where,
                paginate : payload.paginate ? payload.paginate : 10
            })
            .then((response) => {
                this.$store.state.loading.status = false;
                return  response.data;
            }).catch( (error) =>  
            {
                let data = Object.assign({}, error);

                // connection failed/error
                if( data.isAxiosError )
                {
                    if( ! data['response'] ){
                        this.service_connection = 'error-client-network';
                    }else{
                        
                        if( data['response']['data']['file'] ){
                            this.service_connection = 'error-server';
                        }else{
                            this.service_connection = 'error-client-network';
                        }
                    }
                    
                    /*if( data['response']['data']['file'] ){
                        this.service_connection = 'error-server';
                    }else{
                        this.service_connection = 'error-client-network';
                    }*/
                }
                
            });
        }
    }
}