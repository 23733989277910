<template>
    <main>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Manual Book <span>-</span> <span class="text-success">Last Update : </span><span>24-Nov-2023</span>
                    </h1>
                    <div class="page-header-subtitle text-secondary">Dokumentasi penjelasan tentang tata cara penggunaan API parameter, endpoint, deskripsi dan lain-lain.</div>
                </div>
            </div>
        </header>
        <hr/>
        <div class="row">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-3 pl-0 ">
                                <div id="doc-list">
                                    <ul>
                                        <li class="title"><span>INTRODUCTION</span></li>
                                        <li><a href="javascript:;" @click="GoTo('desc-introduction')">Apa itu API</a></li>
                                        <li><a href="javascript:;" @click="GoTo('desc-introduction')">Mengenal Sandbox</a></li>
                                        <li class="title"><span>AUTHENTICATION</span></li>
                                        <li><a href="javascript:;" @click="GoTo('desc-oauth')">OAuth2.0</a></li>
                                        <li><a href="javascript:;" @click="GoTo('desc-headers')">Headers</a></li>
                                        <li class="title"><span>API SERVICES</span></li>
                                        <li><a href="javascript:;" @click="GoTo('desc-token')">Get Token</a></li>
                                        <li><a href="javascript:;" @click="GoTo('desc-myaccount')">My Account</a></li>
                                        <li><a href="javascript:;" @click="GoTo('desc-category')">Kategori Produk</a></li>
                                        <li><a href="javascript:;" @click="GoTo('desc-subcategory')">Sub-Kategori Produk</a></li>
                                        <li><a href="javascript:;" @click="GoTo('desc-product')">Master Produk</a></li>
                                        <li><a href="javascript:;" @click="GoTo('desc-check-tagihan')">Check Tagihan</a></li>
                                        <li><a href="javascript:;" @click="GoTo('desc-trx')">Beli Pulsa, Paket Data dan lain-lain</a></li>
                                        <li><a href="javascript:;" @click="GoTo('desc-history')">History Transaksi</a></li>
                                        <li><a href="javascript:;" @click="GoTo('desc-check-trx')">Cek Transaksi</a></li>
                                        <li><a href="javascript:;" @click="GoTo('desc-check-saldo')">Cek Saldo Balance</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-9">
                                <div id="doc-desc">
                                    <div class="chapter-title">
                                        <span class="float-left">CHAPTER</span>
                                        <span class="float-right title">INTRODUCTION</span>
                                    </div>
                                    <div class="chapter" id="desc-introduction">
                                        <p><b>Apa itu API ? </b> API atau yang biasa di sebut Application Programming Interface atau Antarmuka Pemograman Aplikasi merupakan sebuah platform atau jembatan penghubung komunikasi antara satu aplikasi dengan aplikasi lain yang melalui jaringan protocol HTTP.</p>
                                        <p><b>Sandbox</b> merupakan sebuah aplikasi digital atau platform aplikasi yang menyediakan layanan/service dimana pada umumnya digunakan untuk unit testing integrasi sistem, konfigurasi, serta monitoring
                                        aktivitas yang terjadi antara 2 aplikasi atau server yang terhubung dalam environment platform sistem.</p>
                                    </div>
                                    <div class="chapter-title">
                                        <span class="float-left">CHAPTER</span>
                                        <span class="float-right title">AUTHENTICATION</span>
                                    </div>
                                    <div class="chapter" id="desc-oauth">
                                        <h4>OAuth2.0</h4>
                                        <p>Dflash API menggunakan OAuth 2.0 sebagai kerangka otorisasi. Untuk mendapatkan token akses anda harus memiliki "client_id" dan "client_secret" sebagai dasar akun otentikasi yang nantinya digunakan untuk mendapatkan access token. Untuk mempelajari lebih lanjut tentang kerangka otorisasi OAuth 2.0, Anda dapat membaca <a href="https://tools.ietf.org/html/rfc6749" target="__blank">Dokumentasi RFC6749.</a></p>
                                        <div class="alert alert-danger">
                                            <i class="mdi mdi-alert"></i> 
                                            "Client ID" dan "Client Secret" merupakan data bersifat credential. Mohon menjaga kerahasian informasi akun anda dari orang lain/pihak yang tidak berkepentingan.
                                        </div>
                                    </div>
                                    <div class="chapter" id="desc-headers">
                                        <h4>Headers</h4>
                                        <p>Untuk dapat selalu terhubung dan melakukan request data pada setiap pemanggilan endpoint API anda harus menyertakan "access_token" serta konfigurasi headers.</p>
                                        <div class="table">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Nama</th>
                                                        <th>Tipe</th>
                                                        <th>Deskripsi</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Authorization</td>
                                                        <td>Alphanumeric</td>
                                                        <td>OAuth2.0 Token Format value: Bearer {access_token}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Content-Type</td>
                                                        <td>Alphanumeric</td>
                                                        <td>Konten isi dari permintaan/request body. contoh (applicaton/json)</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="chapter-title">
                                        <span class="float-left">CHAPTER</span>
                                        <span class="float-right title">API SERVICES</span>
                                    </div>
                                    <div class="chapter">
                                        <p>Dflash H2H menyediakan berbagai layanan/service API yang dapat anda gunakan sesuai kebutuhan integrasi sistem. Semua request yang di HIT pada endpoint API  memiliki 
                                            standarisasi response balasan yaitu seperti <b>http code</b>, <b>request status</b>, <b>message</b> dan beberapa lainnya disertakan dengan <b>data json</b>.</p>
                                        <p>Agar memudahkan anda melakukan integrasi sistem, anda dapat membaca dan mempelajari lebih lanjut tentang response status beserta dengan http code melalui <router-link :to="{ name: 'httpcode' }">Dokumentasi HTTP Code & Response</router-link>.</p>
                                    </div>
                                    <div class="chapter" id="desc-token">
                                        <h4>Get Token <br/><small>Request Method : <b class="text-success">POST</b></small></h4>
                                        <div class="endpoint">
                                            <span class="title">Endpoint</span>
                                            <span class="url">auth/get-token</span>
                                        </div>
                                        <p>Token merupakan standar otorisasi pada OAuth2 dimana memiliki fungsional sebagai otentikasi "Header Authorization" agar server tujuan dapat mengenali setiap request yang datang. Didalam data hasil extraksi token terdiri dari berbagai algoritma header seperti  HMAC SHA256 atau RSA, payload dan verify signature.
                                        Selain daripada itu token juga memiliki batas waktu penggunaan (expired time) atau masa kadaluarsa token. </p>
                                        
                                        <span># Request</span>
                                        <div class="table mt-2 mb-4">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Parameter</th>
                                                        <th>Tipe</th>
                                                        <th class="text-center" width="120">Mandatory</th>
                                                        <th>Deskripsi</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>client_id</td>
                                                        <td>Alphanumeric</td>
                                                        <td class="text-center">Yes</td>
                                                        <td>Unqiue Indentified ID yang dimiliki oleh setiap client sebagai parameter utama untuk mendapatkan token aplikasi.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>client_secret</td>
                                                        <td>Alphanumeric</td>
                                                        <td class="text-center">Yes</td>
                                                        <td>Secret Key yang merupakan hasil generate beberapa algoritma dan signature yang wajib di sertakan dalam client_id.</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <span># Response</span>
                                        <div class="table mt-2">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Field</th>
                                                        <th>Tipe</th>
                                                        <th>Deskripsi</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>type</td>
                                                        <td>String</td>
                                                        <td>Tipe dari Header Authorization contoh : "Bearer".</td>
                                                    </tr>
                                                    <tr>    
                                                        <td>token</td>
                                                        <td>Alphanumeric</td>
                                                        <td>Access token yang dapat digunakan untuk Header Authorization Token ( hasil generate client_id dan client_secret).</td>
                                                    </tr>
                                                    <tr>
                                                        <td>expire_token</td>
                                                        <td>Numeric</td>
                                                        <td>Masa expired ( kadaluarsa token ).</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="chapter" id="desc-myaccount">
                                        <h4>My Account <br/><small>Request Method : <b class="text-danger">GET</b></small></h4>
                                        <div class="endpoint">
                                            <span class="title">Endpoint</span>
                                            <span class="url">auth/me</span>
                                        </div>
                                        <p>Menampilkan data akun pribadi yang terdaftar beserta dengan environment system yang saat ini sedang aktif terhubung.</p>
                                       
                                        <span># Response</span>
                                        <div class="table mt-2">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Field</th>
                                                        <th>Tipe</th>
                                                        <th>Deskripsi</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>env</td>
                                                        <td>String</td>
                                                        <td>Environment aktif yang saat ini sedang terhubung/digunakan.</td>
                                                    </tr>
                                                    <tr>    
                                                        <td>ip_whitelist</td>
                                                        <td>String</td>
                                                        <td>IP Address Server yang anda setting pada environment ( <span class="text-success">Production</span> ).</td>
                                                    </tr>
                                                    <tr>
                                                        <td>kode_reseller</td>
                                                        <td>String</td>
                                                        <td>Kode reseller yang anda dapat pada saat mendaftar.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>kode_level</td>
                                                        <td>String</td>
                                                        <td>Kode level saat ini.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>nama_pemilik_konter</td>
                                                        <td>String</td>
                                                        <td>Nama pemilik konter yang terdaftar saat ini.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>nama_konter</td>
                                                        <td>String</td>
                                                        <td>Nama konter yang terdaftar saat ini.</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="chapter" id="desc-category">
                                        <h4>Kategori Produk <br/><small>Request Method : <b class="text-danger">GET</b></small></h4>
                                        <div class="endpoint">
                                            <span class="title">Endpoint</span>
                                            <span class="url">product/category</span>
                                        </div>
                                        <p>Untuk memudahkan anda dalam manage produk kami telah menyusun data produk berdasarkan klasifikasi kategori. Pada endpoint ini semua kategori produk akan ditampilkan beserta dengan id nya dimana id tersebut akan digunakan sebagai value dari parameter ketika anda memanggil endpoint "Sub-Kategori Produk"</p>
                                        <span># Response</span>
                                        <div class="table mt-2">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Field</th>
                                                        <th>Tipe</th>
                                                        <th>Deskripsi</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>id_kategori</td>
                                                        <td>Numeric</td>
                                                        <td>Unique ID produk kategori.</td>
                                                    </tr>
                                                    <tr>    
                                                        <td>nama_kategori</td>
                                                        <td>String</td>
                                                        <td>Nama dari kategori produk.</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="chapter" id="desc-subcategory">
                                        <h4>Sub-Kategori Produk <br/><small>Request Method : <b class="text-success">POST</b></small></h4>
                                        <div class="endpoint">
                                            <span class="title">Endpoint</span>
                                            <span class="url">product/subcategory</span>
                                        </div>
                                        <p>Subkategori merupakan suatu turunan dari kategori produk. Dimana kategori produk sebagai parent dan sub-kategori produk sebagai child. Untuk menggunakan endpoint ini anda harus melakukan request/hit terlebih dahulu ke endpoint "Kategori Produk" untuk
                                            mendapatkan value "id_kategori" dimana nantinya value tersebut akan digunakan sebagai parameter.</p>
                                        <span># Request</span>
                                        <div class="table mt-2 mb-4">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Parameter</th>
                                                        <th>Tipe</th>
                                                        <th class="text-center" width="120">Mandatory</th>
                                                        <th>Deskripsi</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>id_kategori</td>
                                                        <td>Numeric</td>
                                                        <td class="text-center">Yes</td>
                                                        <td>Unique Id milik kategori produk dimana value nya bisa anda dapatkan melalui pemanggilan endpoint "kategori produk".</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <span># Response</span>
                                        <div class="table mt-2">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Field</th>
                                                        <th>Tipe</th>
                                                        <th>Deskripsi</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>    
                                                        <td>id_subkategori</td>
                                                        <td>Numeric</td>
                                                        <td>Unique Id dari sub-kategori produk.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>nama_kategori</td>
                                                        <td>Numeric</td>
                                                        <td>Nama Sub-Kategori.</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="chapter" id="desc-product">
                                        <h4>Master Produk <br/><small>Request Method : <b class="text-success">POST</b></small></h4>
                                        <div class="endpoint">
                                            <span class="title">Endpoint</span>
                                            <span class="url">product/master</span>
                                        </div>
                                        <p>Merupakan kumpulan dari seluruh produk digital. Data yang ditampilkan dalam produk master nantinya dapat digunakan sebagai parameter untuk melakukan request transaksi. 
                                            Untuk mendapatkan data produk master dibutuhkan sebuah parameter wajib yaitu [id_subcategory] pastikan anda mendapatkan value dari id_subcategory melalui endpoint "Sub-Kategori".</p>
                                        <span># Request</span>
                                        <div class="table mt-2 mb-4">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Parameter</th>
                                                        <th>Tipe</th>
                                                        <th class="text-center" width="120">Mandatory</th>
                                                        <th>Deskripsi</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>id_subkategori</td>
                                                        <td>Numeric</td>
                                                        <td class="text-center">Yes</td>
                                                        <td>Unique Id milik sub-kategori produk dimana value nya bisa anda dapatkan melalui pemanggilan endpoint "sub-kategori produk".</td>
                                                    </tr>
                                                    <tr>
                                                        <td>kode_provider</td>
                                                        <td>String</td>
                                                        <td class="text-center">No</td>
                                                        <td>Unique Id milik provider dimana value nya dapat anda gunakan sebagai filter tambahan untuk query data.</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="alert alert-warning mb-4">
                                            <i class="mdi mdi-information"></i> 
                                            Parameter "kode_provider" bersifat optional. Jika anda tidak memerlukan penggunaan filter anda dapat menghapus parameter tersebut.
                                        </div>

                                        <span># Response</span>
                                        <div class="table mt-2">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Field</th>
                                                        <th>Tipe</th>
                                                        <th>Deskripsi</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>    
                                                        <td>kode_produk</td>
                                                        <td>String</td>
                                                        <td>Unique kode produk.</td>
                                                    </tr>
                                                    <tr>    
                                                        <td>nama_produk</td>
                                                        <td>String</td>
                                                        <td>Nama produk.</td>
                                                    </tr>
                                                    <tr>    
                                                        <td>kode_provider</td>
                                                        <td>String</td>
                                                        <td>Kode unique milik provider.</td>
                                                    </tr>
                                                    <tr>    
                                                        <td>nama_provider</td>
                                                        <td>String</td>
                                                        <td>Nama dari provider produk.</td>
                                                    </tr>
                                                    <tr>    
                                                        <td>harga</td>
                                                        <td>Numeric</td>
                                                        <td>Harga produk dalam format IDR. - <b class="text-danger">*</b> Khusus produk tagihan harga tidak ditampilkan.</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="chapter" id="desc-check-tagihan">
                                        <h4>Cek Tagihan & Bayar <br/><small>Request Method : <b class="text-success">POST</b></small></h4>
                                        <div class="endpoint">
                                            <span class="title">Endpoint</span>
                                            <span class="url">trx/tagihan</span>
                                        </div>
                                        <p>Anda dapat melakukan pengecekan jumlah tagihan saat ini ataupun melakukan pembayaran tagihan. Perbedaan antara <b>cek tagihan</b> dan <b>bayar tagihan</b> pada umumnya terletak pada awal kode produk. misalnya untuk cek tagihan kode produk
                                        di awali dengan huruf "C" (CPLN) dan untuk pembayaran kode produk di awali dengan "B" (BPLN).</p>
                                        <span># Request</span>
                                        <div class="table mt-2 mb-4">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Parameter</th>
                                                        <th>Tipe</th>
                                                        <th class="text-center" width="120">Mandatory</th>
                                                        <th>Deskripsi</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>product_code</td>
                                                        <td>String</td>
                                                        <td class="text-center">Yes</td>
                                                        <td>Kode master produk (di dapat dari pemanggilan endpoint "Master Produk").</td>
                                                    </tr>
                                                    <tr>
                                                        <td>number</td>
                                                        <td>Numeric</td>
                                                        <td class="text-center">Yes</td>
                                                        <td>Nomor tujuan / Target nomor yang akan di cek.</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <span># Response</span>
                                        <div class="table mt-2">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Field</th>
                                                        <th>Tipe</th>
                                                        <th>Deskripsi</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>    
                                                        <td>kode_produk</td>
                                                        <td>String</td>
                                                        <td>Unique kode produk.</td>
                                                    </tr>
                                                    <tr>    
                                                        <td>nama_produk</td>
                                                        <td>String</td>
                                                        <td>Nama produk.</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="chapter" id="desc-trx">
                                        <h4>Beli Pulsa, Paket Data dan lain-lain <br/><small>Request Method : <b class="text-success">POST</b></small></h4>
                                        <div class="endpoint">
                                            <span class="title">Endpoint</span>
                                            <span class="url">trx/process</span>
                                        </div>
                                        <p>Anda dapat melakukan berbagai transaksi pembelian pulsa, paket data dan lain sebagainya melalui endpoint ini. Sebagai catatan bahwa pada endpoint ini anda di haruskan membuat callback_url transaksi yang nantinya
                                            akan di hit oleh sistem kami sebagai response balasan dari setiap transaksi. Untuk mempelajari lebih lanjut tentang "callback_url transaksi" beserta parameter yang kami kirimkan anda dapat melakukan konfigurasi melalui
                                            <router-link :to="{ name: 'credential' }">Konfigurasi Callback URL</router-link>.
                                        </p>
                                        <span># Request</span>
                                        <div class="table mt-2 mb-4">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Parameter</th>
                                                        <th>Tipe</th>
                                                        <th class="text-center" width="120">Mandatory</th>
                                                        <th>Deskripsi</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>ref_id</td>
                                                        <td>String</td>
                                                        <td class="text-center">Yes</td>
                                                        <td>Kode unique transaksi (hasil generate sistem/aplikasi client)</td>
                                                    </tr>
                                                    <tr>
                                                        <td>product_code</td>
                                                        <td>String</td>
                                                        <td class="text-center">Yes</td>
                                                        <td>Kode unique produk.</td>
                                                    </tr>
                                                    <tr>
                                                        <td>number</td>
                                                        <td>String</td>
                                                        <td class="text-center">Yes</td>
                                                        <td>Nomor tujuan / Target nomor yang akan di isi.</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <span># Response</span>
                                        <div class="table mt-2">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Field</th>
                                                        <th>Tipe</th>
                                                        <th>Deskripsi</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>    
                                                        <td>ref_id</td>
                                                        <td>String</td>
                                                        <td>Kode unique transaksi (hasil generate sistem/aplikasi client)</td>
                                                    </tr>
                                                    <tr>    
                                                        <td>kode_produk</td>
                                                        <td>String</td>
                                                        <td>Nama produk.</td>
                                                    </tr>
                                                    <tr>    
                                                        <td>no_tujuan</td>
                                                        <td>String</td>
                                                        <td>Nomor tujuan / Target nomor yang akan di isi .</td>
                                                    </tr>
                                                    <tr>    
                                                        <td>harga</td>
                                                        <td>Numeric</td>
                                                        <td>Harga produk transaksi saat ini.</td>
                                                    </tr>
                                                    <tr>    
                                                        <td>saldo</td>
                                                        <td>Numeric</td>
                                                        <td>Jumlah saldo saat ini atau sisa saldo.</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="chapter" id="desc-history">
                                        <h4>History Transaksi <br/><small>Request Method : <b class="text-success">POST</b></small></h4>
                                        <div class="endpoint">
                                            <span class="title">Endpoint</span>
                                            <span class="url">trx/history</span>
                                        </div>
                                        <p>Seluruh data transaksi yang melalui H2H akan teridentifikasi oleh sistem. Anda dapat melihat histori transaksi berdasarkan periode waktu tertentu dengan melakukan settingan parameter sesuai kebutuhan anda.  </p>
                                        <span># Request</span>
                                        <div class="table mt-2 mb-4">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Parameter</th>
                                                        <th>Tipe</th>
                                                        <th class="text-center" width="120">Mandatory</th>
                                                        <th>Deskripsi</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>pagination</td>
                                                        <td>Array</td>
                                                        <td class="text-center">No</td>
                                                        <td>Request data berdasarkan jumlah tertentu. Seperti yang anda ketahui bahwa pagination terdiri dari nilai offset dan limit. contoh parameter array : [1, 100] ( 1 - adalah nilai offset, 100 - adalah nilai limit )</td>
                                                    </tr>
                                                    <tr>
                                                        <td>start_date</td>
                                                        <td>String</td>
                                                        <td class="text-center">Yes</td>
                                                        <td>Perode tanggal awal/dimulai </td>
                                                    </tr>
                                                    <tr>
                                                        <td>end_date</td>
                                                        <td>String</td>
                                                        <td class="text-center">Yes</td>
                                                        <td>Perode tanggal ahir/sampai dengan </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="alert alert-warning mb-4">
                                            <i class="mdi mdi-information"></i> 
                                            Parameter "pagination" bersifat optional. Jika anda ingin mendapatkan keseluruhan data tanpa pagination anda dapat menghilangkan parameter "pagination" dari request.
                                        </div>
                                        <span># Response</span>
                                        <div class="table mt-2">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Field</th>
                                                        <th>Tipe</th>
                                                        <th>Deskripsi</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>    
                                                        <td>kode_trx</td>
                                                        <td>Numeric</td>
                                                        <td>Kode transaksi ( Hasil generate sistem H2H)</td>
                                                    </tr>
                                                    <tr>    
                                                        <td>ref_id</td>
                                                        <td>String</td>
                                                        <td>Kode unique transaksi (hasil generate sistem/aplikasi client)</td>
                                                    </tr>
                                                    <tr>    
                                                        <td>tanggal</td>
                                                        <td>String</td>
                                                        <td>Tanggal record transaksi</td>
                                                    </tr>
                                                    <tr>    
                                                        <td>kode_produk</td>
                                                        <td>String</td>
                                                        <td>Kode unique produk</td>
                                                    </tr>
                                                    <tr>    
                                                        <td>nama_produk</td>
                                                        <td>String</td>
                                                        <td>Nama produk pada saat transaksi</td>
                                                    </tr>
                                                    <tr>    
                                                        <td>tujuan</td>
                                                        <td>String</td>
                                                        <td>Nomor tujuan yang di cantumkan pada saat transaksi</td>
                                                    </tr>
                                                    <tr>    
                                                        <td>harga</td>
                                                        <td>Numeric</td>
                                                        <td>Harga produk dalam format currency IDR</td>
                                                    </tr>
                                                    <tr>    
                                                        <td>status</td>
                                                        <td>String</td>
                                                        <td>Status pada saat melakukan transaksi</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="chapter" id="desc-check-trx">
                                        <h4>Cek Status Transaksi <br/><small>Request Method : <b class="text-success">POST</b></small></h4>
                                        <div class="endpoint">
                                            <span class="title">Endpoint</span>
                                            <span class="url">trx/check</span>
                                        </div>
                                        <p>Untuk menunjang kebutuhan sistem kami juga menambahkan endpoint pengecekan status transaksi pada setiap request transaksi yang anda kirim. Sebagai catatan endpoint ini membutuhkan parameter ref_id. </p>
                                        <span># Request</span>
                                        <div class="table mt-2 mb-4">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Parameter</th>
                                                        <th>Tipe</th>
                                                        <th class="text-center" width="120">Mandatory</th>
                                                        <th>Deskripsi</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>ref_id</td>
                                                        <td>String</td>
                                                        <td class="text-center">Yes</td>
                                                        <td>Kode unique transaksi (hasil generate sistem/aplikasi client)</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <span># Response</span>
                                        <div class="table mt-2">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Field</th>
                                                        <th>Tipe</th>
                                                        <th>Deskripsi</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>    
                                                        <td>kode_trx</td>
                                                        <td>String</td>
                                                        <td>Kode transaksi ( Hasil generate sistem H2H)</td>
                                                    </tr>
                                                    <tr>    
                                                        <td>ref_id</td>
                                                        <td>String</td>
                                                        <td>Kode unique transaksi (hasil generate sistem/aplikasi client)</td>
                                                    </tr>
                                                    <tr>    
                                                        <td>tgl_transaksi</td>
                                                        <td>String</td>
                                                        <td>Tanggal pada saat melakukan transaksi.</td>
                                                    </tr>
                                                    <tr>    
                                                        <td>kode_produk</td>
                                                        <td>String</td>
                                                        <td>Kode unique produk.</td>
                                                    </tr>
                                                     <tr>    
                                                        <td>tujuan</td>
                                                        <td>String</td>
                                                        <td>Nomor tujuan yang di cantumkan pada saat transaksi.</td>
                                                    </tr>
                                                    <tr>    
                                                        <td>harga</td>
                                                        <td>Numeric</td>
                                                        <td>Harga produk saat melakukan transaksi.</td>
                                                    </tr>
                                                    <tr>    
                                                        <td>status</td>
                                                        <td>String</td>
                                                        <td>Status transaksi</td>
                                                    </tr>
                                                    <tr>    
                                                        <td>nama_produk</td>
                                                        <td>String</td>
                                                        <td>Nama produk saat melakukan transaksi.</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="chapter" id="desc-check-saldo">
                                        <h4>Cek Saldo Balance <br/><small>Request Method : <b class="text-danger">GET</b></small></h4>
                                        <div class="endpoint">
                                            <span class="title">Endpoint</span>
                                            <span class="url">saldo/check</span>
                                        </div>
                                        <p>Pada setiap request transaksi atau pembelian produk anda membutuhan saldo/balance. Jika saldo anda kurang dari harga produk atau tidak mencukupi maka secara auto request transaksi akan dibatalkan. 
                                            Untuk melakukan pengecekan terhadap jumlah saldo saat ini atau sisa saldo anda dapat menggunakan endpoint ini.</p>
                                        <span># Response</span>
                                        <div class="table mt-2">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Field</th>
                                                        <th>Tipe</th>
                                                        <th>Deskripsi</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>    
                                                        <td>saldo</td>
                                                        <td>Numeric</td>
                                                        <td>Jumlah saldo saat ini (Dalam format IDR).</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
    </main>
</template>

<script>
    import $ from 'jquery';
    export default {
       
        data() {
            return {
                isDownloadProcess : false,
                isFirstLoad : true
            }
        },
        created()
        {
            this.$nextTick(function(){
                this.InitializeStickySidebar();
            });
        },
        methods:
        {
            InitializeStickySidebar()
            {
                new window.StickySidebar('#doc-list', {
                    topSpacing: 70,
                    bottomSpacing: 0,
                });
            },
            GoTo(initial)
            {
                let pos = $('#'+initial).offset().top - 140;
                $('html').animate({
                    scrollTop: pos
                }, 0);
            }
        }
    }
</script>

<style lang="scss" scoped>
.end-of-book{
    position: absolute;
    bottom:-150px;
    z-index:9;
    width:97.4%;
    height:150px;
    overflow:hidden;
    background:#ffffff36;
}
.card{
    padding-bottom:200px;
    margin-bottom:-200px;
}
#doc-list{
    float:left;
    width: 100%; 
    position:relative;
    padding-top:10px;
    margin-bottom:200px;
  
    ul{
        margin:0;
        padding:0;
        li{
            float:left;
            width:100%;
            list-style:none;
            font-size:14px;
            padding:5px 0px 0px 15px;
            a{
                text-decoration:none;
                display: block;
                color:inherit;
                &:hover{
                    opacity:.5;
                }
            }
        }
        li.title{
            background-color:#f3693526;
            margin-bottom:10px;
            margin-top:10px;
            border-radius:3px;

            span.title{
                font-size:inherit;
                display:block;
            }
        }
    }
}

#doc-desc{
    position: relative;
    border-left:1px solid #dcd3d3;
    padding-left:25px;
    .chapter-title{
        float:left;
        width:100%;
        text-align:right;
        margin-top:20px;
        margin-bottom:20px;
        border-bottom:1px solid #1f1f1f;
        padding-bottom:10px;
        span{
            font-size:14px;
            font-weight: 600;
        }
        span.title{
            position:relative;
            top:4px;
            text-align:center;
            font-size:13px;
        }
    }
    .chapter{
        padding-bottom:10px;
        font-size:14px;
        h4{
            margin-bottom:15px;
            small{font-size:12px;}
        }
        p{
            a{color:red;}
        }
        span{
            font-size:inherit;
        }
        table{
            width:100%;
            font-size:13px;
            margin-bottom:20px;
            thead tr >th{
                padding:10px;
                font-weight: 600;
                font-size: inherit;
                border-top:1px solid #ababab;
                border-bottom:1px solid #ababab;
            }
            tbody tr >td{
                padding:10px;
                white-space:word-wrap !important;
                line-height: 20px;
                font-size: inherit;
                border-bottom:1px solid #ecd1c6;
            }
        }
    }

}

.endpoint{
    float: left;
    width: 100%;
    margin-bottom: 15px;
    border-radius: 20px;
    overflow: hidden;
    background-color: #ffffff3b;
    border:1px solid #6a6a6a26;
    span{
        float:left;

    }
    .title{
        padding: 5px 12px;
        background-color: #dbdbdb69;
        margin-right: 10px;
        color: #ed512a;
    }
    .url{
        position:relative;
        top:5px;
    }
}
</style>
