<template>
    <main>
        <div id="login-container">
            <div id="wave-bubble"></div>
            <div id="wave-login"></div>

            <div id="login">
                <img src="/assets/images/logo-company.svg" id="logo-company"/>

                <div class="row">
                    <div class="col-md-8">
                        <div class="desc">
                            <h3>Welcome to Sandbox</h3>
                            <p>Anda dapat melakukan pengujian unit testing, konfigurasi, serta monitoring transaksi. Pengelolaan produk yang terintegrasi dengan sistem H2H agar proses transaksi dapat berjalan dengan lancar.</p>
                            <p>Dapatkan experience development pada environment staging dan production. Expandable bisnis dan transaksi anda dengan selalu terintegrasi bersama Dflash. </p>

                            <div class="cube">
                                <div class="shadow"></div>
                                <div class="infont"><i class="mdi mdi-cube-outline"></i>H2H</div>
                            </div>
                        </div>
                        <div class="browser">
                            <label>Best browser experience</label>
                            <ul>
                                <li><img src="assets/images/browser/chrome.png"></li>
                                <li><img src="assets/images/browser/firefox.png"></li>
                                <li><img src="assets/images/browser/safari.png"></li>
                            </ul>
                            <small>Recommended highly use "Chrome"</small>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div id="form">
                            <form @submit="SubmitLogin">
                                <h3 class="fw-light text-center">Login</h3>
                                <span><i class="mdi mdi-info text-danger"></i>Silahkan masukan akun yang telah di daftarkan sebagai akun H2H.</span>
                                <div class="mt-2">
                                    <label >No Telepon Terdaftar</label><br/>
                                    <input class="form-control" id="inputPhone" placeholder="..." type="text" v-model="model.phonex" />
                                    <small class="text-danger" v-if="error_msg.handphone">! {{ error_msg.handphone[0] }}</small>
                                </div>
                                <div class="mt-2">
                                    <label  for="inputPassword">ID Reseller</label>
                                    <input class="form-control" id="inputPassword" type="text" placeholder="..." v-model="model.id_reseller" />
                                    <small class="text-danger" v-if="error_msg.id_reseller">! {{ error_msg.id_reseller[0] }}</small>
                                </div>
                                <div class="mt-2">
                                    <label  for="inputPassword">Kode Pin</label>
                                    <input class="form-control" id="inputPassword" type="password" placeholder="****" v-model="model.pincode" />
                                    <small class="text-danger" v-if="error_msg.pincode">! {{ error_msg.pincode[0] }}</small>
                                    <small class="text-danger" v-if="error_msg.password">! {{ error_msg.password[0] }}</small>
                                </div>
                                <hr/>
                                <div class="text-center mt-3 mb-0">
                                    <ButtonProcess :classes="'btn btn-full btn-sm btn-warning'" :submit_state="isSubmitProcess" :type="'Submit'" :title="'CONTINUE LOGIN'" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <footer class="login-footer" >
                <span>Copyright &copy; 2023 Dflash - All Rights Reserved.</span>
            </footer>
            
        </div>
    </main>
</template>
<script>

    export default 
    {
        data() {
            return {
                error_msg : {},
                model :{  phonex : '', id_reseller : '', pincode : '' },
                isSubmitProcess  : false,
            }
        },
        created(){
            setTimeout(() => {
                window.init();
            }, 1000);
        },
        methods:
        {
           
            async SubmitLogin(e) 
            {
                e.preventDefault();
                this.error_msg = {};
                this.isSubmitProcess = true;
                
                this.model.handphone = this.model.phonex;
                if( this.model.phonex !== '' )
                {
                    let phone = this.model.phonex;

                    if( phone.charAt(0) == 6 && phone.charAt(1) == 2 ){
                        this.model.handphone = '+'+phone;
                    }else{
                        if( phone.charAt(0) == 0 ){
                            this.model.handphone = '+62'+phone.substring(1);
                        }else{
                            this.model.handphone = phone;
                        }
                    }
                }
                
                const req = await this.ServiceAuthenticateLogin(this.model);
                if(req)
                {
                    if( req.result )
                    {
                       this.configuration();
                    }else{
                        this.isSubmitProcess = false;
                        this.error_msg = req.msg;
                    }
                }
            },
            async configuration()
            {
                const req = await this.ServiceApiPost({url:'auth/initialize', data:{
                    user_id     : localStorage.getItem('user-id'),
                }});
                if( req && req.result )
                {
                    let model = req.query;

                    localStorage.setItem('server-date', JSON.stringify(model.date));
                    localStorage.setItem('notification_sound', model.notification);

                    localStorage.setItem('user-name', model.name);
                    localStorage.setItem('user-photo', model.photo_owner );
                    localStorage.setItem('user-privileges-name', model.privileges_name);
                    localStorage.setItem('enable-app', 'ready');

                    setTimeout(() => {
                       window.location.reload();
                    }, 1000);

                }else{
                    this.task_progress = 100;
                    this.task_status = 'Permission Denied !!, You dont have any access to application. Please contact administrator! ';
                }   
            },
        }
    }
</script>

<style lang="scss" scoped>
 #logo-company{
    width: 165px;
    position: absolute;
    top: -82px;
    left: 82px;
}
#login-container{
    width:100%;
    height:100%;
    float:left;
    position: absolute;
    background: linear-gradient(105.2deg, rgb(255, 78, 78) 11.2%, rgb(253, 176, 71) 117.9%);
    font-family :'Poppins Light';

    #wave-login{
        width: 100%;
        height: 150px;
        background: url(/assets/images/wave3.svg) no-repeat;
        background-size: cover;
        position: absolute;
        bottom:0;
    }
    #wave-bubble{
        width: 300px;
        height: 190px;
        background: url(/assets/images/laravel-footer-logo.png) no-repeat;
        background-size: contain;
        position: absolute;
        top: 55px;
        left: -60px;
        rotate: 90deg;
        }

    #login{
        position:relative;
        top:20%;
        color:#fff;
       
        .desc, .browser{
            width:80%;
            padding:20px 50px;
            position:relative;
            left:0;
            right:0;
            margin:0 auto;
            text-align:center;
        }
        .desc{
           
            h3{margin-bottom:30px;}
            p{
                font-size: 14px;
            }
            

            .cube {
                position: relative;
                .shadow {
                    display: block;
                    position: absolute;
                    z-index: 1;
                    left:0;
                    right:0;
                    margin:0 auto;

                    width:92px;
                    height:92px;
                    background: linear-gradient(45deg,red,blue,green,yellow,#e11d74,black);
                    background-size: 400%;
                    border-radius: 5px;
                    animation: animate 35s linear infinite;
                }

               
                .infont{
                    padding:10px 20px;
                    width:90px;
                    height:90px;
                    position: relative;
                    z-index: 2;
                    left:0;
                    right:0;
                    top:1px;
                    margin:0 auto;
                    margin-top:30px;
                    margin-bottom:30px;
                    background-color: #1a191f;
                    position:relative;
                    font-size:14px;
                    border-radius: 5px;

                    i{
                        position:relative;
                        font-size:32px;
                        color:#ff4500;
                    }
                }
            }

        }
        .browser{
            top:-30px;
            ul{
                padding:2px 3px;
                position:relative;
                left:0;
                right:0;
                margin:0 auto;
                margin-bottom:10px;
                width:111px;
                height:40px;
                background-color:#ffffff3b;
                border-radius:25px;
                li{
                    list-style: none;
                    float:left;
                    padding:5px;
                    text-align:center;
                    img{
                        width:25px;
                    }
                }
            }
        }
        #form{
            width:350px;
            height:auto;
            padding:30px;
            background-color:#ffffff0f;
        }
      
    }
}


.login-footer{
    position:absolute;
    bottom:10px;
    left:0;
    right:0;
    margin:0 auto;
    width:100%;
    text-align:center;
    span{
        color:#ffcfcf;
        font-size:12px;
    }
}


@keyframes animate {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

</style>