<template>
    <div class="popup-modal">
      
        <div class="modal-dialog modal-lg" >
            <div class="modal-content">
                <!-- filter table -->
                <div class="" v-if="isEnableFilter">
                    <div class="slide-right-filter-modal">
                        <div class="box-sorting">
                            <label><i class="mdi mdi-information-outline text-warning"></i> Ordering <br/><small class="text-secondary">Urutkan berdasarkan ascending atau descending.</small></label>
                            <div class="form-group">
                                <select class="form-control" v-model="mixmeta_data.okey">
                                    <option value="users.id">ID</option>
                                    <option value="customer_register.status">Status Member</option>
                                    <option value="users.name">Nama</option>
                                    <option value="customer_register.level_code">Level</option>
                                    <option value="privileges.name">Privileges Access</option>
                                    <option value="users.created_at">Tgl.Dibuat</option>
                                </select>
                                <div class="sort">
                                    <a href="javascript:;" :class="mixmeta_data.oval == 'desc' ? 'active float-left' : 'float-left'" @click="mixmeta_data.oval = 'desc'"><i class="mdi mdi-sort-descending"></i></a>
                                    <a href="javascript:;" :class="mixmeta_data.oval == 'asc' ? 'active float-right' : 'float-right'"  @click="mixmeta_data.oval = 'asc'"><i class="mdi mdi-sort-ascending"></i> </a>
                                </div>
                            </div>
                            <br/>
                            <br/>
                        </div>
                        <div class="box-search">
                            <label><i class="mdi mdi-information-outline text-warning"></i> Pencarian Data <br/><small class="text-secondary">Masukan keyword pencarian data.</small></label>
                            <div class="form-group">
                                <select class="form-control" v-model="mixmeta_data.skey">
                                    <option value=""> -- Target Kolom Pencarian --</option>
                                    <option value="users.name">Nama</option>
                                    <option value="users.code_otmx_reseller">Kode Reseller</option>
                                    <option value="customer_register.level_code">Level</option>
                                    <option value="users.created_at">Tgl.Dibuat</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <input type="date" class="form-control"  v-model="mixmeta_data.sval" v-if="mixmeta_data.skey == 'created_at' || mixmeta_data.skey == 'updated_at'"/>
                                <input type="search" class="form-control"  v-model="mixmeta_data.sval" placeholder="Masukan Keyword ...." v-else/>
                            </div>
                            <hr/>
                            <ButtonProcess :classes="'btn btn-full btn-sm btn-warning'"  :type="'Submit'" :title="'Terapkan'" @click="DataQuery()"/>
                        </div>
                    </div>
                    <div class="slide-center-info-modal ">
                        <span class="exit" @click="isEnableFilter = false"><i class="mdi mdi-close" ></i></span>
                    </div>
                </div>
                <!-- end filter table -->
                <div class="modal-header">
                    <h5 class="modal-title">Reseller / Sales <br/> <small class="text-secondary">Tekan "Pilih" untuk melakukan import data.</small></h5>
                    <a href="javascript:;" class="nav-module link-close" @click="ClosePopup()"><i class="mdi mdi-close mdi-icon"></i> </a>
                    <a href="javascript:;" class="nav-module link-filter" @click="isEnableFilter=true"><i class="mdi mdi-menu mdi-icon"></i> </a>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <hr/>
                            <div class="table-info">
                                <div class="float-right">
                                    <ul>
                                        <li>
                                            <i :class="mixmeta_data.oval == 'desc' ? 'mdi mdi-sort-descending' : 'mdi mdi-sort-ascending'"></i> <span>{{ $filters.capitalize( mixmeta_data.oval )  }}</span>
                                        </li>
                                        <li class="border-left">
                                            <i class="mdi mdi-refresh" v-if="! $store.state.loading.status" @click="DataQuery()"></i>
                                            <div class="spinner-border  spinner-border-sm" role="status" v-else></div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <SystemConnection :connection="service_connection"  v-if="isFirstLoad" />
                            <div class="table-cover scrollbox" v-else>
                                <table :class="isRefreshTable ? 'table-load' : '' ">
                                    <thead>
                                        <tr>
                                            <th>Action</th>
                                            <th class="text-center">Photo</th>
                                            <th :class="mixmeta_data.okey == 'users.code_otmx_reseller' ? 'active' : ''">Kode Reseller</th>
                                            <th :class="mixmeta_data.okey == 'customer_register.level_code' ? 'active' : ''">Level</th>
                                            <th :class="mixmeta_data.okey == 'users.name' ? 'active' : ''">Name & Owner</th>
                                            <th data-filter="sales_area.created_at">Created</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="mixmodel.total > 0">
                                        <tr v-for="(items, idx) in mixmodel.data" :key="idx">
                                            <td width="80">
                                                <a href="javascript:;" class="btn btn-secondary" @click="Choose(idx)">Pilih User</a>
                                            </td>
                                            <td width="100" class="text-center">
                                                <ImageSmCircle :photo="items.photo_owner" v-if="items.photo_owner !== null"/>
                                                <div class="avatar avatar-xl" v-else>
                                                    <img class="avatar-img img-fluid" src="assets/img/avatar.png" />
                                                </div>
                                            </td>
                                            <td>{{ items.code_otmx_reseller }}</td>
                                            <td>{{ items.level_code == null ? '--'  : items.level_code }}</td>
                                            <td width="280">
                                                {{ $filters.ucwords(items.name) }} <br/> <span class="text-secondary">{{ $filters.ucwords(items.owner_name) }}</span>
                                            </td>
                                            <td width="100">{{ $filters.formatDate(items.created_at, 'DD/MM/YYYY') }}<br/><small class="text-secondary">{{ $filters.formatDate(items.created_at, 'LT') }}</small></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <v-pagination
                            v-if="mixmodel.total > 0"
                            v-model="page"
                            :class="'pagination'"
                            :pages="parseInt(mixmodel.last_page)"
                            :range-size="1"
                            active-color="#ffffff"
                            @update:modelValue="DataQuery"
                        />
                    </div>
                </div>
               
            </div>
        </div>
    </div>

</template>
<script>
    export default 
    {   
        props :['data_model'],
        data() {
            return {
                isFirstLoad : true,
                isEnableFilter : false,
            };
        },
        created()
        {
            this.mixmeta_data.okey = 'users.id';
            this.mixmeta_data.oval = 'desc';
            this.ServiceInitFunction('DataQuery', 's-table');
        },
        methods: 
        {
          
            ClosePopup()
            {
                this.$emit('closePopup');
            },
            Choose(idx){
                let model = this.mixmodel.data[idx];
                this.$emit('closePopup', model);
            },
            async DataQuery(pageNum)
            {
                this.isEnableFilter = false;
                this.isRefreshTable = true;
                this.page = pageNum == undefined ? 1 : pageNum;
                let res = await this.ServiceApiDataPaging({
                    url:'reseller-data', 
                    offset : this.page,
                    search: { key : this.mixmeta_data.skey, value : this.mixmeta_data.sval },
                    order: { key : this.mixmeta_data.okey, value : this.mixmeta_data.oval },
                });
                if(res){ 
                    this.isFirstLoad = false;
                    this.isRefreshTable = false;
                    this.mixmodel = res.query; 
                }
            }
        }
    }
</script>
