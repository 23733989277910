<template>
    <div>
        <label>Kategori Produk <small class="text-danger" v-if="mode == 'form'">*</small></label>
        <select  class="form-control" v-model="selected"  @change="ChooseData()" >
            <option value="all">-- Semua Kategori --</option>
            <option v-for="(items, idx) in model" :key="idx" :value="items.id">{{ $filters.ucwords(items.product_type) }} - {{ items.category_name }}</option>
        </select>
    </div>
</template>
<script>
export default {
    name: 'SelectProductCategory',
    props : ['mode', 'id', 'type'],
    
    data() {
        return {
            selected : 'all',
            model : []
        }
    },
    created()
    {
        this.DataQuery();
    },
    methods:
    {
        async DataQuery()
        {
            const res = await this.ServiceApiPost({
                url:'product_category/data/all',
                data :{
                    filter : [
                        {key : 'product_category.product_type', val : ['digital', 'tagihan']}
                    ]
                }
            });
            if(res && res.result){ 
                this.model = res.query; 
                if( this.id ){
                    this.selected = this.id;
                }
            }
        }, 
        ChooseData()
        {
            if(this.selected !== 'all'){

                let data_model = {};
                for(let key in this.model)
                {
                    if( this.model[key].id == this.selected ){
                        data_model = this.model[key];
                    }
                }    
                this.$emit('ChooseData', data_model);
            }else{
                this.$emit('ChooseData', 'all');
            }
        }
    }
}
</script>