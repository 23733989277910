<template>
    <main>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Contact Us
                    </h1>
                    <div class="page-header-subtitle text-secondary">Anda dapat mengirimkan kami email jika ada kendala atau pertanyaan teknis melalui pengisian form di bawah ini.</div>
                </div>
            </div>
        </header>
        <hr/>
        <div class="row">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-body">
                        <form @submit="Submit">
                            <div class="row" >
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label><small class="text-danger">*</small>  Pilihan Topic</label>
                                        <select class="form-control">
                                            <option value="">Kendala Teknis API</option>
                                            <option value="">Mengenai Produk</option>
                                            <option value="">Transaksi</option>
                                            <option value="">Deposit dan Balance</option>
                                            <option value="">Lainya</option>
                                        </select>
                                        <small class="text-danger" v-if="service_form_error.subject">{{ service_form_error.subject[0] }}</small>
                                    </div>
                                    <div class="form-group">
                                        <label><small class="text-danger">*</small> Subject</label>
                                        <input type="text" class="form-control" v-model="model.subject"/>
                                        <small class="text-danger" v-if="service_form_error.subject">{{ service_form_error.subject[0] }}</small>
                                    </div>
                                    <div class="form-group">
                                        <label><small class="text-danger">*</small>  Message / Pesan <small class="text-danger">*</small></label>
                                        <QuillEditor  theme="snow" output="html" style="height: 400px; background-color:#fff;"/>
                                        <small class="text-danger" v-if="service_form_error.text">{{ service_form_error.text[0] }}</small>
                                    </div>
                                </div>
                            </div> 
                            <hr/>
                            <div class="mt-4 pb-5">
                                <span class="float-left"><i class="mdi mdi-information-outline text-danger"></i> Mohon periksa kembali subject dan isi pesan sebelum di kirim.</span>
                                <ButtonProcess :classes="'btn btn-sm btn-warning float-right'" :submit_state="isSubmitProcess" :type="'Submit'" :title="'Kirim Pesan'"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!--<div id="bg-faq-mail"></div>-->
    </main>
</template>


<script>
    import { QuillEditor } from '@vueup/vue-quill'
    import '@vueup/vue-quill/dist/vue-quill.snow.css';
    import $ from 'jquery';
    export default {
        components: {
            QuillEditor
        },
        data() {
            return {
                isSubmitProcess : false,
                model :{subject: '', text:''}
            }
        },
        methods:
        {
            async Submit(e) 
            {
                e.preventDefault();
                this.isSubmitProcess = true;

                this.model.text = $('.ql-editor').html();
                this.isSubmitProcess = true;
                let res = await this.ServiceApiPost({ action : 'post', url: 'news/store', data :this.model, callback_url : 'news-data' });
                if(res){
                    this.isSubmitProcess = false;
                }
            }
        }
    }
</script>


<style lang="scss" scoped>

#bg-faq-mail{
    position: relative;
    display:block;
    z-index: 0;
    left: 0;
    bottom:-12em;
    width: 380px;
    height: 230px;
    background:url('/assets/images/bg-email.webp')no-repeat;
    background-size:cover;
    opacity:.20;

}
</style>