import { createWebHistory, createRouter } from "vue-router";

import Errors404 from '../application/Errors404';

import Dashboard from "../application/Dashboard";
import NotificationSound from '../application/NotificationSound';
import BalanceData from '../application/Balance/Data';
import BalanceStore from '../application/Balance/Store';
import BalanceConfirm from '../application/Balance/Confirm';
import Credential from '../application/Integrasi/Credential';
import LogReequest from '../application/Integrasi/Log';
import HttpCode from '../application/Integrasi/HttpCode';
import Profile from "../application/Profile";
import Documentation from '../application/Documentation';
import Transaction from '../application/Transactions/Transaction';
import Faq from '../application/Faq';
import EmailUs from '../application/EmailUs';
import ProductDigitalPrice from '../application/ProductDigital/Product';
import ReportTransaction from "../application/Report/Transaction";
import DetailPromo from "../application/DetailPromo";




const routes = [
    {
        path : '/404',
        name : '404',
        component : Errors404,
    },
    {
        path : '/',
        name : 'dashboard',
        component : Dashboard,
    },
    {
        path : '/notification-sound',
        name : 'notification-sound',
        component : NotificationSound,
    },
 
    {
        path : '/balance-data',
        name : 'balance-data',
        component : BalanceData,
    },
    {
        path : '/balance-store',
        name : 'balance-store',
        component : BalanceStore,
    },
    {
        path : '/balance-confirm',
        name : 'balance-confirm',
        component : BalanceConfirm,
    },
    {
        path : '/credential',
        name : 'credential',
        component : Credential,
    },
    {
        path : '/log',
        name : 'log',
        component : LogReequest,
    },
    {
        path : '/httpcode',
        name : 'httpcode',
        component : HttpCode,
    },
    {
        path : '/doc',
        name : 'doc',
        component : Documentation,
    },
    {
        path : '/profile',
        name : 'profile',
        component : Profile,
    },
    {
        path : '/transaction',
        name : 'transaction',
        component : Transaction,
    },
    {
        path : '/product',
        name : 'product',
        component : ProductDigitalPrice,
    },
   
    
    {
        path : '/report-transaction',
        name : 'report-transaction',
        component : ReportTransaction,
    },
    {
        path : '/detail-promo',
        name : 'detail-promo',
        component : DetailPromo,
    },
    {
        path : '/faq',
        name : 'faq',
        component : Faq,
    },
    {
        path : '/email-us',
        name : 'email-us',
        component : EmailUs,
    }
];


const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;