export default {

 
    methods: 
    {
        ServicePrint()
        {
            var printContents = document.getElementById('data-print').innerHTML;
            var originalContents = document.body.innerHTML;
            document.body.innerHTML = printContents;
            window.print();
            document.body.innerHTML = originalContents;
        },
        
    },
};