import ButtonProcess from './parts/ButtonProcess';
import ImageMdCircle from './parts/ImageMdCircle';
import ImageSmCircle from './parts/ImageSmCircle';
import UploadSingleImage from './parts/UploadSingleImage';
import UploadSingleVideo from './parts/UploadSingleVideo';
import LoaderBar from './parts/LoaderBar';
import GoogleMap from './parts/GoogleMap';
import LeafletMap from './parts/LeafletMap';
import SystemConnection from './parts/SystemConnection';
import ColorPicker from './parts/ColorPicker';
import Loader from './parts/Loader';
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import TableOrdering from './parts/TableOrdering';


// Api
import Notification from './api/Notification';
import SelectProductCategory from "./api/SelectProductCategory";
import SelectProductSubCategory from "./api/SelectProductSubCategory";
import SummaryProductCategory from "./api/SummaryProductCategory";


export default [
    SystemConnection,
    SelectProductCategory,
    SelectProductSubCategory,
    VPagination,
    ButtonProcess,
    ImageMdCircle,
    ImageSmCircle,
    UploadSingleImage,
    UploadSingleVideo,
    LoaderBar,
    GoogleMap,
    LeafletMap,
    ColorPicker,
    Loader,
    Notification,
    SummaryProductCategory,
    TableOrdering
];