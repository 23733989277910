<template>
    <div class="block-screen">
        <lottie-player src="assets/img/lotties/404.json" background="transparent"  speed="0.5" class="lottie-processing lottie-404" loop  autoplay></lottie-player>
        <h5>Opps, Sorry page not found !<br/>  <span class="text-secondary">Please contact web administrator</span></h5>
    </div>
</template>

<style lang="scss" scoped>
.block-screen{
    position:relative;
    z-index: 9;
    left:0px;
    top:0px;
    text-align:center;
    background-color:#ecf0f1;
    width:100%;
    height:100%;
    margin:auto;
    
   
    .lottie-404{
        position:relative;
        width:400px;
        margin:0 auto;
    }
    h5{
        position:relative;
        top:-120px;
        font-weight:400;
        span{
            font-weight:400;
        }
    }
   
}
</style>