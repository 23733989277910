<template>
    <main>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Konfigurasi 
                    </h1>
                    <div class="page-header-subtitle text-secondary">Data informasi credential akses, konfigurasi dan direct notifikasi.</div>
                </div>
            </div>
        </header>
        <hr/>
        <form @submit="Submit">
            <div class="row">
                <div class="col-xl-12">
                    <div class="card p-4">
                        <div >
                            <label><i class="mdi mdi-information-outline text-warning"></i> Setting Notifikasi </label>
                            <div class="box-data">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label >Bunyi Notifikasi</label>
                                        <p>Sistem dflash akan mengirimkan informasi yang di butuhkan melalui notifikasi pada dashboard. Silahkan pilih salah satu bunyi notifikasi favorit anda. </p>
                                    </div>
                                    <div class="col-md-6">
                                        <div id="avatar-list">
                                            <div class="scrollx">
                                                <ul>
                                                    <li v-for="(items, idx) in sound" :key="idx">
                                                        <i class="icon-check mdi mdi-checkbox-marked-circle" v-if="isActiveSound == items"></i>
                                                        <a href="javascript:;" @click="ChangeSound(items)">
                                                            <div class="picture">
                                                                <i :class="'mdi mdi-bell-circle-outline rt'+idx"></i>
                                                            </div>
                                                            <small>{{ items }}</small>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="box-data mt-2">
                            <div class="float-left title-mini">
                                <label >Topup</label>
                                <p class="text-secondary">Sistem akan mengirimkan notifikasi melalui email setiap kali anda topup.</p>
                            </div>
                            <div class="float-right mt-1">
                                <label class="switch">
                                    <input type="checkbox" id="topup" :checked="ValidateChecked('topup')"/>
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div>
                        <!-- <div class="box-data">
                            <div class="float-left  title-mini">
                                <label >Saldo Balance </label>
                                <p class="text-secondary">Notifikasi akan dikirimkan melalui email pada saat saldo balance anda dibawah/sama dengan nilai minimum.</p>
                            </div>
                            <div class="float-right mt-1">
                                <label class="switch">
                                    <input type="checkbox" id="balance" :checked="ValidateChecked('balance')"/>
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div> -->
                        <!--<div class="box-data">
                            <div class="float-left  title-mini">
                                <span>Download</span><br/>
                                <small class="text-secondary">Sistem akan mengirimkan hasil download data ke alamat email.</small>
                            </div>
                            <div class="float-right mt-1">
                                <label class="switch">
                                    <input type="checkbox" id="download" :checked="ValidateChecked('download')"/>
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div>-->
                        <div class="box-data">
                            <div class="float-left  title-mini">
                                <label>Contract Expiry</label><br/>
                                <p class="text-secondary">Sistem akan mengirimkan notifikasi ke alamat email <b class="text-danger">30 Hari</b> sebelum masa kontrak habis.</p>
                            </div>
                            <div class="float-right mt-1">
                                <label class="switch">
                                    <input type="checkbox" id="contract" :checked="ValidateChecked('contract')"/>
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div class="card mb-4">
                        <div class="card-body">
                            <div class="box-data mb-2 mt-2">
                                <label><i class="mdi mdi-information-outline text-warning"></i> API Credential Akses</label>
                            </div>
                            <div class="box-data">
                                <span class="text-secondary">Base URL</span>
                                <div class="float-right loading-dots" v-if="isFirstLoad"><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div></div>
                                <span class="float-right" v-else>{{ model.endpoint_url }} <a href="javascript:;" class="copy" title="Copy" @click="CopyClipboard('Endpoint Url',model.endpoint_url)"><i class="mdi mdi-content-copy"></i></a></span>
                            </div>
                            <div class="box-data">
                                <span class="text-secondary">Client ID</span>
                                <div class="float-right loading-dots" v-if="isFirstLoad"><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div></div>
                                <span class="float-right" v-else>{{ model.app_id }} <a href="javascript:;" class="copy" title="Copy" @click="CopyClipboard('Client ID',model.app_id)"><i class="mdi mdi-content-copy"></i></a></span>
                            </div>
                            <div class="box-data">
                                <span class="text-secondary">Client Secret</span>
                                <div class="float-right loading-dots" v-if="isFirstLoad"><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div></div>
                                <span class="float-right" v-else>{{ model.api_secret }} <a href="javascript:;" class="copy" title="Copy" @click="CopyClipboard('Client Secret',model.api_secret)"><i class="mdi mdi-content-copy"></i></a></span>
                            </div>
                            <div class="box-data  mt-2">
                                <label><i class="mdi mdi-information-outline text-warning"></i> Direct Notifikasi <small class="text-danger">(*)</small></label>
                                <p class="text-secondary">Masukan alamat email agar sistem dapat mengirimkan berbagai notifikasi dan informasi yang diperlukan.</p>
                                <div class="">
                                    <span class="text-secondary">Contoh : jhondoe@gmail.com</span>
                                    <div class="float-right">
                                        <input type="email" class="form-control" placeholder="admin@gmail.com" v-model="model.email"/>
                                        <small class="text-danger" v-if="service_form_error.email">{{ service_form_error.email[0] }}</small>
                                    </div>
                                </div>
                            </div>
                            <div class="box-data  mt-2" v-if="env !== 'staging'">
                                <label><i class="mdi mdi-information-outline text-warning"></i> IP Whitelist  <small class="text-danger">(*)</small></label>
                                <p>IP Whitelist bersifat wajib diisi untuk integrasi otentikasi pada environment production. Jika anda melakukan perubahan pada konfigurasi IP Server silahkan sesuaikan alamat IP Address yang akan di integrasikan dengan sistem dflash.</p>
                                <div class="">
                                    <span class="text-secondary">Masukan IP Address</span>
                                    <div class="float-right">
                                        <input type="text" class="form-control" placeholder="..." v-model="model.ip_whitelist"/>
                                        <small class="text-danger" v-if="service_form_error.ip_whitelist">{{ service_form_error.ip_whitelist[0] }}</small>
                                    </div>
                                </div>
                            </div>
                            <div class="box-data  mt-2">
                                <label><i class="mdi mdi-information-outline text-warning"></i> URL Callback Transaksi <small class="text-danger">(*)</small></label>
                                <p>URL Callback merupakan endpoint yang akan terintegrasi dengan sistem dflash. Sistem kami akan melakukan hit pada URL callback yang telah anda setting untuk mengirimkan data hasil response dari settiap transaksi yang anda lakukan. </p>
                                <div class="">
                                    <span class="text-secondary">Contoh URL Callback Anda : </span>  https://example.com/trx_callback <br/>
                                    <span>Maka sistem Dflash akan melakukan hit ke url callback anda dengan parameter berikut : https://example.com/trx_callback?refid=xxx&status=xxx&harga=xxx</span>
                                    
                                    <div class="float-right mt-3">
                                        <input type="text" class="form-control" placeholder="..." v-model="model.callback_url"/>
                                        <small class="text-danger" v-if="service_form_error.callback_url">{{ service_form_error.callback_url[0] }}</small>
                                    </div>
                                </div>
                                <div class="mt-3">
                                    <span>- HIT Method : ( GET ).</span><br/>
                                    <span>- Parameter yang di kirimkan.</span>
                                    <pre>
{
    "refid": "xxx",
    "status": "xxx",
    "harga": xxx
}
                                    </pre>
                                </div>
                            </div>
                           <!--  <div class="box-data  mt-2">
                                <label><i class="mdi mdi-information-outline text-warning"></i>  Saldo Balance</label>
                                <p>Masukan minimum saldo balance sebagai reminder. Sistem akan mengirimkan notifikasi jika saldo balance anda dibawah atau sama dengan nilai minimum.</p>
                                <div class="">
                                    <span class="text-secondary pt-2">Balance ( <b class="text-success">Rp</b> )</span>
                                    <div class="float-right">
                                        <input type="number" class="form-control" placeholder="0" v-model="model.minimum_balance"/>
                                    </div>
                                </div>
                            </div> -->
                            
                            <!-- <div class="box-data  mt-2 mb-1">
                                <label><i class="mdi mdi-information-outline text-warning"></i> Limit Private Akun</label>
                                <p>Batasi akun login anda sesuai dengan kebutuhan. Sistem akan melakukan validasi terhadap jumlah akun yang dapat login dalam dashboard pada sesi yang sama.</p>
                                <div class="">
                                    <span class="text-secondary">Tipe Otentikasi Login</span>
                                    <div class="float-right">
                                        <select class="form-control" v-model="model.login_type">
                                            <option value="single">Single</option>
                                            <option value="multiple">Multiple</option>
                                        </select>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            <div class="from-group mt-4">
                <div class="float-left">
                    <i class="mdi mdi-information-outline text-warning"></i> <span class="text-secondary">Periksa kembali konfigurasi data sebelum disimpan. Pastikan anda mengisi dengan benar.</span>
                </div>
                <div class="float-right">
                    <ButtonProcess :classes="'btn btn-sm btn-warning '" :submit_state="isSubmitProcess" :type="'Submit'" :title="'Simpan Konfigurasi'" />
                </div>
            </div>
        </form>
    </main>
</template>

<script>
    import $ from 'jquery';
    export default 
    {   
        data() {
            return {
                isSubmitProcess : false,
                isFirstLoad : true,
                isActiveSound : '',
                model:{},
                env : localStorage.getItem('is_enable_env'),
                sound : ['default', 'buzzer', 'chuko', 'definite', 'demons', 'early', 'morning', 'happy', 'little', 'loving', 'obey']
            };
        },
        created()
        {
            this.DataQuery();
        },
        methods: 
        {
            async CopyClipboard(title, string)
            {    
                await navigator.clipboard.writeText(string);
                alert(title + ' telah di copy');
            },
            ValidateChecked(initial)
            {
                if(initial == 'topup'){
                    return this.model.notif_mail_topup == 'yes' ? true : false;
                }
                if(initial == 'balance'){
                    return this.model.notif_mail_balance == 'yes' ? true : false;
                }
                if(initial == 'download'){
                    return this.model.notif_download == 'yes' ? true : false;
                }
                if(initial == 'contract'){
                    return this.model.notif_contract_expiry == 'yes' ? true : false;
                }
            },
            async ChangeSound(path)
            {
                var sys_audio  = document.getElementById('sys-audio');
                var source_mp3 = document.getElementById('source-mp3');
                source_mp3.src = '/sound/'+path+'.mp3';

                this.isActiveSound = path;
                sys_audio.load();
                sys_audio.play();   
            },
            async DataQuery()
            {
                let req = await this.ServiceApiPost({url: 'h2h/'+this.env+'/user/config', data:{freseller : localStorage.getItem('user-code')}});
                if(req && req.result)
                {
                    this.model = req.query;
                    this.isFirstLoad = false;

                    // notification sound
                    let expl_sound = this.model.notification_sound;
                    expl_sound = expl_sound.split('.mp3');
                    this.isActiveSound = expl_sound[0].replace('/sound/', '');
                }
            },
            async Submit(e) 
            {
                e.preventDefault();
                this.isSubmitProcess = true;

                this.model.is_required_mail = false;
                this.model.notif_mail_topup = 'no';
                this.model.notif_mail_balance = 'no';
                this.model.notif_download = 'no';
                this.model.notif_contract_expiry = 'no';

                if($("#topup").is(':checked')){this.model.notif_mail_topup = 'yes'; this.model.is_required_mail = true; }
                if($("#balance").is(':checked')){this.model.notif_mail_balance = 'yes'; this.model.is_required_mail = true; }
                if($("#download").is(':checked')){this.model.notif_download = 'yes'; this.model.is_required_mail = true; }
                if($("#contract").is(':checked')){this.model.notif_contract_expiry = 'yes'; this.model.is_required_mail = true; }
                this.model.notification_sound =  '/sound/'+this.isActiveSound+'.mp3';

                let res = await this.ServiceApiPost({ action : 'post', url: 'h2h/'+this.env+'/user/config-update', data :this.model });
                if( res  ){
                    this.isSubmitProcess = false;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
pre{
   
    background-color: #ffffff33;
    padding: 14px 0px 0px 16px;
    margin-top: 10px;
}
.btn{
    width:200px;
}
.box-data{
    float:left;
    width:100%;
    padding:10px;
    border-bottom:1px solid #fcfcfc59;
    p{
        font-size:13px;
        color:#3f3f3f;
    }
    input[type="text"], input[type="email"], input[type="number"], select{
        width:300px;
    }
    .title-mini{
        width:80%;
    }
    .bg-credential{
        padding:5px;
        background-color:#ffe0d19f;
        border-radius:3px;
    }
    .copy{
        font-size:16px;
        color:#ec432e;
        margin-left:5px;
    }
    .copy:hover{
        color:#f06d21;
    }
}
.box-data:last-child{
    border-bottom:none;
}
.pic-box{
    width:100%;
    height:174px;
    overflow:hidden;
    text-align:center;
    border:1px solid #ffffff8d;
    border-radius:3px;
    padding:5px;
    img{
        height:100%;
    }
}

#avatar-list{
    width: 100%;
    height: 85px;
    padding: 6px 10px 0px 10px;
    overflow-x: scroll;
    background-color: #ffffff5e;
    border-radius:10px;
    div.scrollx{
        width:600px;
        height:70px;
        ul{
            margin:0;
            padding:0;
            width:auto;
            li{
                position:relative;
                list-style:none;
                float:left;
                margin:0px;
                padding:5px;
                text-align:center;
                .spinner-border{
                    position:absolute;
                    z-index: 2;
                    font-size:20px;
                    top:2px;
                    left:11px;
                }
                .icon-check{
                    position:absolute;
                    z-index: 2;
                    font-size:20px;
                    top:-5px;
                    left:11px;
                    color:#00a317;
                }
                a{
                    display:block;
                    color:#353535;
                    .picture{
                        position: relative;
                        z-index: 1;
                        width:43px;
                        height:43px;
                        border-radius:50%;
                        overflow:hidden;
                        background-color:#fff;
                        text-align:center;
                        i{
                            font-size:35px;
                            position:relative;
                            top:-10px;
                        }
                        i.rt0{color:#ec4b2b91;}
                        i.rt1{color:#ec982b91;}
                        i.rt2{color:#ecd52b91;}
                        i.rt3{color:#afec2b91;}
                        i.rt4{color:#2bec6891;}
                        i.rt5{color:#2becb591;}
                        i.rt6{color:#2bdfec8a;}
                        i.rt7{color:#2b88ec8a;}
                        i.rt8{color:#822bec8a;}
                        i.rt9{color:#ec2b6b8a;}
                    }
                }
                a:hover{
                    opacity:.85;
                }
               
            }
        }
    } 
}

</style>