import $ from 'jquery';
export default {

    data() {
        return {
            mixfn_name : '',
            mixsearch : false,
            mixmodel : [],
            mixmeta_data : { okey: 'id', oval:'desc',  skey : '', sval : '', wkey:'', wval:''},
        }
    },
    methods: 
    {
        ServiceRefreshData()
        {
            this.mixmeta_data = { okey: '', oval:'',  skey : '', sval : '', wkey : this.mixmeta_data.wkey, wval:this.mixmeta_data.wval};
            this[this.mixfn_name].call(window, 1);
        },
        ServiceSearchData()
        {
            if(this.mixmeta_data.sval == ''){
                alert('🙏, Please enter the keyword');
                return;
            }
            this[this.mixfn_name].call(window, 1);
        },
        ServiceInitFunction(fnstring, initial_class)
        {
            this.mixfn_name = fnstring;
            this[this.mixfn_name].call(window, 1);
            this.SetOrderingTable(initial_class);
        },
        ServiceGlobalSearch()
        {
            if( localStorage.getItem('global-search') )
            {
                let data = JSON.parse(localStorage.getItem('global-search'));
                this.mixmeta_data.skey = data.search_key;
                this.mixmeta_data.sval = data.search_val;
            }

            localStorage.removeItem('global-search');
        },
        SetOrderingTable(initial_class)
        { 
            var self  = this; 
            var funcs = this.mixfn_name;

            setTimeout(() => {

                if( $('.'+initial_class) )
                {

                    // initial default
                    $('.'+initial_class).find('tr > th').each(function(){

                        if(  $(this).data('filter') !== undefined )
                        {
                            if( $(this).data('filter') == self.mixmeta_data.okey )
                            {
                                if( self.mixmeta_data.oval == 'asc' ){
                                    $(this).addClass('active-asc')
                                }
    
                                if( self.mixmeta_data.oval == 'desc' ){
                                    $(this).addClass('active-desc')
                                }
                            }
                        }
                       
                    })

                    // trigger click
                    $('.'+initial_class).find('tr > th').click(function()
                    {
                        if( $(this).data('filter') )
                        {
                            $('.'+initial_class).find('tr > th').removeClass('active-asc');
                            $('.'+initial_class).find('tr > th').removeClass('active-desc');
                            
                            self.mixmeta_data.okey = $(this).data('filter');
                            self.mixmeta_data.oval = self.mixmeta_data.oval == 'desc' ? 'asc' : 'desc';

                            if( self.mixmeta_data.oval == 'asc' ){
                                $(this).addClass('active-asc')
                            }

                            if( self.mixmeta_data.oval == 'desc' ){
                                $(this).addClass('active-desc')
                            }

                            self[funcs].call(window);
                        }
                        
                    });

                }else{
                    self.SetOrderingTable(initial_class);
                }

            }, 3000);
               
            
        },
    },
};