<template>
    <main>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-12 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Konfirmasi Topup 
                    </h1>
                    <div class="page-header-subtitle text-secondary">Silahkan transfer ke nomor rekening yang terdaftar sesuai dengan jumlah digit dibawah ini.</div>
                </div>
            </div>
        </header>
        <hr/>
        <div class="row">
            <div class="col-md-12">
                <div class="page-info">
                    <i class="mdi mdi-check-circle-outline"></i>
                    <div id="desc">
                        Terimakasih telah melakukan transaksi bersama DFLASH. <br/>
                        Detail pembayaran deposit telah kami kirimkan melalui email berikut <b>{{ isForwardInvoiceEmail }}</b>.
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mb-4">
                    <div class="card-body">
                       <div class="row">
                            <div class="col-md-12">
                                <label><i class="mdi mdi-information-outline text-warning"></i> Informasi <br/><span class="text-secondary">Silahkan transfer ke nomor rekening yang terdaftar sesuai dengan jumlah digit yang tercantum.</span></label>
                            <hr/>
                            </div>
                            <div class="col-md-5 border-right pr-5">
                                <div class="guides">
                                    <ul v-if="isDepositBy == 'transfer_bank'">
                                        <li>Bank Terdaftar 
                                            <div class="loading-dots" v-if="isFirstLoad"><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div></div>
                                            <span class="float-right" v-else>{{ model_bank.bank }}</span>
                                        </li>
                                        <li>No Rekening 
                                            <a href="javascript:;" class="copy" title="Copy" @click="CopyClipboard('Nomor Rekening',model_bank.no_rekening)"><i class="mdi mdi-content-copy"></i></a>

                                            <div class="loading-dots" v-if="isFirstLoad"><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div></div>
                                            <span class="float-right" v-else>{{ model_bank.no_rekening }}</span>
                                        </li>
                                        <li>Atas Nama 
                                            <div class="loading-dots" v-if="isFirstLoad"><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div></div>
                                            <span class="float-right" v-else>{{ model_bank.an }}</span>
                                        </li>
                                    </ul>
                                    <ul v-if="isDepositBy == 'mart'">
                                        <li>Minimarket Terdaftar 
                                            <span class="float-right">{{ model_mart.name }}</span>
                                        </li>
                                        <li>Kode Pembayaran 
                                            <a href="javascript:;" class="copy" title="Copy" @click="CopyClipboard('Kode Pembayaran', model.unique_code)"><i class="mdi mdi-content-copy"></i></a>
                                            <span class="float-right">{{ model.unique_code }}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-7 pl-5">
                                <div class="total-nominal">
                                    <span>Jumlah Pembayaran <b>( Rp )</b> </span>
                                    <a href="javascript:;" class="copy" title="Copy" @click="CopyClipboard('Jumlah Pembayaran ',model.total_payment)"><i class="mdi mdi-content-copy"></i></a>
                                    <br/>
                                    <div class="amount">{{ model.total_payment }}</div>
                                </div>
                                <div id="generate-unique-code" v-if="isDepositBy == 'transfer_bank'">
                                    <span>Kode Unik Pembayaran  </span><br/>
                                    <small>{{ model.unique_code }}</small>
                                </div>
                                <div id="generate-keterangan" v-if="isDepositBy == 'mart'">
                                    <span>Keterangan</span><br/>
                                    <ul >
                                        <li  v-for="(items, idx) in model_mart.keterangan" :key="idx">
                                            <small>{{ items }}</small>
                                        </li>
                                    </ul>
                                </div>
                                <!--<div class="total-nominal limit-time">
                                    <span>  Batas Waktu Pembayaran </span>
                                    <br/>
                                    <div class="amount ">
                                        <span id="time">05:00</span>
                                    </div>
                                </div>-->
                            </div>
                            <div class="col-md-12">
                                <div id="note" v-if="isDepositBy == 'transfer_bank'">
                                    <b class="text-danger">!</b> <span>Mohon periksa kembali jumlah nominal pembayaran serta rekening tujuan sebelum transfer.</span>
                                </div>
                                <div id="note" v-if="isDepositBy == 'mart'">
                                    <b class="text-danger">!</b> <span>Mohon periksa kembali jumlah nominal pembayaran dan silahkan catat <b>kode pembayaran</b>.</span>
                                </div>
                            </div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>


<script>
    export default {
       
        data() {
            return {
                isFirstLoad : true,
                isForwardInvoiceEmail : '',
                isDepositBy : '',
                model : { total_payment : 0 },
                model_bank : {},
                model_mart : {}
            }
        },
       
        created()
        {
            /*
            if( !localStorage.getItem('QUERY_AMOUNT')  ){
                window.location.href = 'balance-store';
            }*/

            this.isDepositBy = localStorage.getItem('QUERY_DEPOSIT_BY');
            this.model.total_payment = localStorage.getItem('QUERY_AMOUNT');
            this.model.unique_code = localStorage.getItem('QUERY_UNIQUE_CODE');

            if( localStorage.getItem('QUERY_INV_SEND_TO') )
            {
                this.isForwardInvoiceEmail = localStorage.getItem('QUERY_INV_SEND_TO');
            }

            if( this.isDepositBy == 'transfer_bank' ){
                this.QueryBank();
            }else{
                this.model_mart.name = localStorage.getItem('QUERY_ID');
                let keterangan = localStorage.getItem('QUERY_KETERANGAN');
                    keterangan = keterangan.split('-');
                    keterangan.splice(0, 1)

                this.model_mart.keterangan = keterangan;
            }
            /*
            localStorage.removeItem('QUERY_ID');
            localStorage.removeItem('QUERY_AMOUNT');
            localStorage.removeItem('QUERY_UNIQUE_CODE');
            localStorage.removeItem('QUERY_MART');
            localStorage.removeItem('QUERY_KETERANGAN');
            */
        },
        methods:
        {
            async CopyClipboard(title, string)
            {    
                await navigator.clipboard.writeText(string);
                alert(title + ' telah di copy');
            },

            async QueryBank()
            {
                let res = await this.ServiceApiPost({
                    url:'payment_method/find_bank',
                    data : {id : localStorage.getItem('QUERY_ID')}
                });
                if(res && res.result)
                {
                    this.model_bank = res.query;
                    this.isFirstLoad = false;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.border-right{
    border-right:1px dashed #e3d2c2 !important;
}
.total-nominal{
    
    float: left;
    width: 50%;
    text-align: left;
    margin-top: 4px;
    margin-bottom: 7px;
    .amount{
        float:left;
        padding:10px 15px;
        width:100%;
        background-color:#ffffff4a;
        border-radius:5px;
        font-size:20px;
        margin-top:5px;
        border:1px solid #ec432d70;
        span{
            font-size:20px;
        }
    }
}

.limit-time{
    text-align:right;
    width:40%;
    float:right;
    .amount{
        margin-top:10px;
        width:170px;
        float:right;
        border:1px solid #ffffff6d;
        background-color:#ffecc04a;
        text-align:center;
    }
}

#note{
    float:left;
    width:100%;
    border-top:1px solid  #edd2c3;
    margin-top:20px;
    padding-top:20px;
    margin-bottom:20px;

}

#generate-unique-code{
    float:right;
    width:45%;
    margin-top:30px;
    margin-left:20px;

    small{
        padding: 1px 10px;
        background-color: #f1c980c2;
        border:1px solid #f8d7aa;
        font-size: 17px;
        margin-top:5px;
        border-radius: 2px;
        float:left;
    }
}

#generate-keterangan{
    float:right;
    width:45%;
    margin-left:20px;
    ul{
        margin: 0;
        padding: 0;
        margin-top: 5px;
        padding-top: 5px;
        border-top: 1px solid #f4c8b7;
        li{
            float:left;
            width:100%;
            list-style:disc;
            padding-bottom:2px;
            margin-left:10px;
            small{
                font-size: 12px;
                float:left;
                color:rgb(49, 49, 49);
            }
        }
    }
}

#topup{
    padding: 10px 20px 5px;
    border: 2px solid rgba(253, 63, 0, 0.424);
    border-radius: 15px;
    background-color: #fbfbfb5c;
}
.guides{
    float:left;
    width:100%;
    ul{
        margin:0;
        margin-bottom:15px;
        padding:5px;
        border-radius:10px;
        float:left;
        width:100%;
        li{
            position:relative;
            width:100%;
            float:left;
            padding:5px;
            list-style:none;
            font-size:13px;
            color:#262626;
            border-bottom:1px solid #e0e0e0;
            .copy{
                position:absolute;
                top: 1px;
                right: -23px;
            }
        }
    }
}

.copy{
    font-size:16px;
    color:#ec432e;
    margin-left:10px;
    float:right;
    position:relative;
    top:-3px;
}
.copy:hover{
    color:#f06d21;
}

.loading-dots {
    position: relative;
    float:right !important;
    top:0px;
}
</style>