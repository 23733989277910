export default {
   
    methods: {
        
        ServerDateService(initial)
        {
            var d = new Date();

            if( initial == 'active_year' ) return d.getFullYear();
            if( initial == 'active_month' ) return d.getMonth()+1;
            if( initial == 'active_day' ) return d.getDate();
        },
    }
}