<template>
    <div class="row">
        <div class="col-md-4">
            <div class="total-product">
                <ul>
                    <li>
                        <div class="circle-icon" v-if="model_category.photo !== null">
                            <img :src="$store.state.api_resource + model_category.photo"/>
                        </div>
                        <i class="mdi mdi-firebase bg-icon bg-icon-category" v-else></i> 
                        {{ $filters.ucwords(model_category.name) }}
                        <br/>
                        <small class="text-secondary">Jumlah total kategori produk.</small>
                    </li>
                    <li class="number">
                        <i class="mdi mdi-arrow-right" v-if="summary.category == 'checklist'"></i>
                        <span v-else>{{ $filters.formatNumber(summary.category) }}</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-md-4">
            <div class="total-product">
                <ul>
                    <li>
                        <div class="circle-icon" v-if="model_subcategory.photo !== null">
                            <img :src="$store.state.api_resource + model_subcategory.photo"/>
                        </div>
                        <i class="mdi mdi-firebase bg-icon bg-icon-subcategory" v-else></i> 
                        {{ $filters.ucwords(model_subcategory.name) }}
                        <br/>
                        <small class="text-secondary">Jumlah total sub kategori produk.</small>
                    </li>
                    <li class="number">
                        <i class="mdi mdi-arrow-right" v-if="summary.sub_category == 'checklist'"></i>
                        <span v-else>{{ $filters.formatNumber(summary.sub_category) }}</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-md-4">
            <div class="total-product">
                <ul>
                    <li>
                        <i class="mdi mdi-firebase bg-icon  bg-icon-product"></i> Produk<br/>
                        <small class="text-secondary">Jumlah total produk items.</small>
                    </li>
                    <li class="number">
                        <span>{{ $filters.formatNumber(summary.product) }}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'SummaryProductCategory',
    props : ['init_summary', 'init_category', 'init_subcategory'],
    watch: { 
        init_summary: function(newVal) { 
          this.summary = newVal;
        }
    },
    data() {
        return {
            model_category : { photo : null, name : 'Kategori' },
            model_subcategory : { photo : null, name : 'Sub Kategori' },
            summary : {category : 0, sub_category:0, product: 0},
        }
    },
    created()
    {
        if( this.init_category )
        {
            this.model_category.photo = this.init_category.photo;
            this.model_category.name = this.init_category.name;
        }
        if( this.init_subcategory )
        {
            this.model_subcategory.photo = this.init_subcategory.photo;
            this.model_subcategory.name = this.init_subcategory.name;
        }
        if( this.init_summary ){
            this.summary = this.init_summary;
        }

    },
}
</script>


<style lang="scss" scoped>
.total-product{
    background-color:rgba(240, 240, 240, 0.538);
    float:left;
    width:100%;
    margin-bottom:15px;
    border-radius:40px;
    border: 1px solid #ff9d955e;
    overflow:hidden;
    color:#2d2d2d;
    ul{
        padding:0;
        margin:0;
        li:first-child{
            position:relative;
            width:80%;
            padding:12px;
            text-align:left;
            padding-left:70px;
            small{
                position:relative;
                top:-6px;
            }
            .bg-icon{
                font-size: 30px;
                position: absolute;
                left: 30px;
                top: 7px;
            }
            .bg-icon-category{color:#e99e6a}
            .bg-icon-subcategory{color:#eb3c2fad}
            .bg-icon-product{color:#1d8da491}
            .circle-icon{
                position:absolute;
                left:20px;
                top:16px;
                width:37px;
                height:37px;
                border-radius:50%;
                border:1px solid #fcf9f3;
                text-align:center;
                img{
                    width:100%;
                }
            }
        }
        li{
            padding:10px;
            list-style: none;
            float:left;
            height:70px;
            width:20%;
            text-align:center;
            background-color:#ffbd8f17;
            border-left:1px dashed #e5cabe;
        }
        li.number{
            background-color:#ed522900;
            span, i{
                display:block;
                position:relative;
                top:5px;
                font-size:20px;
                color:#e72e00;
                padding-top:5px;
            }
        }
     
    }
}
</style>