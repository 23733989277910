<template>
    <div class="popup-modal">
        <div class="modal-dialog modal-sm" >
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Environment Sistem <br/><span class="text-secondary">Silahkan pilih environment sesuai kebutuhan integrasi anda.</span></h5>
                    <button class="btn btn-sm btn-close" type="button" @click="ClosePopup()"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12 mt-3">
                            <div class="env env-staging">
                                <div class="icon">
                                    <i class="mdi mdi-webpack"></i>
                                </div>
                                <div class="desc">
                                    <h4>Staging</h4>
                                    <p>Data yang terintegrasi merupakan data dummy dan dapat digunakan untuk kebutuhan testing.</p>
                                    <a href="javascript:;" class="subbtn-circle" @click="SwitchEnv('staging')">Gunakan Env <i class="mdi mdi-chevron-right"></i></a>
                                </div>
                           </div>
                           <div class="env env-prod">
                                <div class="icon">
                                    <i class="mdi mdi-server-security"></i>
                                </div>
                                <div class="desc">
                                    <h4>Production</h4>
                                    <p>Data yang terintegrasi merupakan data realtime. Pastikan konfigurasi anda sudah benar.</p>
                                    <a href="javascript:;" class="subbtn-circle" @click="SwitchEnv('production')">Gunakan Env <i class="mdi mdi-chevron-right"></i></a>
                                </div>
                           </div>
                           <div id="footer">
                                <span class="text-secondary">
                                    <b class="text-danger">!</b> Perubahan environment akan menyesuaikan akun & integrasi data. Harap periksa kembali konfigurasi sistem anda setelah merubah environment.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default 
    {   
        props :['data_model'],
        data() {
            return {
                isFirstLoad : true,
                model : [],
                total_loans : 0,
                total_paid : 0
            };
        },
        methods: 
        {
            ClosePopup()
            {
                this.$emit('closePopup');
            },
            SwitchEnv(env)
            {
                localStorage.setItem('is_enable_env', env);
                window.location.reload();
            },
        }

    }
</script>

<style lang="scss" scoped>
.subbtn-circle{
    padding: 5px 17px;
    border: radius 25%;
    background-color: #343434;
    color: #fff;
    text-align: center;
    border-radius: 25px;
    float: right;
}
#footer{
    float:left;
    width:100%;
    text-align:center;
    margin-top:15px;
    margin-bottom:15px;
    span{

        font-size:12px;
    }
}
.subbtn-circle:hover{
    color:#000;
    background-color:#fff;
    border:1px solid #343434;
}

.env{
    position:relative;
    margin-bottom:10px;
    background-color: #fff;
    border-radius:10px;
    float:left;
    width:100%;
    overflow:hidden;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);

    .icon, .desc{
        float:left;
    }
    .icon{
        width:25%;
        height:140px;
        background-color:#f9cb50;
        text-align:center;
        i{
            display:block;
            margin:0 auto;
            position:relative;
            padding: 3px;
            top: 36px;
            font-size: 45px;
            color:#fff;
            background-color:#e3981a;
            border-radius:50%;
            width: 73px;
            height: 73px;
        }
    }
    .desc{
        width:70%;
        height:140px;
        h4{
            font-weight: 200;
            margin-top:10px;
            text-align:center;
            margin-bottom:10px;
        }
        p{
            padding-left:20px;
            font-size:12px;
            word-wrap: break-word;
            color:rgb(84, 84, 84);

        }
    }
   
}


.env-staging{
    .icon{
        background-color:#f9cb50;
        i{
            color:#fff;
            background-color:#e3981a;
        }
    }
}

.env-prod{
    .icon{
        background-color:#ef6f55;
        i{
            color:#fff;
            background-color:#de583f;
        }
    }
}

</style>