<template>
    <div id="bg-footer">
        <div class="popup-modal " v-if="isEnableAppVersion">
            <div class="modal-dialog modal-sm" >
                <div class="modal-content software-license">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-4 p-0">
                                <div class="icon">
                                    <div class="cube">
                                        <div class="shadow"></div>
                                        <div class="infont"><i class="mdi mdi-cube-outline"></i>H2H</div>
                                    </div>
                                    Sandbox <br/>
                                    <small>Beta Version </small><small id="version-number">1.0</small>
                                </div>
                            </div>
                            <div class="col-md-8 p-0">
                                <div class="desc">
                                    <a href="javascript:;" class="close"   @click="ClosePopup()"><i class="mdi mdi-close"></i></a>
                                    <h4>License</h4>
                                    <p>
                                        Web Aplikasi Sandbox ini buat oleh Dflash Tehnologi Team Indonesia sebagai Hak Cipta Lisensi.
                                        Dilarang keras melakukan plagiat, menggunakan bahkan memodifikasi tanpa memiliki izin telebih dahulu.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer class="footer">
            <div id="bg-hexa"></div>
            <div id="bg-robot"></div>
            <div class="row">
                <div class="col-md-4">
                    <div class="d-sm-flex justify-content-center justify-content-sm-between">
                        <span class="copyright text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © <a href="https://dflash.co.id/" target="_blank" class="text-orange">Dflash</a> 2023  All rights reserved. <br/>Proudly develop by dflash technology team indonesia.</span>
                    </div>
                </div>
                <div class="col-md-4 text-center">
                    <span class="copyright">Let's keep in touch with our team</span>
                    <ul>
                        <li><router-link :to="{ name: 'faq' }" class="dropdown-item" ><i class="color-faq mdi mdi-comment-question-outline"></i> <span>Faq</span></router-link></li>
                        <li><router-link :to="{ name: 'email-us' }" class="dropdown-item"><i class="color-secure mdi mdi-email-open-outline"></i> <span>Contact Us</span></router-link></li>
                        <li><a href="javascript:;" @click="isEnableAppVersion = true"><i class="color-version mdi mdi-arrow-up-bold-hexagon-outline"></i> <span>Version</span></a></li>
                    </ul>
                </div>
                <div class="col-md-4 text-left">
                    <span class="copyright"><i class="mdi mdi-format-quote-close"> </i> Nikmati berbagai fitur transaksi yang dapat memudahkan sistem anda melakukan expandable bisnis. Dflash secara konsisten akan menjaga kerahasiaan akun dan data transaksi anda. <i class="mdi mdi-format-quote-close"> </i> </span>
                </div>
            </div>
        </footer>
        <div id="bg-wave"></div>
    </div>
</template>


<script>
    export default {
       
        data() {
            return {
                isEnableAppVersion : false,

            }
        },
       
        methods:
        {
            ClosePopup()
            {
                this.isEnableAppVersion = false;
            }
        }
    }
</script>


<style lang="scss" scoped>

.software-license{
    float:left;
    width:100%;
    height:auto;
    background:none;
    border:none;
    font-family :'Poppins Light';
    .icon, .desc{
        float:left; 
        width:100%;
        height:210px;
        color:#fff;

    }

    .icon{
        background-color:#3a3d46;
        text-align:center;
        padding-top:33px;

        .cube {
            position: relative;
            .shadow {
                display: block;
                position: absolute;
                z-index: 1;
                left:0;
                right:0;
                margin:0 auto;

                width:92px;
                height:92px;
                background: linear-gradient(45deg,red,blue,green,yellow,#e11d74,black);
                background-size: 400%;
                border-radius: 5px;
                animation: animate 35s linear infinite;
            }

            
            .infont{
                padding:10px 20px;
                width:90px;
                height:90px;
                position: relative;
                z-index: 2;
                left:0;
                right:0;
                top:1px;
                margin:0 auto;
                margin-bottom:20px;
                background-color: #1a191f;
                position:relative;
                font-size:14px;
                border-radius: 5px;

                i{
                    position:relative;
                    font-size:32px;
                    color:#ff4500;
                }
            }
        }
       
       
        i{
            font-size:60px;
        }
        #version-number{color:#ff3c00;}
    }
    .desc{
        background: linear-gradient(110.1deg, rgb(241, 115, 30) 18.9%, rgb(231, 29, 54) 90.7%);
        text-align:left;
        padding: 15px 29px 0px 21px;
        h4{
            text-align:center; 
            margin-bottom:15px;
            padding-bottom:15px;
            border-bottom: 1px solid #ff6555;
        }
        p{font-size:13px;}
        .close{
            i{
                color:#fff;
                position:absolute;
                right:10px;
                top:10px;
            }
        }
    }
  
}


#bg-hexa{
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 99;
    width: 301px;
    height: 231px;
    background:url('/assets/images/laravel-footer-logo.png')no-repeat;
    background-size:cover;
}
#bg-robot{
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 99;
    width: 301px;
    height: 191px;
    background:url('/assets/images/laracasts-gang.png')no-repeat;
    background-size:contain;
}
#bg-footer{
    width: 100%;
    height: 210px;
    position: relative;
    left: 0;
    bottom: 0;
    background: #424552;

    #bg-wave{
        width: 100%;
        height: 90px;
        position: absolute;
        right:0;
        bottom:0;
        background : url('/assets/images/wave3.svg')no-repeat;
        background-size:cover;
        opacity:.85;
    }
    footer{

        padding-top:60px;
        padding-left: 6em;
        padding-right: 6em;
        font-family: inherit;
        color:#fff;
        span{
            font-size:14px;
        }
        .copyright{
            font-size:13px; 
            line-height: 20px;
            font-family :'Poppins Light';

            i.mdi-format-quote-close{
                color:#d32c13;
            }
        }
        .text-muted{color:#fff !important;}
        ul{
            padding:0;
            margin:0;
            position: relative;
            left:0;
            right:0;
            margin:0 auto;
            width:277px;
            padding-top: 10px;
            margin-top: 10px;
            border-top: 1px solid #b33101;
            li{
                list-style: none;
                float:left;
                margin:5px;
                text-align: center;
                a{
                    display: block;
                    padding: 2px 10px;
                    background-color: #2a2b35;
                    color: #fff;
                    text-decoration: none;
                    border-radius: 25px;
                    text-align: center;
                    i{
                        font-size:14px;
                    }
                    span{
                        font-size:12px;
                        font-family :'Poppins Light';
                    }
                    .color-faq{color:#00deff;}
                    .color-secure{color:#ff3d3d;}
                    .color-version{color:#a1ff00d4;}
                    &:hover{
                        background-color:#eb3731;
                        .mdi{
                            color:#fff;
                        }

                    }
                }
            }
        }
    }
}




@keyframes animate {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
</style>